import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';

import server from '../../utils/server';
import apiDealer from '../../configs/api-dealer';

import AuthUtil from '../../utils/AuthUtil';
import validator from '../../utils/validator';
import DownloadGoogle from '../../components/DownloadGoogle';

require('./login.less');

const logo = require('../../images/dealer/logo_small.png');
const logoYouche = require('../../images/dealer/logo_large.png');
const topLogo = require('../../images/login/top_logo.png');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code_id: '',
      btn_value: '获取验证码',
      is_disabled: false,
      opacity: 1,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getVerifyCode = this.getVerifyCode.bind(this);
  }

  componentDidMount() {
    if (AuthUtil.isLogin()) {
      location.href = '/';
    }
  }

  handleSubmit() {
    const phone = this.formRef.getFieldValue('phone');
    const code = this.formRef.getFieldValue('code');

    if (!phone || !validator.phone(phone)) {
      message.error('请输入正确的电话号码');
      return false;
    }
    if (!code) {
      message.error('请输入验证码');
      return false;
    }

    const param = this.formRef.getFieldsValue();
    param.permission = 'no-login';
    param.code_id = this.state.code_id;
    server.post(apiDealer.system.login(), param, (loginRes) => {
      window.accessToken = `Bearer ${loginRes.res.access_token}`;
      localStorage.setItem('refressToken', `${loginRes.res.refress_token}`);
      server.get(apiDealer.system.userInfo(), (data) => {
        const { user } = data.res;
        if (user && Object.keys(user).length > 0) {
          user.uid = user._id;
          localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
          const date = new Date();
          const hour = date.getHours();
          const minute = date.getMinutes();
          localStorage.setItem('last_update', ((hour * 60) + minute).toString());
          localStorage.setItem('last_update_day', new Date().getDate().toString());
          message.success('登录成功');
          setTimeout(() => {
            location.href = '/';
          }, 500);
        } else {
          message.error('获取用户信息失败, 请重新登录');
        }
      });
    });
  }

  getVerifyCode() {
    const phone = this.formRef.getFieldValue('phone');
    if (!phone) {
      message.error('请输入电话号码');
      return false;
    }

    let num = 10;
    this.setState({ is_disabled: true, opacity: 0.5 });

    let btn_value = `${num}s`;
    this.setState({ btn_value });

    let time = setInterval(() => {
      num--;
      btn_value = `${num}s`;
      this.setState({ btn_value });

      if (num === 0) {
        this.setState({
          is_disabled: false,
          opacity: 1,
          btn_value: '获取验证码',
        });
        clearInterval(time);
        time = undefined;
      }
    }, 1000);

    server.post(apiDealer.system.getVerifyCode(), {
      phone, permission: 'no-login',
    }, (data) => {
      message.info('验证码已发送', 3);
      const { sms } = data.res;
      if (sms && Object.keys(sms).length > 0) {
        this.setState({ code_id: sms._id });
        this.formRef.setFieldsValue({ code_id: sms._id });
      } else {
        message.error('验证码获取失败');
      }
    });
  }

  render() {
    const { btn_value, is_disabled } = this.state;

    return (
      <div>
        <div className="content">
          <header>
            <div className="logo">
              <img src={logo} alt="水稻经销商" />
            </div>
            <div className="phone">
              <span className="mr10">寻求帮助</span>
              <span>400-900-9556</span>
            </div>
            <div className="top-logo">
              <span className="mr10">系统支持</span>
              <img src={topLogo} alt="" />
            </div>
          </header>

          <section style={{ height: document.body.clientHeight - 165, minHeight: '550px' }}>
            <div className="section-content">
              <div className="word">
                <div className="name">
                  <img src={logoYouche} style={{ width: '280px', height: '50px' }} />
                </div>
                <div className="slogan-one mt15">配件商城+金融产品 一站式服务平台</div>
              </div>

              <div className="sign-in">
                <div className="accountLogin">
                  <p>账号登录</p>
                </div>
                <Form
                  ref={ref => this.formRef = ref}
                  onFinish={this.handleSubmit}
                >
                  <Form.Item name={'code_id'} initialValue={this.state.code_id} noStyle>
                    <Input type="hidden" />
                  </Form.Item>

                  <Row>
                    <Col span={15}>
                      <Form.Item name={'phone'} noStyle>
                        <Input className="input-phone input" size="large" placeholder="请输入手机号" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Button
                        disabled={is_disabled}
                        className="code-button"
                        onClick={this.getVerifyCode.bind(this)}
                      >
                        {btn_value}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name={'code'} noStyle>
                        <Input
                          className="input-password input" onPressEnter={this.handleSubmit}
                          placeholder="请输入验证码"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button className="submit-button" type="primary" htmlType="submit">登录</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </section>

          <footer>
            <p>
              Copyright © 2012-{new Date().getFullYear()} 北京稻成科技有限公司. All Rights Reserved
              <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
                京ICP备15040810号
              </a>
            </p>
          </footer>
        </div>

        <DownloadGoogle />
      </div>
    );
  }
}

export default Login;
