const API_HOST = `${window.baseURL}/guanche/`;

const apiGuanche = {
  config: {
    limit: 15,
    halfLimit: 10,
    skip: 0,
  },

  system: {
    getVerifyCode() {
      return `${API_HOST}user/get-code`;
    },
    login() {
      return `${API_HOST}user/login`;
    },
    logout() {
      return `${API_HOST}user/logout`;
    },
    userInfo() {
      return `${API_HOST}user/info`;
    },

    refressLogin() {
      return `${API_HOST}user/refress-login`;
    },

    userList(condition) {
      return `${API_HOST}user/user-list?key=${condition.key}&create_start_date=${condition.startDate}&create_end_date=${condition.endDate}&skip=${((condition.page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    userListAll() {
      return `${API_HOST}user/user-list?key=&create_start_date=&create_end_date=&skip=&limit=`;
    },
  },

  task: {
    tastSummary() {
      return `${API_HOST}task/task-summary`;
    },
  },

  auto: {
    list(condition) {
      return `${API_HOST}auto/list?create_start_date=${condition.startDate}&create_end_date=${condition.endDate}&key=${condition.key}&skip=${((condition.page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    searchAll(key) {
      return `${API_HOST}auto/list?key=${key}&create_start_date=&create_end_date=&skip=0&limit=-1`;
    },
    create() {
      return `${API_HOST}auto/create`;
    },
    edit() {
      return `${API_HOST}auto/edit`;
    },
    setAdminAndCustomer() {
      return `${API_HOST}auto/set-admin-and-customer`;
    },
    detail(id) {
      return `${API_HOST}auto/detail?auto_id=${id}`;
    },

    insurance: {
      list(condition) {
        return `${API_HOST}auto/insurance-log-list?auto_id=${condition.autoId}&skip=${((condition.page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      create() {
        return `${API_HOST}auto/create-insurance-log`;
      },
      edit() {
        return `${API_HOST}auto/edit-insurance-log`;
      },
      detail(autoId, insuranceLogId) {
        return `${API_HOST}auto/insurance-log-detail?auto_id=${autoId}&insurance_log_id=${insuranceLogId}`;
      },
      companyList() {
        return `${API_HOST}auto/get-insurance-company-list`;
      },
      config() {
        return `${API_HOST}auto/get-insurance-config`;
      },
    },
    maintain: {
      list(condition) {
        return `${API_HOST}auto/maintain-intention-list?auto_id=${condition.autoId}&start_date=${condition.startDate}&end_date=${condition.endDate}&skip=${((condition.page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      listByAutoId(condition) {
        return `${API_HOST}auto/maintain-intention-list-by-auto-id?auto_id=${condition.autoId}&start_date=${condition.startDate}&end_date=${condition.endDate}&skip=${((condition.page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
    },
    weizhang: {
      list(condition) {
        return `${API_HOST}auto/weizhang-list?auto_id=${condition.autoId}&start_date=${condition.startDate}&end_date=${condition.endDate}&skip=${((condition.page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      detail(autoId) {
        return `${API_HOST}auto/search-weizhang-result?auto_id=${autoId}`;
      },
    },

    inspection: {
      list(condition) {
        return `${API_HOST}auto/annual-inspection-log-list?auto_id=${condition.autoId}&skip=${((condition.page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      create() {
        return `${API_HOST}auto/create-annual-inspection-log`;
      },
      edit() {
        return `${API_HOST}auto/edit-annual-inspection-log`;
      },
      detail(autoId, inspectionLogId) {
        return `${API_HOST}auto/annual-inspection-log-detail?inspection_log_id=${inspectionLogId}&auto_id=${autoId}`;
      },
    },

    useAuto: {
      create() {
        return `${API_HOST}auto/create-customer-use-auto`;
      },
      edit() {
        return `${API_HOST}auto/edit-customer-use-auto`;
      },
      list(condition) {
        return `${API_HOST}auto/use-auto-list-by-auto-id?auto_id=${condition.autoId}&skip=${((condition.page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      detail(condition) {
        return `${API_HOST}auto/customer-use-auto-detail?auto_id=${condition.autoId}&use_auto_id=${condition.useAutoId}`;
      },
    },
  },

  // 套餐卡管理
  couponCard: {
    list(condition) {
      const { page = 1, status = '-2', key = '' } = condition;
      return `${API_HOST}coupon-package/card-list?keyword=${key}&skip=${((page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}&status=${status}`;
    },

    detail(id) {
      return `${API_HOST}coupon-package/card-detail?card_id=${id}`;
    },

    cardOrderList(condition) {
      const { page = 1, packageId = '', key = '', customerId = '', autoId = '' } = condition;
      return `${API_HOST}coupon-package/card-order-list?package_id=${packageId}&customer_id=${customerId}&auto_id=${autoId}&keyword=${key}&skip=${((page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },

    giveCustomerCard() {
      return `${API_HOST}coupon-package/give-customer-card`;
    },
  },

  // 车主
  autoOwner: {
    list(condition) {
      return `${API_HOST}customer/list?is_show_auto=${condition.isShowAuto}&key=${condition.key}&skip=${((condition.page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    searchList(key) {
      return `${API_HOST}customer/list?is_show_auto=0&key=${key}&skip=&limit=`;
    },
    create() {
      return `${API_HOST}customer/create`;
    },
    edit() {
      return `${API_HOST}customer/edit`;
    },
    detail(id) {
      return `${API_HOST}customer/detail?customer_id=${id}`;
    },
    useAutoList(condition) {
      return `${API_HOST}auto/use-auto-list-by-customer-id?customer_id=${condition.customerId}&skip=${((condition.page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    customerCount(id) {
      return `${API_HOST}customer/customer-count?auto_brand_ids=${id}`;
    },
  },

  sms: {
    createTemplate() {
      return `${API_HOST}sms/create-template`;
    },
    editTemplate() {
      return `${API_HOST}sms/edit-template`;
    },
    detail(id) {
      return `${API_HOST}sms/template-detail?template_id=${id}`;
    },
    templateList(condition) {
      return `${API_HOST}sms/template-list?create_start_date=${condition.startDate}&create_end_date=${condition.endDate}&type=${condition.type}&status=${condition.status}&skip=${((condition.page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    getSendTemplates(page, type) {
      return `${API_HOST}sms/template-list?type=${type}&create_start_date=&create_end_date=&status=1&skip=${((page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    consumeList(condition) {
      return `${API_HOST}sms/consume-list?start_date=${condition.startDate}&end_date=${condition.endDate}&status=${condition.status}&sub_type=${condition.subType}&skip=${((condition.page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    send() {
      return `${API_HOST}sms/send-sms`;
    },
    sendBatch() {
      return `${API_HOST}sms/send-batch-sms`;
    },
  },

  /**
   * 维保记录
   */
  maintainLog: {
    list(condition) {
      const { page, key } = condition;
      return `${API_HOST}auto/maintain-intention-list?key=${key}&skip=${((page - 1) || 0) *
      apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
  },

  /**
   * 违章查询
   */
  trafficViolation: {
    list(condition) {
      return `${API_HOST}task/weizhang-search-list?skip=${((condition.page - 1) || 0) *
      apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    query() {
      return `${API_HOST}task/create-weizhang-search`;
    },
    querySingle() {
      return `${API_HOST}account/edit`;
    },
    queryBatch() {
      return `${API_HOST}task/create-weizhang-search`;
    },
    queryBatchDetail(condition) {
      const { page, taskId, key, isSms } = condition;
      return `${API_HOST}task/weizhang-list-by-task-id?task_id=${taskId}&key=${key}&is_sms=${isSms}&skip=${((page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    send() {
      return `${API_HOST}task/remind-weizhang`;
    },
  },

  /**
   * 提醒任务
   */
  remind: {
    maintain: {
      list(condition) {
        const { page, isSms, between } = condition;
        return `${API_HOST}task/maintain-list?is_sms=${isSms}&between=${between}&skip=${((page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      send() {
        return `${API_HOST}task/remind-maintain`;
      },
    },

    insurance: {
      list(condition) {
        const { page, isSms, between } = condition;
        return `${API_HOST}task/insurance-list?is_sms=${isSms}&between=${between}&skip=${((page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      send() {
        return `${API_HOST}task/remind-insurance`;
      },
    },

    yearlyCheck: {
      list(condition) {
        const { page, isSms, between } = condition;
        return `${API_HOST}task/inspection-list?is_sms=${isSms}&between=${between}&skip=${((page -
          1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
      },
      send() {
        return `${API_HOST}task/remind-inspection`;
      },
    },
  },

  // 资金管理
  wallet: {
    chargeList(condition) {
      const { page = 1 } = condition;
      return `${API_HOST}prepay-wallet/charge-list?skip=${(page -
        1) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },

    exportConsume(condition) {
      const { startDate = '', endDate = '' } = condition;
      return `${API_HOST}prepay-wallet/export-consume-list?start_date=${startDate}&end_date=${endDate}`;
    },

    consumeList(condition) {
      const { startDate = '', endDate = '', page = 1 } = condition;
      return `${API_HOST}prepay-wallet/consume-list?start_date=${startDate}&end_date=${endDate}&skip=${(page -
        1) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },

    info() {
      return `${API_HOST}prepay-wallet/wallet-info`;
    },
  },

  /**
   * 员工|账号管理
   */
  user: {
    list(condition) {
      const { page, key, startDate, endDate } = condition;
      return `${API_HOST}user/user-list?key=${key}&create_start_date=${startDate}&create_end_date=${endDate}&skip=${((page -
        1) || 0) * apiGuanche.config.limit}&limit=${apiGuanche.config.limit}`;
    },
    add() {
      return `${API_HOST}user/create`;
    },
    edit() {
      return `${API_HOST}user/edit`;
    },
    fire() {
      return `${API_HOST}user/fire`;
    },
  },

};

export default apiGuanche;
