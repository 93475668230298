import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import bugsnag from '@bugsnag/js';
import 'nprogress/nprogress.css';
import bugsnagReact from '@bugsnag/plugin-react';

import AuthUtil from '../../utils/AuthUtil';
import server from '../../utils/server';

import Bi from './dashboard/bi-system/Index';
import ActivityBI from './parts-sale/activity-bi/Index';
import Login from './Login';
import App from './App';

const packageFile = require('../../../package.json');

message.config({ maxCount: 1 });

window.env = module.hot ? 'dev' : 'dist';
server.businessModule = 'dealer';
const shuidaoURL = 'https://api.shuidao.com';
const yunbed = 'https://api.shuidao.yunbed.com';
const userInfo = AuthUtil.getLoginUserInfo();
let releaseState = 'development';
if (window.env === 'dist') {
  if (window.baseURL === shuidaoURL) {
    releaseState = 'production';
  } else if (window.baseURL === yunbed) {
    releaseState = 'testflight';
  } else {
    releaseState = 'dev1';
  }
}

const BugsnagClient = bugsnag({
  apiKey: 'df5c804812af2b8a4c376c5cc5478559',
  appVersion: packageFile.versionDealer,
  releaseStage: releaseState,
  user: {
    id: userInfo.id,
    name: userInfo.name,
    email: userInfo.phone,
  },
});
BugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = BugsnagClient.getPlugin('react');
const beforeSend = (report) => {
  report.severity = 'error';
};

render(
  <ErrorBoundary beforeSend={beforeSend}>
    <ConfigProvider locale={zhCN}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/bi-system" component={Bi} />
          <Route path="/activity-bi/:id" component={ActivityBI} />
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById('app_dealer'),
);

if (module.hot) {
  module.hot.accept();
}
