const API_ADMIN_SYSTEM = `${window.baseURL}/admin_system`;
const API_ADMIN_YOUCHE = `${window.baseURL}/admin_youche`;
const API_ADMIN_DAOTIAN = `${window.baseURL}/admin_daotian`;
const API_ADMIN_NEW_ENERGY = `${window.baseURL}/admin_new_energy`;
const API_ADMIN_GUANCHE = `${window.baseURL}/admin_guanche`;
const API_ADMIN_CUSTOMER = `${window.baseURL}/admin_customer`;
const API_ADMIN_ARTIFICER = `${window.baseURL}/admin_artificer`;
const API_ADMIN_STATISTIC = `${window.baseURL}/admin_statistic`;
const API_ADMIN_BRANCH = `${window.baseURL}/admin_branch`;
const API_ADMIN_DEALER = `${window.baseURL}/admin_part_dealer`;

/**
 * 后台管理系统API配置
 */
const apiAdmin = {
  config: {
    limit: 15, // 查询全部数据时，limit=-1即可
    halfLimit: 10,
    skip: 0,
  },

  webSocket(userId, type = '1') {
    return `${window.baseWebsocket}/?type=${type}&user_id=${userId}`;
  },

  // 0.系统
  system: {
    getVerifyCode() {
      return `${API_ADMIN_SYSTEM}/super-user/get-code`;
    },
    login() {
      return `${API_ADMIN_SYSTEM}/super-user/login`;
    },
    logout() {
      return `${API_ADMIN_SYSTEM}/super-user/logout`;
    },
    refressLogin() {
      return `${API_ADMIN_SYSTEM}/super-user/refress-login`;
    },
    userInfo() {
      return `${API_ADMIN_SYSTEM}/super-user/info`;
    },
  },

  // 业务管理,maintain保养业务
  business: {
    log: {
      list(condition) {
        const { page = 1, userId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/branch/use-log-list?user_id=${userId}&branch_id=${branchId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    workLiving: {
      list(condition) {
        const { page = 1, userId = '', branchId = '', startDate = '', endDate = '', status = '-2', type = '-2' } = condition;
        return `${API_ADMIN_BRANCH}/branch/work-living-log-list?branch_user_id=${userId}&start_date=${startDate}&end_date=${endDate}&status=${status}&type=${type}&branch_id=${branchId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      detail(id) {
        return `${API_ADMIN_BRANCH}/branch/work-living-log-detail?log_id=${id}`;
      },
    },

    user: {
      list(condition) {
        const { page = 1, key = '', branchId = '', status = '-10' } = condition;
        return `${API_ADMIN_BRANCH}/branch/user-list?keyword=${key}&status=${status}&branch_id=${branchId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      getAll(condition) {
        const { key = '', branchId = '', status = '-10' } = condition;
        return `${API_ADMIN_BRANCH}/branch/user-list?keyword=${key}&status=${status}&branch_id=${branchId}&skip=0&limit=-1`;
      },
    },
    setMeal: {
      list(condition) {
        const { page = 1, key = '', status = '', branchId = '', canBargain = 0 } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/card-list?keyword=${key}&status=${status}&can_bargain=${canBargain}&branch_id=${branchId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      attendList(condition) {
        const { page = 1, packageId = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/package-bargain-attend-list?&package_id=${packageId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      assistList(condition) {
        const { page = 1, packageId = '', attendId } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/package-bargain-assist-list?package_id=${packageId}&attend_id=${attendId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      add() {
        return `${API_ADMIN_BRANCH}/coupon-package/create-card`;
      },
      edit() {
        return `${API_ADMIN_BRANCH}/coupon-package/edit-card`;
      },
      update() {
        return `${API_ADMIN_BRANCH}/coupon-package/update-card-status`;
      },
      detail(id) {
        return `${API_ADMIN_BRANCH}/coupon-package/card-detail?card_id=${id}`;
      },
      searchList(condition) {
        const { page = 1, key, maintainType = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/maintain/item-list?keyword=${key}&maintain_type=${maintainType}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : ''}`;
      },
      getAll(condition = {}) {
        const { page = 1, key = '', status = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/card-list?keyword=${key}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=-1`;
      },
      cardViewClueList(condition) {
        const { page = 1, packageId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/card-view-clue-list?branch_id=${branchId}&package_id=${packageId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      consumeCompanyList(condition) {
        const { page = 1, packageId = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/package-consume-company-list?package_id=${packageId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      sellCompanyList(condition) {
        const { page = 1, packageId = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/package-sell-company-list?package_id=${packageId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      sellEnterpriseList(condition) {
        const { page = 1, packageId = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/package-sell-enterprise-list?package_id=${packageId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      addSellEnterprise() {
        return `${API_ADMIN_BRANCH}/coupon-package/package-add-sell-enterprise`;
      },

      consumeCompanyDetail(companyId, packageId) {
        return `${API_ADMIN_BRANCH}/coupon-package/package-consume-company-detail?company_id=${companyId}&package_id=${packageId}`;
      },
    },
    buy: {
      list(condition) {
        const { page = 1, cardId = '', companyId = '', branchId = '', key = '' } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/card-order-list?keyword=${key}&card_id=${cardId}&company_id=${companyId}&branch_id=${branchId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },
    consume: {
      list(condition) {
        const { page = 1, cardId = '', companyId = '', branchId = '', key = '', limit } = condition;
        return `${API_ADMIN_BRANCH}/coupon-package/card-item-consume-list?keyword=${key}&card_id=${cardId}&company_id=${companyId}&branch_id=${branchId}&skip=${((page -
          1) || 0) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },
    },
    visit: {
      list(condition) {
        const { page = 1, branchUserId = '', branchId = '', companyId = '', visitTimeStart = '', visitTimeEnd = '', fromType = '-10' } = condition;
        return `${API_ADMIN_BRANCH}/branch/company-visit-log-list?branch_user_id=${branchUserId}&company_id=${companyId}&from_type=${fromType}&start_date=${visitTimeStart}&end_date=${visitTimeEnd}&branch_id=${branchId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      visitPlanList(condition) {
        const { page = 1, branchUserId = '', companyId = '', startDate = '', endDate = '', status = '', branchId = '', fromType = '-2' } = condition;
        return `${API_ADMIN_BRANCH}/branch/company-visit-plan-list?branch_user_id=${branchUserId}&branch_id=${branchId}&company_id=${companyId}&from_type=${fromType}&start_date=${startDate}&end_date=${endDate}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      detailLog(id) {
        return `${API_ADMIN_BRANCH}/branch/company-visit-log-detail?log_id=${id}`;
      },
    },

    journal: {
      list(condition) {
        const { page = 1, companyId = '', branchId = '', userId = '', startDate = '', endDate = '', templateId = '' } = condition;
        return `${API_ADMIN_BRANCH}/journal/journal-list?company_id=${companyId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}&template_id=${templateId}&branch_id=${branchId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      export(condition) {
        const { companyId = '', branchId = '', userId = '', startDate = '', endDate = '', templateId = '' } = condition;
        return `${API_ADMIN_BRANCH}/journal/export-journal-list?company_id=${companyId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}&template_id=${templateId}&branch_id=${branchId}&rtick=${new Date().getTime()}`;
      },

      journalDetail(journalId, companyId) {
        return `${API_ADMIN_BRANCH}/journal/journal-detail?journal_id=${journalId}&company_id=${companyId}`;
      },

      templateDetail(id) {
        return `${API_ADMIN_BRANCH}/journal/template-detail?template_id=${id}`;
      },

      addTemplate() {
        return `${API_ADMIN_BRANCH}/journal/create-template`;
      },

      editTemplate() {
        return `${API_ADMIN_BRANCH}/journal/edit-template`;
      },

      templateList(condition) {
        const { page = 1, branchId = '', status = '-1' } = condition;
        return `${API_ADMIN_BRANCH}/journal/template-list?status=${status}&branch_id=${branchId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      templateOnline() {
        return `${API_ADMIN_BRANCH}/journal/template-online`;
      },

      templateOffline() {
        return `${API_ADMIN_BRANCH}/journal/template-offline`;
      },

      templateJournalList(condition) {
        const { page = 1, companyId = '', branchId = '', userId = '', startDate = '', endDate = '', templateId = '' } = condition;
        return `${API_ADMIN_BRANCH}/journal/template-journal-list?company_id=${companyId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}&template_id=${templateId}&branch_id=${branchId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      templateJournalExport(condition) {
        const { companyId = '', branchId = '', userId = '', startDate = '', endDate = '', templateId = '' } = condition;
        return `${API_ADMIN_BRANCH}/journal/export-template-journal-list?company_id=${companyId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}&template_id=${templateId}&branch_id=${branchId}&rtick=${new Date().getTime()}`;
      },
    },

    summary: {
      brandCompanyIntention(branchId) {
        return `${API_ADMIN_BRANCH}/statistic/brand-company-intention-summary?branch_id=${branchId}`;
      },

      brandCompanyIntentionUser(branchId) {
        return `${API_ADMIN_BRANCH}/statistic/brand-company-intention-user-summary?branch_id=${branchId}`;
      },

      dealOrderSummaryByUser(condition) {
        const { branchUserId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/deal-order-summary-by-user?branch_user_id=${branchUserId}&branch_id=${branchId}`;
      },

      yearDealOrderSummaryByUser(condition) {
        const { branchUserId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/year-deal-order-summary-by-user?branch_user_id=${branchUserId}&branch_id=${branchId}`;
      },

      yearCompanyVisitSummaryByUser(condition) {
        const { branchUserId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/year-company-visit-summary-by-user?branch_user_id=${branchUserId}&branch_id=${branchId}`;
      },

      weekDealOrderSummaryByUser(condition) {
        const { branchUserId = '', branchId = '', startDate = '', endDate = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/week-deal-order-summary-by-user?start_date=${startDate}&end_date=${endDate}&branch_user_id=${branchUserId}&branch_id=${branchId}`;
      },

      companyVisitSummaryByUser(condition) {
        const { branchUserId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/company-visit-summary-by-user?branch_user_id=${branchUserId}&branch_id=${branchId}`;
      },

      weekCompanyVisitSummaryByUser(condition) {
        const { branchUserId = '', branchId = '', startDate = '', endDate = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/week-company-visit-summary-by-user?start_date=${startDate}&end_date=${endDate}&branch_user_id=${branchUserId}&branch_id=${branchId}`;
      },

      branchUserReportDaysByUser(condition) {
        const { branchUserId = '', branchId = '', startDate = '', endDate = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/branch-user-report-days-by-user?start_date=${startDate}&end_date=${endDate}&branch_user_id=${branchUserId}&branch_id=${branchId}&skip=0&limit=-1`;
      },

      companyOrder(condition) {
        const { month, page = 1, branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/company-order-summary?branch_id=${branchId}&month=${month}&skip=${(page -
          1) * apiAdmin.config.halfLimit}&limit=${apiAdmin.config.halfLimit}`;
      },

      companyVisit(condition) {
        const { month, branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/intention-company-visit-summary?branch_id=${branchId}&month=${month}`;
      },

      dealCompanyVisit(condition) {
        const { month, branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/deal-company-visit-summary?branch_id=${branchId}&month=${month}`;
      },

      dealOrder(condition) {
        const { month, branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/deal-order-summary?branch_id=${branchId}&month=${month}`;
      },

      weekDealOrder(condition) {
        const { weekMonth = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/week-deal-order-summary?branch_id=${branchId}&week_monday=${weekMonth}`;
      },

      yearDealOrder(condition) {
        const { year = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/year-deal-order-summary?branch_id=${branchId}&year=${year}`;
      },

      yearCompanyVisitSummary(condition) {
        const { year = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/year-company-visit-summary?branch_id=${branchId}&year=${year}`;
      },

      branchReportList(condition) {
        const { page = 1, startDate = '', endDate = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/branch-report-days?branch_id=${branchId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * apiAdmin.config.halfLimit}&limit=${apiAdmin.config.halfLimit}`;
      },
      branchReportAll(condition) {
        const { startDate = '', endDate = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/branch-report-days?branch_id=${branchId}&start_date=${startDate}&end_date=${endDate}&skip=0&limit=-1`;
      },

      branchUserReport(condition) {
        const { date = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/branch-user-report?branch_id=${branchId}&date=${date}`;
      },

      companyVisitSummary(condition) {
        const { month = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/company-visit-summary?branch_id=${branchId}&month=${month}`;
      },

      weekCompanyVisitSummary(condition) {
        const { weekMonth = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/statistic/week-company-visit-summary?branch_id=${branchId}&week_monday=${weekMonth}`;
      },
    },

    marketInsight: {
      list(condition) {
        const { page = 1, type = '-1', productId = '', supplierUserId = '', supplierId = '', companyId = '', partType = '', brandId = '' } = condition;
        return `${API_ADMIN_BRANCH}/market-insight/list?type=${type}&product_id=${productId}&supplier_user_id=${supplierUserId}&supplier_id=${supplierId}&brand_id=${brandId}&company_id=${companyId}&part_type=${partType}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      productList(condition) {
        const { page = 1, type = '-1', partType = '', key = '', isHot = '-1', brandId = '' } = condition;
        return `${API_ADMIN_BRANCH}/market-insight/product-list?type=${type}&key=${key}&part_type=${partType}&is_hot=${isHot}&brand_id=${brandId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      createProduct() {
        return `${API_ADMIN_BRANCH}/market-insight/create-product`;
      },

      setProductBrand() {
        return `${API_ADMIN_BRANCH}/market-insight/set-product-brand`;
      },

      setProductName() {
        return `${API_ADMIN_BRANCH}/market-insight/set-product-name`;
      },

      editProduct() {
        return `${API_ADMIN_BRANCH}/market-insight/edit-product`;
      },

      updateIsHot() {
        return `${API_ADMIN_BRANCH}/market-insight/update-product-hot`;
      },

      updateStatus() {
        return `${API_ADMIN_BRANCH}/market-insight/update-product-status`;
      },
    },

    warehouse: {
      list(condition) {
        const { page = 1, branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/warehouse/list?branch_id=${branchId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      branchStockRemainAmount(branchId) {
        return `${API_ADMIN_BRANCH}/warehouse/branch-stock-remain-amount?branch_id=${branchId}`;
      },

      getAll(branchId = '') {
        return `${API_ADMIN_BRANCH}/warehouse/list?branch_id=${branchId}&skip=0&limit=-1`;
      },

      stockList(condition) {
        const { page = 1, warehouseId = '', productId = '', productItemId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/warehouse/stock-list?warehouse_id=${warehouseId}&branch_id=${branchId}&product_id=${productId}&product_item_id=${productItemId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      exportStock(condition) {
        const { warehouseId = '', productId = '', productItemId = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/warehouse/export-stock?warehouse_id=${warehouseId}&branch_id=${branchId}&product_id=${productId}&product_item_id=${productItemId}&rtick=${new Date().getTime()}`;
      },

      stockLogList(condition) {
        const { page = 1, warehouseId = '', productId = '', productItemId = '', type = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/warehouse/stock-log-list?warehouse_id=${warehouseId}&branch_id=${branchId}&product_id=${productId}&product_item_id=${productItemId}&type=${type}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      exportStockLog(condition) {
        const { warehouseId = '', productId = '', productItemId = '', type = '', branchId = '' } = condition;
        return `${API_ADMIN_BRANCH}/warehouse/export-stock-log?warehouse_id=${warehouseId}&branch_id=${branchId}&product_id=${productId}&product_item_id=${productItemId}&type=${type}&rtick=${new Date().getTime()}`;
      },

      detail(id) {
        return `${API_ADMIN_BRANCH}/warehouse/detail?warehouse_id=${id}`;
      },

      unlockAnHour() {
        return `${API_ADMIN_BRANCH}/warehouse/unlock-an-hour`;
      },

      stocktaking: {
        list(condition) {
          const { page = 1, warehouseId = '', branchId = '' } = condition;
          return `${API_ADMIN_BRANCH}/warehouse/inventory-list?branch_id=${branchId}&warehouse_id=${warehouseId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        detail(id) {
          return `${API_ADMIN_BRANCH}/warehouse/inventory-detail?inventory_id=${id}`;
        },
      },

      incomeStatement: {
        list(condition) {
          const { page = 1, warehouseId = '', branchId = '' } = condition;
          return `${API_ADMIN_BRANCH}/warehouse/income-statement-list?branch_id=${branchId}&warehouse_id=${warehouseId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        detail(id) {
          return `${API_ADMIN_BRANCH}/warehouse/income-statement-detail?income_statement_id=${id}`;
        },
      },

      output: {
        list(condition) {
          const { page = 1, status = '-2', type = '0', branchId = '', warehouseId = '' } = condition;
          return `${API_ADMIN_BRANCH}/warehouse-output/list?status=${status}&type=${type}&warehouse_id=${warehouseId}&branch_id=${branchId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        detail(id) {
          return `${API_ADMIN_BRANCH}/warehouse-output/detail?output_id=${id}`;
        },

        itemList(condition) {
          const { page = 1, outputId = '' } = condition;
          return `${API_ADMIN_BRANCH}/warehouse-output/item-list?skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&output_id=${outputId}`;
        },

        itemDetail(itemId) {
          return `${API_ADMIN_BRANCH}/warehouse-output/item-detail?output_item_id=${itemId}`;
        },
      },
    },
  },

  // 1.水稻优车 todo branch 改为 youche
  branch: {
    // 经销商管理
    dealer: {
      list(condition) {
        const { page = 1, key = '', cityId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/company/sell-company-list?key=${key}&city_id=${cityId}&branch_id=&branch_user_id=&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll(condition = {}) {
        const { yoCompanyId = '', ycSalesmanId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/company/sell-company-list?key=&city_id=&skip=0&limit=-1&branch_id=${yoCompanyId ||
        ''}&branch_user_id=${ycSalesmanId}`;
      },
    },

    // 业务员
    user: {
      list(condition) {
        return `${API_ADMIN_YOUCHE}/user/list?keyword=${condition.key}&skip=${((condition.page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      listAll(condition = {}) {
        const { key = '', cityId = '' } = condition;
        return `${API_ADMIN_YOUCHE}/user/list?skip=0&limit=-1&keyword=${key}&city_id=${cityId}`;
      },
      listAllCompany(companyId) {
        return `${API_ADMIN_YOUCHE}/user/list?keyword=&branch_id=${companyId ||
        ''}&skip=&limit=-1`;
      },
    },

    // 产品
    product: {
      list(condition) {
        const { page, type, resourceId, cityId, status, key, yoCompanyId } = condition;
        return `${API_ADMIN_YOUCHE}/product/list?status=${status ||
        ''}&resource_id=${resourceId || ''}&type=${type || ''}&city_id=${cityId ||
        ''}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&keyword=${key ||
        ''}&branch_id=${yoCompanyId || ''}`;
      },
      getAll() {
        return `${API_ADMIN_YOUCHE}/product/list?status&resource_id&type=&skip=0&limit=-1`;
      },
      getUsedAllByType(type) {
        return `${API_ADMIN_YOUCHE}/product/list?status=0&resource_id&type=${type}&skip=0&limit=-1`;
      },
      getUsedAndSpecificAutoType(type) {
        return `${API_ADMIN_YOUCHE}/product/list?status=0&is_specific_auto_type=1&resource_id&type=${type}&skip=0&limit=-1`;
      },
      detail(id) {
        return `${API_ADMIN_YOUCHE}/product/detail?product_id=${id}`;
      },
      add() {
        return `${API_ADMIN_YOUCHE}/product/create`;
      },
      editBase() {
        return `${API_ADMIN_YOUCHE}/product/edit-base`;
      },
      editRisk() {
        return `${API_ADMIN_YOUCHE}/product/edit-risk`;
      },
      editFixFinance() {
        return `${API_ADMIN_YOUCHE}/product/edit-amount-fix-finance`;
      },
      editLoanFinance() {
        return `${API_ADMIN_YOUCHE}/product/edit-loan-finance`;
      },
      editMaterial() {
        return `${API_ADMIN_YOUCHE}/product/edit-material`;
      },
      offline() {
        return `${API_ADMIN_YOUCHE}/product/offline`;
      },
      online() {
        return `${API_ADMIN_YOUCHE}/product/online`;
      },
      editHot() {
        return `${API_ADMIN_YOUCHE}/product/edit-hot`;
      },
      calculateTypeConfig() {
        return `${API_ADMIN_YOUCHE}/product/get-calculate-type-config`;
      },

      // 适用城市
      company: {
        list(condition) {
          const { page, productId } = condition;
          return `${API_ADMIN_YOUCHE}/product/product-company-list?product_id=${productId}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_YOUCHE}/product/add-product-company`;
        },
        edit() {
          return `${API_ADMIN_YOUCHE}/product/edit-product-company`;
        },
        delete() {
          return `${API_ADMIN_YOUCHE}/product/delete-product-company`;
        },
      },
    },

    // 方案
    plan: {
      list(condition) {
        const { page, cityId, status, guidePrice, productId, productType } = condition;
        return `${API_ADMIN_YOUCHE}/plan/list?product_id=${productId}&guide_price=${guidePrice}&product_type=${productType}&status=${status}&city_id=${cityId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAllByGuidePrice(guidePrice) {
        return `${API_ADMIN_YOUCHE}/plan/list?guide_price=${guidePrice}&product_id=&product_type=&status=&city_id=skip=0&limit=-1`;
      },
      detail(planId) {
        return `${API_ADMIN_YOUCHE}/plan/detail?plan_id=${planId}`;
      },
      offline() {
        return `${API_ADMIN_YOUCHE}/plan/offline`;
      },
      online() {
        return `${API_ADMIN_YOUCHE}/plan/online`;
      },
      addAmountFixPlan() {
        return `${API_ADMIN_YOUCHE}/plan/create-amount-fix-plan`;
      },
      addLoanPlan() {
        return `${API_ADMIN_YOUCHE}/plan/create-loan-plan`;
      },
      editAmountFix() {
        return `${API_ADMIN_YOUCHE}/plan/edit-amount-fix-plan`;
      },
      editLoan() {
        return `${API_ADMIN_YOUCHE}/plan/edit-loan-plan`;
      },
      addAutoType() {
        return `${API_ADMIN_YOUCHE}/plan/create-auto-type`;
      },
      editHot() {
        return `${API_ADMIN_YOUCHE}/plan/edit-hot`;
      },
    },

    // 订单
    order: {
      create() {
        return `${API_ADMIN_YOUCHE}/order/create-order`;
      },
      cancel() {
        return `${API_ADMIN_YOUCHE}/order/cancel-order`;
      },
      delete() {
        return `${API_ADMIN_YOUCHE}/order/delete`;
      },
      list(condition) {
        return `${API_ADMIN_YOUCHE}/order/list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&key=${condition.searchKey}&biz_type=${condition.bizType}&status=${condition.status}`;
      },
      intentionList(condition) {
        return `${API_ADMIN_YOUCHE}/order/intention-list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&key=${condition.searchKey}&biz_type=${condition.bizType}`;
      },
      materialList(id, type) {
        return `${API_ADMIN_YOUCHE}/order/material-list?order_id=${id}&material_type=${type}`;
      },
      detail(orderId) {
        return `${API_ADMIN_YOUCHE}/order/detail?order_id=${orderId}`;
      },
      updateState() {
        return `${API_ADMIN_YOUCHE}/order/update-status`;
      },
      editAuto() {
        return `${API_ADMIN_YOUCHE}/order/edit-auto`;
      },
      editLoan() {
        return `${API_ADMIN_YOUCHE}/order/edit-loan-finance`;
      },
      editFixAmountFinance() {
        return `${API_ADMIN_YOUCHE}/order/edit-fix-amount-finance`;
      },
      editCustomer() {
        return `${API_ADMIN_YOUCHE}/order/edit-customer`;
      },
      insuranceConfig() {
        return `${API_ADMIN_YOUCHE}/order/get-insurance-config`;
      },
      insuranceLogDetail(orderId) {
        return `${API_ADMIN_YOUCHE}/order/get-insurance-log-detail?order_id=${orderId}`;
      },
      customerDetail(id, customerId) {
        return `${API_ADMIN_YOUCHE}/order/customer-detail?order_id=${id}&customer_id=${customerId}`;
      },
      editInsuranceLog() {
        return `${API_ADMIN_YOUCHE}/order/edit-insurance-log`;
      },
      updateMaterial() {
        return `${API_ADMIN_YOUCHE}/order/update-material`;
      },

      rejectContract() {
        return `${API_ADMIN_YOUCHE}/order/contract-fail`;
      },
      finishContract() {
        return `${API_ADMIN_YOUCHE}/order/contract-finish`;
      },

      //  收益信息
      financeDetail(orderId) {
        return `${API_ADMIN_YOUCHE}/order/get-finance-detail?order_id=${orderId}`;
      },
      editFinance() {
        return `${API_ADMIN_YOUCHE}/order/edit-finance`;
      },
      financeList(condition) {
        return `${API_ADMIN_YOUCHE}/order/finance-list?city_id=${condition.cityId}&resource_id=${condition.resourceId}&skip=${((condition.page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&start_date=${condition.startDate}&end_date=${condition.endDate}`;
      },
      // 操作日志
      operateLogs(orderId) {
        return `${API_ADMIN_YOUCHE}/order/order-log-list?order_id=${orderId}`;
      },

      financeAudit() {
        return `${API_ADMIN_YOUCHE}/order/request-dealer-money`;
      },
      transferFromResource() {
        return `${API_ADMIN_YOUCHE}/order/resource-give-money`;
      },
      transferToDealer() {
        return `${API_ADMIN_YOUCHE}/order/give-dealer-money`;
      },
    },

    // 订单统计
    statistics: {
      summary() {
        return `${API_ADMIN_YOUCHE}/order/status-statistic`;
      },
      list(condition) {
        return `${API_ADMIN_YOUCHE}/order-statistic/list?city_id=${condition.cityId}&month=${condition.month}`;
      },
      add() {
        return `${API_ADMIN_YOUCHE}/order-statistic/create`;
      },
      edit() {
        return `${API_ADMIN_YOUCHE}/order-statistic/edit`;
      },
    },

    // 资源方
    resource: {
      list(condition) {
        return `${API_ADMIN_YOUCHE}/resource/list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll() {
        return `${API_ADMIN_YOUCHE}/resource/list?status=0&skip=0&limit=-1`;
      },
      add() {
        return `${API_ADMIN_YOUCHE}/resource/create`;
      },
      edit() {
        return `${API_ADMIN_YOUCHE}/resource/edit`;
      },
      offline() {
        return `${API_ADMIN_YOUCHE}/resource/offline`;
      },
    },

    // 材料
    material: {
      list(condition) {
        return `${API_ADMIN_YOUCHE}/material/list?resource_id=0&skip=${(condition.page - 1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAllByResourceId(resourceId) {
        return `${API_ADMIN_YOUCHE}/material/list?resource_id=${resourceId}&skip=0&limit=-1`;
      },
      add() {
        return `${API_ADMIN_YOUCHE}/material/create`;
      },
      edit() {
        return `${API_ADMIN_YOUCHE}/material/edit`;
      },
    },

    // 通知
    notice: {
      list() {
        return `${API_ADMIN_YOUCHE}/notice/list?skip=0&limit=`;
      },
      accept() {
        return `${API_ADMIN_YOUCHE}/notice/accept`;
      },
      acceptAll() {
        return `${API_ADMIN_YOUCHE}/notice/accept-all`;
      },
    },
  },

  // 2.门店管家
  daotian: {
    // 维修厂管理
    repairShop: {
      user: {
        getAllRoles() {
          return `${API_ADMIN_DAOTIAN}/user/all-department-roles`;
        },

        getRoles(department) {
          return `${API_ADMIN_DAOTIAN}/user/department-roles?department=${department}`;
        },

        getLoginLimit(companyId) {
          return `${API_ADMIN_DAOTIAN}/user/login-limit?company_id=${companyId}`;
        },

        list(condition) {
          const { phone = '', companyId = '', page = 1, limit } = condition;
          return `${API_ADMIN_DAOTIAN}/user/list?phone=${phone}&company_id=${companyId}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        getWeChatQRCode(userId, companyId) {
          return `${API_ADMIN_DAOTIAN}/user/wechat-band-qrcode?user_id=${userId}&company_id=${companyId}`;
        },

        douyinBandUrl(userId, companyId) {
          return `${API_ADMIN_DAOTIAN}/user/douyin-band-url?user_id=${userId}&company_id=${companyId}`;
        },

        kuaishouBandUrl(userId, companyId) {
          return `${API_ADMIN_DAOTIAN}/user/kuaishou-band-url?user_id=${userId}&company_id=${companyId}`;
        },

        add() {
          return `${API_ADMIN_DAOTIAN}/user/create`;
        },

        edit() {
          return `${API_ADMIN_DAOTIAN}/user/edit`;
        },

        fire() {
          return `${API_ADMIN_DAOTIAN}/user/fire`;
        },

        wechatLoginUnband() {
          return `${API_ADMIN_DAOTIAN}/user/wechat-login-unband`;
        },
      },

      company: {
        list(condition) {
          const { page = 1, key = '', cityId = '', systemType = '', sellerPhone = '', isExpired = '', expireDay = '', startDate = '', endDate = '', payNotExpired = '', currentPayLevel = '', status = '-2', isNewEnergy = '-10' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/maintain-company-list?city_id=${cityId}&expire_day=${expireDay}&system_type=${systemType}&seller_phone=${sellerPhone}&is_expired=${isExpired}&current_pay_level=${currentPayLevel}&is_new_energy=${isNewEnergy}&key=${key}&create_start_date=${startDate}&create_end_date=${endDate}&pay_not_expired=${payNotExpired}&status=${status}&skip=${(page -
            1) * apiAdmin.config.halfLimit}&limit=${apiAdmin.config.halfLimit}`;
        },

        add() {
          return `${API_ADMIN_DAOTIAN}/company/create`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/company/edit`;
        },
        detail(id) {
          return `${API_ADMIN_DAOTIAN}/company/detail?company_id=${id}`;
        },
        close() {
          return `${API_ADMIN_DAOTIAN}/company/close`;
        },
        editBank() {
          return `${API_ADMIN_DAOTIAN}/company/edit-bank`;
        },
        editApp() {
          return `${API_ADMIN_DAOTIAN}/company/edit-app`;
        },
        editPos() {
          return `${API_ADMIN_DAOTIAN}/company/edit-pos`;
        },
        editProfit() {
          return `${API_ADMIN_DAOTIAN}/company/edit-profit`;
        },
        editContact() {
          return `${API_ADMIN_DAOTIAN}/company/edit-contact`;
        },
        editOther() {
          return `${API_ADMIN_DAOTIAN}/company/edit-other`;
        },

        giveSms() {
          return `${API_ADMIN_DAOTIAN}/company/give-sms`;
        },
        cleanData() {
          return `${API_ADMIN_DAOTIAN}/company/clean-data`;
        },
        statistics(id) {
          return `${API_ADMIN_DAOTIAN}/company/company-statistic?company_id=${id}`;
        },
        orderSummary(id) {
          return `${API_ADMIN_DAOTIAN}/company/part-order-summary?company_id=${id}`;
        },
        brandIntentionList(companyId) {
          return `${API_ADMIN_DAOTIAN}/company/company-brand-intention-list?company_id=${companyId}`;
        },

        partDealer(companyId) {
          return `${API_ADMIN_DAOTIAN}/company/company-part-dealer-list?company_id=${companyId}`;
        },

        servicePurchaseList(condition) {
          const { purchaseLevel = '', inviteDealerId = '', page = 1, companyId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/service-purchase-list?purchase_level=${purchaseLevel}&company_id=${companyId}&invite_dealer_id=${inviteDealerId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        exportServicePurchase(condition) {
          const { purchaseLevel = '', inviteDealerId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/export-service-purchase?purchase_level=${purchaseLevel}&invite_dealer_id=${inviteDealerId}&rtick=${new Date().getTime()}`;
        },

        levelList() {
          return `${API_ADMIN_DAOTIAN}/company/service-purchase-level-list`;
        },

        adminFollowUpList(condition) {
          const { adminUserId = '', companyId = '', page = 1, startDate = '', endDate = '', status = '-2', startId = '', subType = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/admin-follow-up-list?admin_user_id=${adminUserId}&status=${status}&sub_type=${subType}&company_id=${companyId}&start_id=${startId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        adminFollowUpLTypeist() {
          return `${API_ADMIN_DAOTIAN}/company/admin-follow-up-type-list`;
        },
        addAdminFollowUp() {
          return `${API_ADMIN_DAOTIAN}/company/add-admin-follow-up`;
        },
        stopService() {
          return `${API_ADMIN_DAOTIAN}/company/stop-purchased-service`;
        },
        editAppExpireTime() {
          return `${API_ADMIN_DAOTIAN}/company/edit-app-expire-time`;
        },
        systemTypeList() {
          return `${API_ADMIN_DAOTIAN}/company/system-type-list`;
        },

        editSystemType() {
          return `${API_ADMIN_DAOTIAN}/company/edit-system-type`;
        },

        editDealer() {
          return `${API_ADMIN_DAOTIAN}/company/edit-part-dealer`;
        },

        servicePurchaseLevelByCompany(companyId) {
          return `${API_ADMIN_DAOTIAN}/company/service-purchase-level-list-by-company?company_id=${companyId}`;
        },

        getServicePurchasePayUrl(companyId, level) {
          return `${API_ADMIN_DAOTIAN}/company/get-service-purchase-pay-url?company_id=${companyId}&purchase_level=${level}`;
        },

        getAdminQRCodeUrl(companyId) {
          return `${API_ADMIN_DAOTIAN}/company/get-band-company-admin-qrcode-url?company_id=${companyId}`;
        },

        clueAddFollowUp() {
          return `${API_ADMIN_DAOTIAN}/company/clue-add-admin-follow-up`;
        },

        clueAdminFollowUpList(condition) {
          const { adminUserId = '', companyClueId = '', page = 1, startDate = '', endDate = '', status = '-2', startId = '', effectiveLevel = '-10' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/clue-admin-follow-up-list?admin_user_id=${adminUserId}&status=${status}&effective_level=${effectiveLevel}&company_clue_id=${companyClueId}&start_id=${startId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      // 积分
      integral: {
        incomeList(condition) {
          const { companyId = '', page = 1, startDate = '', endDate = '', subType = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/company-bonus-point/income-list?company_id=${companyId}&sub_type=${subType}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        outcomeList(condition) {
          const { companyId = '', page = 1, startDate = '', endDate = '', subType = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/company-bonus-point/outcome-list?company_id=${companyId}&sub_type=${subType}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },
        rebate() {
          return `${API_ADMIN_DAOTIAN}/company-bonus-point/rebate`;
        },

        consume() {
          return `${API_ADMIN_DAOTIAN}/company-bonus-point/consume`;
        },

        exchangeSystemService() {
          return `${API_ADMIN_DAOTIAN}/company-bonus-point/exchange-system-service`;
        },

        serviceExchangeLevelList(companyId) {
          return `${API_ADMIN_DAOTIAN}/company-bonus-point/service-exchange-level-list?company_id=${companyId}`;
        },
      },

      active: {
        list(condition) {
          const { page, cityId = '', startDate = '', endDate = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/company-active-reward-list?city_id=${cityId}&startDate=${startDate}&endDate=${endDate}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      clue: {
        list(condition) {
          const { page = 1, limit, key = '', cityId = '', countryId = '', status = '-2', bizType = '-2', energyIntent = '-10' } = condition;
          return `${API_ADMIN_DAOTIAN}/company/clue-company-list?key=${key}&city_id=${cityId}&country_id=${countryId}&energy_intent=${energyIntent}&status=${status}&biz_type=${bizType}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },
        updateStatus() {
          return `${API_ADMIN_DAOTIAN}/company/update-clue-company-status`;
        },
        updateBizType() {
          return `${API_ADMIN_DAOTIAN}/company/update-clue-company-biz-type`;
        },

        updateClueCompanyEnergyIntent() {
          return `${API_ADMIN_DAOTIAN}/company/update-clue-company-energy-intent`;
        },
      },

      chain: {
        list(key, page) {
          return `${API_ADMIN_DAOTIAN}/chain/chain-list?key=${key}&skip=${((page - 1) ||
            apiAdmin.config.skip) * 15}&limit=${15}`;
        },
        allList() {
          return `${API_ADMIN_DAOTIAN}/chain/chain-list?key=&skip=0&limit=-1`;
        },
        add() {
          return `${API_ADMIN_DAOTIAN}/chain/create`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/chain/edit`;
        },

        companyList(condition) {
          const { page = 1, chainId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/chain/company-list?chain_id=${chainId}&skip=${(page -
            1) * apiAdmin.config.halfLimit}&limit=${apiAdmin.config.halfLimit}`;
        },

        removeCompany() {
          return `${API_ADMIN_DAOTIAN}/chain/remove-company`;
        },

        addCompany() {
          return `${API_ADMIN_DAOTIAN}/chain/add-company`;
        },
      },

      workOrder: {
        list(condition) {
          const { page, key, status, payStatus, companyId = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/maintain/intention-list?key=${key}&status=${status}&pay_status=${payStatus}&company_id=${companyId}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        exportCompany(id) {
          return `${API_ADMIN_DAOTIAN}/maintain/export-company-intention?company_id=${id}&rtick=${new Date().getTime()}`;
        },
      },

      auto: {
        list(condition) {
          const { page, companyId = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/company/auto-list?company_id=${companyId}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        exportCompanyAuto(id) {
          return `${API_ADMIN_DAOTIAN}/company/export-company-auto?company_id=${id}&rtick=${new Date().getTime()}`;
        },
      },

      prepayCustomer: {
        list(condition) {
          const { page, companyId = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/company/prepay-customer-list?company_id=${companyId}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        exportCompanyPrepayCustomer(id) {
          return `${API_ADMIN_DAOTIAN}/company/export-company-prepay-customer?company_id=${id}&rtick=${new Date().getTime()}`;
        },
      },

      customerCoupon: {
        list(condition) {
          const { page, companyId = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/company/customer-coupon-item-list?company_id=${companyId}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        exportCompanyCoupon(id) {
          return `${API_ADMIN_DAOTIAN}/company/export-company-customer-coupon-item?company_id=${id}&rtick=${new Date().getTime()}`;
        },
      },

      // 收款码
      receiveMoneyQRCode: {
        incomeList(condition) {
          const { page, startTime, endTime, formType = '', companyId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/company-wallet/income-list?company_id=${companyId}&from_type=${formType}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        withdrawList(condition) {
          const { page, startTime, endTime, companyId = '', status = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/company-wallet/withdraw-list?company_id=${companyId}&status=${status}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        withdraw() {
          return `${API_ADMIN_DAOTIAN}/company-wallet/withdraw-auth`;
        },
        withdrawCancel() {
          return `${API_ADMIN_DAOTIAN}/company-wallet/withdraw-cancel`;
        },
        withdrawRequest() {
          return `${API_ADMIN_DAOTIAN}/company-wallet/withdraw-request`;
        },
        companyWithdraw(companyId) {
          return `${API_ADMIN_DAOTIAN}/company-wallet/get-company-wallet?company_id=${companyId}`;
        },
      },

      // 车主资金
      customerWallet: {
        incomeList(condition) {
          const { page, startTime, endTime, formType = '', companyId = '', customerId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/customer-wallet/income-list?company_id=${companyId}&customer_id=${customerId}&from_type=${formType}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        withdrawList(condition) {
          const { page, startTime, endTime, companyId = '', status = '', customerId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/customer-wallet/withdraw-list?company_id=${companyId}&status=${status}&customer_id=${customerId}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        withdraw() {
          return `${API_ADMIN_DAOTIAN}/customer-wallet/withdraw-auth`;
        },

        wxpayList(condition) {
          const { page = 1, status = '', unionid = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/customer-wallet/wx-pay-direct-list?unionid=${unionid}&status=${status}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        wxpayAuth() {
          return `${API_ADMIN_DAOTIAN}/customer-wallet/wx-pay-direct-auth`;
        },
      },
    },

    wechat: {
      customer: {
        list(condition) {
          const { page, key } = condition;
          return `${API_ADMIN_DAOTIAN}/wechat-customer/customer-list?keyword=${key}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        unBandPhone() {
          return `${API_ADMIN_DAOTIAN}/wechat-customer/unband-phone`;
        },

        companyCustomerSummary(companyId) {
          return `${API_ADMIN_DAOTIAN}/wechat-customer/company-customer-summary?company_id=${companyId}`;
        },
      },
      article: {
        list(condition) {
          const { page, limit } = condition;
          return `${API_ADMIN_DAOTIAN}/wechat-customer/news-list?skip=${(page - 1) *
          (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },
        detail(id) {
          return `${API_ADMIN_DAOTIAN}/wechat-customer/news-detail?media_id=${id}`;
        },
      },

      push: {
        list(condition) {
          const { page, id = '', companyId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/wechat-customer/news-push-list?media_id=${id}&company_id=${companyId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        detail(id) {
          return `${API_ADMIN_DAOTIAN}/wechat-customer/news-detail?media_id=${id}`;
        },
        status(id, companyId) {
          return `${API_ADMIN_DAOTIAN}/wechat-customer/news-push-status?media_id=${id}&company_id=${companyId}`;
        },
        new() {
          return `${API_ADMIN_DAOTIAN}/wechat-customer/create-news-push`;
        },
      },

      auto: {
        list(condition) {
          const { page, key } = condition;
          return `${API_ADMIN_DAOTIAN}/wechat-shuidao/list?keyword=${key}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      list(condition) {
        const { page, key } = condition;
        return `${API_ADMIN_DAOTIAN}/wechat-daotian/list?keyword=${key}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      unBandPhone() {
        return `${API_ADMIN_DAOTIAN}/wechat-daotian/unband-phone`;
      },
    },

    douyinCustomer: {
      list(condition) {
        const { page = 1, key = '', branchId = '', branchUserId = '', companyId = '', onlyBranch = '0', onlyCompany = '0', status = '0' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-list?keyword=${key}&branch_id=${branchId}&company_id=${companyId}&branch_user_id=${branchUserId}&status=${status}&only_branch=${onlyBranch}&only_company=${onlyCompany}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      customerDetail(customerId) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-detail?douyin_customer_id=${customerId}`;
      },

      imUserList(condition) {
        const { page = 1, limit, customerId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-im-user-list?douyin_customer_id=${customerId}&skip=${(page -
          1) *
        (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },

      imList(condition) {
        const { page = 1, limit, customerId = '', fromUserId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-im-list?douyin_customer_id=${customerId}&from_user_id=${fromUserId}&skip=${(page -
          1) *
        (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },

      sendIm() {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-send-im`;
      },

      customerExport(condition) {
        const { key = '', branchId = '', branchUserId = '', companyId = '', onlyBranch = '0', onlyCompany = '0', status = '0' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/export-customer-statistic?keyword=${key}&branch_id=${branchId}&company_id=${companyId}&branch_user_id=${branchUserId}&status=${status}&only_branch=${onlyBranch}&only_company=${onlyCompany}&rtick=${new Date().getTime()}`;
      },

      companyCustomerSummary(companyId) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/company-customer-summary?company_id=${companyId}`;
      },

      updateVideoList(customerId) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/update-video-list?douyin_customer_id=${customerId}`;
      },

      douYinVideoList(condition) {
        const { branchId = '', customerId = '', companyId = '', page = 1, status = '-10', branchUserId = '', packageId = '', onlyBranch = '0', onlyCompany = '0', startCreateTime = '', endCreateTime = '', limit } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/video-list?branch_id=${branchId}&branch_user_id=${branchUserId}&company_id=${companyId}&package_id=${packageId}&status=${status}&start_create_time=${startCreateTime}&end_create_time=${endCreateTime}&douyin_customer_id=${customerId}&only_branch=${onlyBranch}&only_company=${onlyCompany}&skip=${(page -
          1)
        * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },

      updateVideoData(condition) {
        const { douyinCustomerId = '', douyinVideoId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/update-video-data?douyin_customer_id=${douyinCustomerId}&douyin_video_id=${douyinVideoId}`;
      },

      douYinCommentList(condition) {
        const { videoId = '', page = 1, limit = apiAdmin.config.limit } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/video-comment-list?douyin_video_id=${videoId}&skip=${(page -
          1)
        * limit}&limit=${limit || apiAdmin.config.limit}`;
      },

      douYinCommentReplyList(condition) {
        const { commentId = '', page = 1, limit = apiAdmin.config.limit } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/video-comment-reply-list?comment_id=${commentId}&skip=${0}&limit=-1`;
      },

      updateVideoComment() {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/update-video-comment`;
      },

      replyVideoComment() {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/reply-video-comment`;
      },

      export(condition) {
        const { branchId = '', customerId = '', companyId = '', status = '-10', branchUserId = '', packageId = '', onlyBranch = '0', onlyCompany = '0', startCreateTime = '', endCreateTime = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/export-video-statistic?branch_id=${branchId}&branch_user_id=${branchUserId}&company_id=${companyId}&package_id=${packageId}&status=${status}&start_create_time=${startCreateTime}&end_create_time=${endCreateTime}&douyin_customer_id=${customerId}&only_branch=${onlyBranch}&only_company=${onlyCompany}&rtick=${new Date().getTime()}`;
      },

      statisticItems(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-statistic-items?douyin_customer_id=${id}`;
      },

      statisticComments(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-statistic-comments?douyin_customer_id=${id}`;
      },

      statisticProfile(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-statistic-profile?douyin_customer_id=${id}`;
      },

      statisticFans(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-statistic-fans?douyin_customer_id=${id}`;
      },

      fansProfile(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-fans-profile?douyin_customer_id=${id}`;
      },

      fansList(condition) {
        const { page = 1, customerId = '', isFollower = '-10', limit, provinceId = '', cityId = '', key = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-fans-list?keyword=${key}&douyin_customer_id=${customerId}&province_id=${provinceId}&city_id=${cityId}&is_follower=${isFollower}&skip=${(page - 1) *
        (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },

      leadsList(condition) {
        const { page = 1, customerId = '', isFollower = '-10', limit, provinceId = '', cityId = '', leadsLevel = '-10', key = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-leads-list?keyword=${key}&douyin_customer_id=${customerId}&province_id=${provinceId}&city_id=${cityId}&leads_level=${leadsLevel}&is_follower=${isFollower}&skip=${(page - 1) *
        (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },

      statisticBase(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/video-statistic-base?douyin_video_id=${id}`;
      },

      videoDetail(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/video-detail?video_id=${id}`;
      },

      microappCustomerList(condition) {
        const { page = 1, key = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/douyin-customer/microapp-customer-list?keyword=${key}&skip=${(page - 1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      microAppQRCode(id) {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/microapp-company-qrcode?microapp_customer_id=${id}`;
      },

      douYinPoiSearch(key = '', companyId = '') {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/douyin-poi-search?keyword=${key}&company_id=${companyId}&skip=0&limit=10`;
      },

      updateCustomerPoi() {
        return `${API_ADMIN_DAOTIAN}/douyin-customer/update-customer-poi`;
      },

      videoPlan: {
        list(condition) {
          const { douyinCustomerId = '', page = 1, status = '-10', startPlanTime = '', endPlanTime = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/douyin-customer/video-plan-list?douyin_customer_id=${douyinCustomerId}&status=${status}&start_plan_time=${startPlanTime}&end_plan_time=${endPlanTime}&skip=${(page - 1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        create() {
          return `${API_ADMIN_DAOTIAN}/douyin-customer/create-video-plan`;
        },

        edit() {
          return `${API_ADMIN_DAOTIAN}/douyin-customer/edit-video-plan`;
        },

        detail(planId) {
          return `${API_ADMIN_DAOTIAN}/douyin-customer/video-plan-detail?plan_id=${planId}`;
        },

        updateStatus() {
          return `${API_ADMIN_DAOTIAN}/douyin-customer/update-video-plan-status`;
        },
      },

      askManages: {
        imAskList(condition) {
          const { isAsk = '-1', page = 1 } = condition;
          return `${API_ADMIN_DAOTIAN}/douyin-customer/customer-ask-im-list?is_ask=${isAsk}&skip=${(page - 1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        commentAskList(condition) {
          const { isAsk = '-1', page = 1 } = condition;
          return `${API_ADMIN_DAOTIAN}/douyin-customer/video-ask-comment-list?is_ask=${isAsk}&skip=${(page - 1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },
    },

    douyinActivity: {
      list(condition) {
        const { page = 1 } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/douyin-package-list?skip=${(page - 1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    kuaishou: {
      customer: {
        list(condition) {
          const { page = 1, key = '', branchId = '', branchUserId = '', companyId = '', onlyBranch = '0', onlyCompany = '0', status = '0' } = condition;
          return `${API_ADMIN_DAOTIAN}/kuaishou-customer/customer-list?keyword=${key}&branch_id=${branchId}&company_id=${companyId}&branch_user_id=${branchUserId}&status=${status}&only_branch=${onlyBranch}&only_company=${onlyCompany}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        detail(customerId) {
          return `${API_ADMIN_DAOTIAN}/kuaishou-customer/customer-detail?kuaishou_customer_id=${customerId}`;
        },
      },

      video: {
        list(condition) {
          const { branchId = '', customerId = '', page = 1, status = '-10', branchUserId = '', packageId = '', onlyBranch = '0', startCreateTime = '', endCreateTime = '', limit } = condition;
          return `${API_ADMIN_DAOTIAN}/kuaishou-customer/video-list?branch_id=${branchId}&branch_user_id=${branchUserId}&status=${status}&kuaishou_customer_id=${customerId}&package_id=${packageId}&only_branch=${onlyBranch}&start_create_time=${startCreateTime}&end_create_time=${endCreateTime}&skip=${(page -
            1)
          * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        detail(videoId) {
          return `${API_ADMIN_DAOTIAN}/kuaishou-customer/video-detail?video_id=${videoId}`;
        },
      },
    },

    // 员工资金
    personnelWallet: {
      incomeList(condition) {
        const { page, startTime, endTime, formType = '', companyId = '', userId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/company-user-wallet/income-list?company_id=${companyId}&user_id=${userId}&from_type=${formType}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      withdrawList(condition) {
        const { page, startTime, endTime, companyId = '', status = '', userId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/company-user-wallet/withdraw-list?company_id=${companyId}&user_id=${userId}&status=${status}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      withdraw() {
        return `${API_ADMIN_DAOTIAN}/company-user-wallet/withdraw-auth`;
      },
    },

    statistics: {
      companyMaintainSummary(companyId) {
        return `${API_ADMIN_DAOTIAN}/statistic/company-maintain-summary?company_id=${companyId}`;
      },
    },

    repairCommercialTenant: {
      list(condition) {
        const { page = 1, key } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-repair/company-list?keyword=${key}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll() {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/company-list?keyword=&skip=0&limit=-1`;
      },
      expandExpireDate() {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/update-company-expire-date`;
      },
      unbindPhone() {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/unband-phone`;
      },

      bindPhone() {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/band-user-phone`;
      },

      qrcodeList(companyId) {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/company-qrcode-list?company_id=${companyId}`;
      },
      moveQrcodeCompanyList(condition) {
        const { page, status } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-repair/move-qrcode-company-list?status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    dealerCommercialTenant: {
      list(condition) {
        const { page, key } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-auto-dealer/company-list?keyword=${key}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll() {
        return `${API_ADMIN_DAOTIAN}/weapp-auto-dealer/company-list?keyword=&skip=0&limit=-1`;
      },
      expandExpireDate() {
        return `${API_ADMIN_DAOTIAN}/weapp-auto-dealer/update-company-expire-date`;
      },
    },

    customer: {
      list(condition) {
        const { page, key } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-customer/customer-list?keyword=${key}&skip=${(page - 1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      requestList(condition) {
        const { page, qrcodeId } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-customer/move-request-list?auto_qrcode_id=${qrcodeId}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      bandList(condition) {
        const { page, qrcodeId = '', customerId = '', key = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-customer/auto-qrcode-band-list?keyword=${key}&auto_qrcode_id=${qrcodeId}&weapp_customer_id=${customerId}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      leadsList(condition) {
        const { page } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-customer/move-leads-list?skip=${(page - 1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      customerPhone(phone) {
        return `${API_ADMIN_SYSTEM}/customer/customer-detail-by-phone?phone=${phone}`;
      },

      bandQRCode(customerId) {
        return `${API_ADMIN_SYSTEM}/customer/wechat-band-qrcode?customer_id=${customerId}`;
      },
    },

    activity: {
      list(condition) {
        const { page = 1, key = '', type = '', companyId = '', isListVisible = '-1', status = '-10', limit } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-list?keyword=${key}&is_list_visible=${isListVisible}&company_id=${companyId}&status=${status}&from_type=${type}&skip=${(page -
          1)
        * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },
      create() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/create-package`;
      },
      edit() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package`;
      },
      editPackageItem() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-item`;
      },
      editPackageRaffle() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-raffle`;
      },
      editPackageConfig() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-config`;
      },
      editPackageAdmin() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-admin`;
      },
      detail(packageId, companyId) {
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-detail?package_id=${packageId}&company_id=${companyId}`;
      },

      douYinDetail(packageId, companyId) {
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-douyin-config?package_id=${packageId}&company_id=${companyId}`;
      },

      channelDetail(packageId, customerId) {
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-channel-detail?package_id=${packageId}&customer_id=${customerId}`;
      },

      updatePackageStatus() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/update-package-status`;
      },
      editPackageChannel() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-channel`;
      },
      editPackageMultiConsume() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-multi-consume`;
      },

      editPackageDouYin() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-douyin`;
      },

      genRaffleCode() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/gen-raffle-code`;
      },

      raffleCodeList(condition) {
        const { packageId = '', status = '-2', page = 1, parentPackageId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-raffle-code-list?package_id=${packageId}&parent_package_id=${parentPackageId}&status=${status}&skip=${(page - 1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      updateRaffleCodePrinted() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/update-raffle-code-printed`;
      },

      editPackagePoster() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-package-poster`;
      },
    },

    activityPay: {
      list(condition) {
        const { packageId = '', page = 1, type = '', customerId = '', companyId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-pay-list?package_id=${packageId}&customer_id=${customerId}&company_id=${companyId}&from_type=${type}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      clueList(condition) {
        const { page, packageId = '', type = '', customerId = '', companyId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-view-clue-list?package_id=${packageId}&customer_id=${customerId}&company_id=${companyId}&from_type=${type}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    packageItemConsume: {
      list(condition) {
        const { page = 1, packageId = '', companyId = '', customerId = '', type = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/package-item-consume-list?customer_id=${customerId}&package_id=${packageId}&from_type=${type}&company_id=${companyId}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    dealerActivityPay: {
      list(condition) {
        const { packageId = '', page } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-auto-dealer/package-pay-list?package_id=${packageId}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    servicePurchase: {
      list(condition) {
        const { page } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-repair/service-purchase-list?skip=${(page - 1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    dealerServicePurchase: {
      list(condition) {
        const { page } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-auto-dealer/service-purchase-list?skip=${(page - 1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    partOrder: {
      list(condition) {
        const { page, status = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-repair/part-specific-order-list?status=${status}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    oldPart: {
      list(condition) {
        const { page, key = '', companyId = '', cityId = '', brandId = '', seriesId = '', typeId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-repair/old-part-list?keyword=${key}&company_id=${companyId}&city_id=${cityId}&auto_brand_id=${brandId}&auto_series_id=${seriesId}&auto_type_id=${typeId}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      msgList(partId) {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/part-msg-list?part_id=${partId}&skip=0&limit=-1`;
      },
      subList(partId, pId) {
        return `${API_ADMIN_DAOTIAN}/weapp-repair/part-msg-sub-list?part_id=${partId}&msg_pid=${pId}&skip=0&limit=-1`;
      },
    },

    orderBusiness: {
      list(condition) {
        const { page, key = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/dealer-list?keyword=${key}&skip=${(page - 1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    orderDeposit: {
      list(condition) {
        const { page } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/security-deposit-charge-list?skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    partDealerOld: {
      list(condition) {
        const { page, key = '', companyId = '', cityId = '', brandId = '', seriesId = '', typeId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/old-part-list?keyword=${key}&company_id=${companyId}&city_id=${cityId}&auto_brand_id=${brandId}&auto_series_id=${seriesId}&auto_type_id=${typeId}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      msgList(partId) {
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/part-msg-list?part_id=${partId}&skip=0&limit=-1`;
      },
      subList(partId, pId) {
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/part-msg-sub-list?part_id=${partId}&msg_pid=${pId}&skip=0&limit=-1`;
      },
    },

    orderQuote: {
      list(condition) {
        const { page, orderId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/quote-list?order_id=${orderId}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    activityTemplate: {
      list(condition) {
        const { page, key, type } = condition;
        return `${API_ADMIN_DAOTIAN}/coupon-package/template-list?keyword=${key}&type=${type}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll(key, type) {
        return `${API_ADMIN_DAOTIAN}/coupon-package/template-list?keyword=${key}&type=${type}&skip=0&limit=-1`;
      },
      types(type) {
        return `${API_ADMIN_DAOTIAN}/coupon-package/get-template-type-list?type=${type}`;
      },
      add() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/create-template`;
      },
      edit() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/edit-template`;
      },
      switch() {
        return `${API_ADMIN_DAOTIAN}/coupon-package/update-template-status`;
      },
    },

    // 数据配置
    dataManage: {
      maintainItem: {
        list(condition) {
          const { page, key, maintainType } = condition;
          return `${API_ADMIN_DAOTIAN}/maintain/item-list?keyword=${key}&maintain_type=${maintainType}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : ''}`;
        },
        types() {
          return `${API_ADMIN_DAOTIAN}/maintain/type-list`;
        },
        add() {
          return `${API_ADMIN_DAOTIAN}/maintain/add-item`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/maintain/edit-item`;
        },
      },
    },

    report: {
      list(condition) {
        const { page = 1, companyId, autoId, customerId } = condition;
        return `${API_ADMIN_DAOTIAN}/maintain/report-list?customer_id=${customerId}&company_id=${companyId}&auto_id=${autoId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : ''}`;
      },

      detail(reportId) {
        return `${API_ADMIN_DAOTIAN}/maintain/report-detail?report_id=${reportId}`;
      },

      itemList(reportId, errorOnly) {
        return `${API_ADMIN_DAOTIAN}/maintain/report-item-list?report_id=${reportId}&error_only=${errorOnly}`;
      },

      maintainItemList(reportId) {
        return `${API_ADMIN_DAOTIAN}/maintain/report-maintain-item-list?report_id=${reportId}`;
      },
    },
  },

  // 新能源
  newEnergy: {
    // 车检项目
    diagnose: {
      list(condition) {
        const { page, key, status = '-10', brandId = '' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/diagnose/list?key=${key}&auto_brand_id=${brandId}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },
      updateStatus() {
        return `${API_ADMIN_NEW_ENERGY}/diagnose/update-status`;
      },
      add() {
        return `${API_ADMIN_NEW_ENERGY}/diagnose/create`;
      },
      edit() {
        return `${API_ADMIN_NEW_ENERGY}/diagnose/edit`;
      },

      updateZip() {
        return `${API_ADMIN_NEW_ENERGY}/diagnose/update-zip`;
      },
    },

    // 课程商品
    courseGoods: {
      add() {
        return `${API_ADMIN_NEW_ENERGY}/course/create-goods`;
      },

      edit() {
        return `${API_ADMIN_NEW_ENERGY}/course/edit-goods`;
      },

      list(condition) {
        const { page, key, status = '-10' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/course/goods-list?key=${key}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },

      updateStatus() {
        return `${API_ADMIN_NEW_ENERGY}/course/update-goods-status`;
      },

      orderList(condition) {
        const { page, customerId = '', status = '-10', goodsId = '' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/course/goods-order-list?customer_id=${customerId}&goods_id=${goodsId}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },

      viewClueList(condition) {
        const { page, customerId = '', goodsId = '' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/course/goods-view-clue-list?customer_id=${customerId}&goods_id=${goodsId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },
    },

    // 课程优惠券
    courseCoupon: {
      add() {
        return `${API_ADMIN_NEW_ENERGY}/course/create-coupon`;
      },

      edit() {
        return `${API_ADMIN_NEW_ENERGY}/course/edit-coupon`;
      },

      list(condition) {
        const { page, key, status = '-10' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/course/coupon-list?key=${key}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },

      updateStatus() {
        return `${API_ADMIN_NEW_ENERGY}/course/update-coupon-status`;
      },

      couponList(condition) {
        const { page, customerId = '', status = '-10', couponId = '' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/course/customer-coupon-list?customer_id=${customerId}&coupon_id=${couponId}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },

      viewClueList(condition) {
        const { page, customerId = '', couponId = '' } = condition;
        return `${API_ADMIN_NEW_ENERGY}/course/coupon-view-clue-list?customer_id=${customerId}&coupon_id=${couponId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },
    },
    charge: {
      station: {
        list(condition) {
          const { page, cityId = '', status = '-10' } = condition;
          return `${API_ADMIN_NEW_ENERGY}/charge/station-list?city_id=${cityId}&status=${status}&skip=${(page -
            1 || 0) * apiAdmin.config.limit}&limit=${
            page ? apiAdmin.config.limit : '-1'
          }`;
        },

        detail(stationId) {
          return `${API_ADMIN_NEW_ENERGY}/charge/station-detail?station_id=${stationId}`;
        },

        editStationPolicy() {
          return `${API_ADMIN_NEW_ENERGY}/charge/edit-station-policy`;
        },

        equipmentList(condition) {
          const { page, stationId = '', status = '-10' } = condition;
          return `${API_ADMIN_NEW_ENERGY}/charge/equipment-list?station_id=${stationId}&status=${status}&skip=${(page -
            1 || 0) * apiAdmin.config.limit}&limit=${
            page ? apiAdmin.config.limit : '-1'
          }`;
        },

        connectorList(condition) {
          const { page, stationId = '', status = '-10', equipmentId = '' } = condition;
          return `${API_ADMIN_NEW_ENERGY}/charge/connector-list?station_id=${stationId}&equipment_id=${equipmentId}&status=${status}&skip=${(page -
            1 || 0) * apiAdmin.config.limit}&limit=${
            page ? apiAdmin.config.limit : '-1'
          }`;
        },

        stop() {
          return `${API_ADMIN_NEW_ENERGY}/charge/stop`;
        },
      },

      order: {
        list(condition) {
          const { page, stationId = '', status = '-10', connectorId = '', customerId = '' } = condition;
          return `${API_ADMIN_NEW_ENERGY}/charge/order-list?station_id=${stationId}&connector_id=${connectorId}&customer_id=${customerId}&status=${status}&skip=${(page -
            1 || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
        },
      },
    },

    // 维修资料
    maintainMaterial: {
      list(condition) {
        const { page, key, status = '-2', type = '' } = condition;
        return `${API_ADMIN_SYSTEM}/maintain-material/list?key=${key}&type=${type}&status=${status}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },
      updateStatus() {
        return `${API_ADMIN_SYSTEM}/maintain-material/status-update`;
      },
      add() {
        return `${API_ADMIN_SYSTEM}/maintain-material/create`;
      },
      edit() {
        return `${API_ADMIN_SYSTEM}/maintain-material/edit`;
      },

      detail(id) {
        return `${API_ADMIN_SYSTEM}/maintain-material/detail?material_id=${id}`;
      },

      useLogList(condition) {
        const { page, materialId = '', type = '', userId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/maintain-material/use-log-list?material_id=${materialId}&type=${type}&user_id=${userId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : '-1'}`;
      },
    },
  },

  // 3.水稻管车
  guanche: {
    company: {
      list(condition) {
        const { page, key, cityId } = condition;
        return `${API_ADMIN_GUANCHE}/enterprise/list?key=${key}&city_id=${cityId}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      detail(id) {
        return `${API_ADMIN_GUANCHE}/enterprise/detail?enterprise_id=${id}`;
      },
      add() {
        return `${API_ADMIN_GUANCHE}/enterprise/create`;
      },
      edit() {
        return `${API_ADMIN_GUANCHE}/enterprise/edit`;
      },
    },
    sms: {
      list(condition) {
        const { page, key, cityId, status } = condition;
        return `${API_ADMIN_GUANCHE}/sms/list?key=${key}&city_id=${cityId}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      detail(id) {
        return `${API_ADMIN_GUANCHE}/sms/detail?template_id=${id}`;
      },
    },

    wallet: {
      chargeList(condition) {
        const { enterpriseId = '', startDate = '', endDate = '', page = 1 } = condition;
        return `${API_ADMIN_GUANCHE}/wallet/prepay-charge-list?enterprise_id=${enterpriseId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      prepayCharge() {
        return `${API_ADMIN_GUANCHE}/wallet/prepay-charge`;
      },

      consumeList(condition) {
        const { enterpriseId = '', startDate = '', endDate = '', page = 1 } = condition;
        return `${API_ADMIN_GUANCHE}/wallet/prepay-consume-list?enterprise_id=${enterpriseId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },
  },

  // 经销商
  dealer: {
    wallet: {
      // 车主资金
      incomeList(condition) {
        const { page, startTime, endTime, formType = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/income-list?dealer_id=${dealerId}&from_type=${formType}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      withdrawList(condition) {
        const { page, startTime, endTime, dealerId = '', status = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/withdraw-list?dealer_id=${dealerId}&status=${status}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      withdraw() {
        return `${API_ADMIN_DEALER}/dealer-wallet/withdraw-auth`;
      },
    },

    // 员工资金
    userWallet: {
      incomeList(condition) {
        const { page, formType = '', dealerId = '', userId = '', startTime = '', endTime = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/user-income-list?dealer_id=${dealerId}&user_id=${userId}&from_type=${formType}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      withdrawList(condition) {
        const { page, startTime, endTime, userId = '', dealerId = '', status = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/user-withdraw-list?user_id=${userId}&dealer_id=${dealerId}&status=${status}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      withdraw() {
        return `${API_ADMIN_DEALER}/dealer-wallet/user-withdraw-auth`;
      },
    },

    walletActivity: {
      chargeList(condition) {
        const { page, startTime, endTime, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/activity-charge-list?dealer_id=${dealerId}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      consumeList(condition) {
        const { page, startTime, endTime, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/activity-consume-list?dealer_id=${dealerId}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    sysWallet: {
      chargeList(condition) {
        const { page, startTime, endTime, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/prepay-charge-list?dealer_id=${dealerId}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      consumeList(condition) {
        const { page, startTime, endTime, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer-wallet/prepay-consume-list?dealer_id=${dealerId}&start_date=${startTime}&end_date=${endTime}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    order: {
      list(condition) {
        const { page = 1, status = '', dealerId = '', companyId = '', payStatus = '', payChannel = '' } = condition;
        return `${API_ADMIN_DEALER}/part-order/list?status=${status}&dealer_id=${dealerId}&company_id=${companyId}&pay_status=${payStatus}&pay_channel=${payChannel}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      detail(orderId) {
        return `${API_ADMIN_DEALER}/part-order/detail?order_id=${orderId}`;
      },

      itemList(orderId) {
        return `${API_ADMIN_DEALER}/part-order/item-list?order_id=${orderId}`;
      },

      productIncomeList(id) {
        return `${API_ADMIN_DEALER}/part-order/product-income-item-list-by-order?order_id=${id}`;
      },
    },

    fake: {
      list(condition) {
        const { page = 1, status = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/anti-fake/list?status=${status}&dealer_id=${dealerId}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      create() {
        return `${API_ADMIN_DEALER}/anti-fake/create`;
      },

      edit() {
        return `${API_ADMIN_DEALER}/anti-fake/edit`;
      },

      setDealer() {
        return `${API_ADMIN_DEALER}/anti-fake/set-dealer`;
      },
      qrcodeList(condition) {
        const { page = 1, status = '', companyId = '', partId = '', qrcodeId = '' } = condition;
        return `${API_ADMIN_DEALER}/anti-fake/qrcode-list?status=${status}&company_id=${companyId}&anti_fake_part_id=${partId}&anti_fake_part_qrcode_id=${qrcodeId}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      insuranceLogList(condition) {
        const { page = 1, status = '', companyId = '', partId = '', qrcodeId = '', autoId = '', customerId = '', dealerId } = condition;
        return `${API_ADMIN_DEALER}/anti-fake/extension-insurance-log-list?status=${status}&company_id=${companyId}&anti_fake_part_id=${partId}&anti_fake_part_qrcode_id=${qrcodeId}&auto_id=${autoId}&dealer_id=${dealerId}&customer_id=${customerId}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      insuranceDetail(id) {
        return `${API_ADMIN_DEALER}/anti-fake/extension-insurance-log-detail?log_id=${id}`;
      },

      insuranceAuth() {
        return `${API_ADMIN_DEALER}/anti-fake/auth-extension-insurance`;
      },

      batchList(condition) {
        const { page = 1, fakeId = '', status = '' } = condition;
        return `${API_ADMIN_DEALER}/anti-fake/batch-list?anti_fake_part_id=${fakeId}&status=${status}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      createBatch() {
        return `${API_ADMIN_DEALER}/anti-fake/add-batch`;
      },

      editBatch() {
        return `${API_ADMIN_DEALER}/anti-fake/edit-batch`;
      },

      updateStatus() {
        return `${API_ADMIN_DEALER}/anti-fake/update-status`;
      },

      companyAttendList(condition) {
        const { page = 1, fakeId = '' } = condition;
        return `${API_ADMIN_DEALER}/anti-fake/company-attend-list?anti_fake_part_id=${fakeId}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    finance: {
      transfer: {
        list(condition) {
          const { page } = condition;
          return `${API_ADMIN_DEALER}/part-order/pay-summary-list-by-dealer?skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        transferToBranch() {
          return `${API_ADMIN_DEALER}/part-order/transfer-to-dealer`;
        },
        payments(condition, getAll = false) {
          const {
            page = 1,
            dealerId = '',
            payTimeStart = '',
            payTimeEnd = '',
            transferTimeStart = '',
            transferTimeEnd = '',
            isTransferred = 0,
            sdTransferId = '',
          } = condition;
          return `${API_ADMIN_DEALER}/part-order/pay-list?&dealer_id=${dealerId}&is_sd_transfer=${isTransferred}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&sd_transfer_time_start=${transferTimeStart}&sd_transfer_time_end=${transferTimeEnd}&sd_transfer_id=${sdTransferId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${getAll ? '-1' : apiAdmin.config.limit}`;
        },
        logs(condition) {
          const { page, dealerId = '' } = condition;
          return `${API_ADMIN_DEALER}/part-order/transfer-list?&dealer_id=${dealerId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },
    },

    goods: {
      list(condition) {
        const { page, key, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/part-product/list?keyword=${key}&dealer_id=${dealerId}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      detail(id) {
        return `${API_ADMIN_DEALER}/part-product/detail?product_id=${id}`;
      },

      itemList(condition) {
        const { dealerId = '', productId = '', status = '', key = '', page = 1, limit } = condition;
        return `${API_ADMIN_DEALER}/part-product/item-list?product_id=${productId}&dealer_id=${dealerId}&keyword=${key}&status=${status}&skip=${(page -
          1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
      },

      itemDetail(id, companyId = '') {
        return `${API_ADMIN_DEALER}/part-product/item-detail?product_item_id=${id}&company_id=${companyId}`;
      },
    },

    brandIntention: {
      list(condition) {
        const { page, dealerId = '', brandId = '', partType = '', level = '' } = condition;
        return `${API_ADMIN_DEALER}/part-product/company-brand-intention-list?dealer_id=${dealerId}&brand_id=${brandId}&part_type=${partType}&level=${level}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      export(condition) {
        const { dealerId = '', brandId = '', partType = '', level = '' } = condition;
        return `${API_ADMIN_DEALER}/part-product/export-company-brand-intention?dealer_id=${dealerId}&brand_id=${brandId}&part_type=${partType}&level=${level}&rtick=${new Date().getTime()}`;
      },
    },

    shuiDaoGoods: {
      product: {
        list(condition) {
          const { page, key = '', status = '' } = condition;
          return `${API_ADMIN_DEALER}/shuidao-product/list?keyword=${key}&status=${status}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        create() {
          return `${API_ADMIN_DEALER}/shuidao-product/create`;
        },

        edit() {
          return `${API_ADMIN_DEALER}/shuidao-product/edit`;
        },

        detail(id) {
          return `${API_ADMIN_DEALER}/shuidao-product/detail?product_id=${id}`;
        },

        updateStatus() {
          return `${API_ADMIN_DEALER}/shuidao-product/update-status`;
        },

        updateIsHot() {
          return `${API_ADMIN_DEALER}/shuidao-product/update-is-hot`;
        },

        updateSortOrder() {
          return `${API_ADMIN_DEALER}/shuidao-product/update-sort-order`;
        },

        // 商品项目
        editItems() {
          return `${API_ADMIN_DEALER}/shuidao-product/edit-item`;
        },
        addItems() {
          return `${API_ADMIN_DEALER}/shuidao-product/create-item`;
        },

        itemList(condition) {
          const { page, key = '', status = '', productId = '' } = condition;
          return `${API_ADMIN_DEALER}/shuidao-product/item-list?keyword=${key}&product_id=${productId}&status=${status}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        updateItemStatus() {
          return `${API_ADMIN_DEALER}/shuidao-product/update-item-status`;
        },

        itemDetail(id) {
          return `${API_ADMIN_DEALER}/shuidao-product/item-detail?product_item_id=${id}`;
        },

        additemUnit() {
          return `${API_ADMIN_DEALER}/shuidao-product/add-item-unit`;
        },
        edititemUnit() {
          return `${API_ADMIN_DEALER}/shuidao-product/edit-item-unit`;
        },
      },

      order: {
        list(condition) {
          const { page = 1, status = '', isUnpay = '0', payStatus = '', payChannel = '', orderId = '', outputStatus = '0', createTimeStart = '', createTimeEnd = '', payTimeStart = '', payTimeEnd = '', authTimeStart = '', authTimeEnd = '', supplierId = '', supplierUserId = '', supplierType = '0', limit } = condition;
          return `${API_ADMIN_DEALER}/shuidao-product-order/list?status=${status}&pay_status=${payStatus}&output_status=${outputStatus}&is_unpay=${isUnpay}&pay_channel=${payChannel}&order_id=${orderId}&supplier_id=${supplierId}&supplier_type=${supplierType}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&supplier_user_id=${supplierUserId}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        getAll(condition) {
          const { status = '', payStatus = '', isUnpay = '0', payChannel = '', orderId = '', outputStatus = '0', createTimeStart = '', createTimeEnd = '', payTimeStart = '', payTimeEnd = '', authTimeStart = '', authTimeEnd = '', supplierId = '', supplierUserId = '' } = condition;
          return `${API_ADMIN_DEALER}/shuidao-product-order/list?status=${status}&pay_status=${payStatus}&output_status=${outputStatus}&pay_channel=${payChannel}&is_unpay=${isUnpay}&order_id=${orderId}&supplier_id=${supplierId}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&supplier_user_id=${supplierUserId}&skip=0&limit=-1`;
        },

        export(condition) {
          const { status = '', isUnpay = '0', payStatus = '', payChannel = '', orderId = '', outputStatus = '0', createTimeStart = '', createTimeEnd = '', payTimeStart = '', payTimeEnd = '', authTimeStart = '', authTimeEnd = '', companyId = '', cityId = '', countryId = '', supplierUserId = '' } = condition;
          return `${API_ADMIN_DEALER}/shuidao-product-order/export-product-order?status=${status}&pay_status=${payStatus}&output_status=${outputStatus}&pay_channel=${payChannel}&is_unpay=${isUnpay}&order_id=${orderId}&company_id=${companyId}&city_id=${cityId}&country_id=${countryId}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&ptime_start=${payTimeStart}&ptime_end=${payTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&supplier_user_id=${supplierUserId}&rtick=${new Date().getTime()}`;
        },
        detail(id) {
          return `${API_ADMIN_DEALER}/shuidao-product-order/detail?order_id=${id}`;
        },

        itemList(id) {
          return `${API_ADMIN_DEALER}/shuidao-product-order/item-list?order_id=${id}`;
        },

        auth() {
          return `${API_ADMIN_DEALER}/shuidao-product-order/auth-order`;
        },

        pay() {
          return `${API_ADMIN_DEALER}/shuidao-product-order/pay-order`;
        },

        shipOrder() {
          return `${API_ADMIN_DEALER}/shuidao-product-order/ship-order`;
        },

        addShipLog() {
          return `${API_ADMIN_DEALER}/shuidao-product-order/add-ship-log`;
        },

        rejectOrder() {
          return `${API_ADMIN_DEALER}/shuidao-product-order/reject-order`;
        },
      },
    },

    activity: {
      list(condition) {
        const { page, key, dealerId = '', status = '-2' } = condition;
        return `${API_ADMIN_DEALER}/part-activity/list?keyword=${key}&status=${status}&dealer_id=${dealerId}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll() {
        return `${API_ADMIN_DEALER}/part-activity/list?keyword=&skip=0&limit=-1`;
      },
      detail(id) {
        return `${API_ADMIN_DEALER}/part-activity/detail?activity_id=${id}`;
      },

      event: {
        list(condition) {
          const { status = '', page, dealerId = '', limit } = condition;
          return `${API_ADMIN_DEALER}/event/list?status=${status}&dealer_id=${dealerId}&skip=${((page -
            1) || 0) * (limit || apiAdmin.config.limit)}&limit=${(limit || apiAdmin.config.limit)}`;
        },

        detail(eventId) {
          return `${API_ADMIN_DEALER}/event/detail?event_id=${eventId}`;
        },

        attendList(condition) {
          const { eventId, page, limit } = condition;
          return `${API_ADMIN_DEALER}/event/attend-list?event_id=${eventId}&skip=${((page -
            1) || 0) * (limit || apiAdmin.config.limit)}&limit=${(limit || apiAdmin.config.limit)}`;
        },

        getQRCode(id) {
          return `${API_ADMIN_DEALER}/event/get-qrcode-pic-data?event_id=${id}`;
        },

        export(eventId) {
          return `${API_ADMIN_DEALER}/event/export-attend-list?event_id=${eventId}&rtick=${new Date().getTime()}`;
        },

        clueList(condition) {
          const { eventId, page = 1 } = condition;
          return `${API_ADMIN_DEALER}/event/clue-list?event_id=${eventId}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        exportClue(id) {
          return `${API_ADMIN_DEALER}/event/export-clue-list?event_id=${id}&rtick=${new Date().getTime()}`;
        },
      },
    },

    brandActivity: {
      list(condition) {
        const { page, key } = condition;
        return `${API_ADMIN_DEALER}/part/brand-activity-list?keyword=${key}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll() {
        return `${API_ADMIN_DEALER}/part/brand-activity-list?keyword=&skip=0&limit=-1`;
      },
      detail(id) {
        return `${API_ADMIN_DEALER}/part/brand-activity-detail?activity_id=${id}`;
      },
      attendLogs(condition) {
        const { page = 1, activityId } = condition;
        return `${API_ADMIN_DEALER}/part/brand-activity-log-list?activity_id=${activityId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    conference: {
      list(condition) {
        const { page, status = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/conference/list?status=${status}&dealer_id=${dealerId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      create() {
        return `${API_ADMIN_DEALER}/conference/create`;
      },
      edit() {
        return `${API_ADMIN_DEALER}/conference/edit`;
      },
      detail(conferenceId) {
        return `${API_ADMIN_DEALER}/conference/detail?conference_id=${conferenceId}`;
      },
      startLive() {
        return `${API_ADMIN_DEALER}/conference/start-live`;
      },
      attendList(condition) {
        const { conferenceId, page, isInRoom } = condition;
        return `${API_ADMIN_DEALER}/conference/attend-list?conference_id=${conferenceId}&is_in_room=${isInRoom}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      attendExport(condition) {
        const { conferenceId, page, isInRoom } = condition;
        return `${API_ADMIN_DEALER}/conference/export-attend?conference_id=${conferenceId}&is_in_room=${isInRoom}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&rtick=${new Date().getTime()}`;
      },
      getQRCode(id) {
        return `${API_ADMIN_DEALER}/conference/get-qrcode-pic-data?conference_id=${id}`;
      },
      deductTypes() {
        return `${API_ADMIN_DEALER}/conference/deduct-type-list`;
      },
      deductFee() {
        return `${API_ADMIN_DEALER}/conference/deduct-fee`;
      },
      editRecordUrl() {
        return `${API_ADMIN_DEALER}/conference/edit-live-record`;
      },
      liveChargeList(condition) {
        const { page, conferenceId, dealerId } = condition;
        return `${API_ADMIN_DEALER}/conference/charge-list?conference_id=${conferenceId}&dealer_id=${dealerId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      liveConsumeList(condition) {
        const { conferenceId = '', page = 1 } = condition;
        return `${API_ADMIN_DEALER}/conference/consume-list?conference_id=${conferenceId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      liveConsumeExport(condition) {
        const { conferenceId, dealerId, startDate, endDate } = condition;
        return `${API_ADMIN_DEALER}/conference-wallet/export-consume-list?conference_id=${conferenceId}&dealer_id=${dealerId}&start_date=${startDate}&end_date=${endDate}&rtick=${new Date().getTime()}`;
      },
    },

    team: {
      list(condition) {
        const { page = 1, key = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/list?keyword=${key}&skip=${((page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      getAll() {
        return `${API_ADMIN_DEALER}/dealer/list?skip=0&limit=-1`;
      },

      detail(id) {
        return `${API_ADMIN_DEALER}/dealer/detail?_id=${id}`;
      },

      add() {
        return `${API_ADMIN_DEALER}/dealer/create`;
      },
      edit() {
        return `${API_ADMIN_DEALER}/dealer/edit`;
      },

      userList(condition) {
        const { page = 1, key = '', dealerId = '', status = '-10' } = condition;
        return `${API_ADMIN_DEALER}/dealer/user-list?keyword=${key}&status=${status}&dealer_id=${dealerId}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      fire() {
        return `${API_ADMIN_DEALER}/dealer/fire`;
      },

      getAllUsers(condition) {
        const { key = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/user-list?keyword=${key}&dealer_id=${dealerId}&skip=0&limit=-1`;
      },

      userTrackList(condition) {
        const { dealerId = '', userId = '', date = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/user-track-list?user_id=${userId}&date=${date}&dealer_id=${dealerId}&type=0`;
      },

      userYingYanTrackList(condition) {
        const { dealerId = '', userId = '', date = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/user-yingyan-track-list?user_id=${userId}&date=${date}&dealer_id=${dealerId}`;
      },

      companyList(condition) {
        const { dealerId = '', cityId = '', key = '', countryId = '', status = '-10', currentPayLevel = '0', page = 1 } = condition;
        return `${API_ADMIN_DEALER}/dealer/company-list?dealer_id=${dealerId}&city_id=${cityId}&key=${key}&country_id=${countryId}&status=${status}&current_pay_level=${currentPayLevel}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    summary: {
      brandCompanyIntention(dealerId) {
        return `${API_ADMIN_DEALER}/statistic/brand-company-intention-summary?dealer_id=${dealerId}`;
      },

      brandCompanyIntentionUser(dealerId) {
        return `${API_ADMIN_DEALER}/statistic/brand-company-intention-user-summary?dealer_id=${dealerId}`;
      },

      companyOrder(condition) {
        const { month, page = 1, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/company-order-summary?dealer_id=${dealerId}&month=${month}&skip=${(page -
          1) * apiAdmin.config.halfLimit}&limit=${apiAdmin.config.halfLimit}`;
      },

      companyVisit(condition) {
        const { month, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/intention-company-visit-summary?dealer_id=${dealerId}&month=${month}`;
      },

      dealCompanyVisit(condition) {
        const { month, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/deal-company-visit-summary?dealer_id=${dealerId}&month=${month}`;
      },

      dealOrder(condition) {
        const { month, dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/deal-order-summary?dealer_id=${dealerId}&month=${month}`;
      },

      dealerReportList(condition) {
        const { page = 1, startDate = '', endDate = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/part-dealer-report-days?dealer_id=${dealerId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
          1) * apiAdmin.config.halfLimit}&limit=${apiAdmin.config.halfLimit}`;
      },

      dealerReportAll(condition) {
        const { startDate = '', endDate = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/part-dealer-report-days?dealer_id=${dealerId}&start_date=${startDate}&end_date=${endDate}&skip=0&limit=-1`;
      },

      dealerUserReport(condition) {
        const { date = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/statistic/part-dealer-user-report?dealer_id=${dealerId}&date=${date}`;
      },
    },

    log: {
      list(condition) {
        const { page = 1, dealerUserId = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/use-log-list?user_id=${dealerUserId}&dealer_id=${dealerId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    push: {
      list(condition) {
        const { page = 1, key = '', status = '', fromType = '0', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/push/push-plan-list?key=${key}&status=${status}&from_type=${fromType}&dealer_id=${dealerId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    visit: {
      list(condition) {
        const { page = 1, dealerUserId = '', dealerId = '', companyId = '', visitTimeStart = '', visitTimeEnd = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/visit-company-log-list?dealer_user_id=${dealerUserId}&dealer_id=${dealerId}&company_id=${companyId}&start_date=${visitTimeStart}&end_date=${visitTimeEnd}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      visitPlanList(condition) {
        const { page = 1, dealerUserId = '', companyId = '', startDate = '', endDate = '', status = '', dealerId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/visit-company-plan-list?dealer_user_id=${dealerUserId}&dealer_id=${dealerId}&company_id=${companyId}&start_date=${startDate}&end_date=${endDate}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      journals(condition) {
        const { page = 1, dealerId = '', companyId = '' } = condition;
        return `${API_ADMIN_DEALER}/dealer/dealer-journal-list?dealer_id=${dealerId}&company_id=${companyId}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      dealerJournal(id = '', companyId = '') {
        return `${API_ADMIN_DEALER}/dealer/dealer-journal-detail?journal_id=${id}&company_id=${companyId}`;
      },

      detailLog(id) {
        return `${API_ADMIN_DEALER}/dealer/visit-log-detail?log_id=${id}`;
      },
    },

    partOrder: {
      list(condition) {
        const { page, status = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/part-specific-order-list?status=${status}&skip=${(page -
          1)
        * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      orderList(condition) {
        const { page = 1, orderId = '' } = condition;
        return `${API_ADMIN_DAOTIAN}/weapp-part-dealer/quote-list?order_id=${orderId}&skip=${((page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    getAllRoles() {
      return `${API_ADMIN_DEALER}/dealer/all-department-roles`;
    },
  },

  // 4.产品运营
  operation: {
    comment: {
      list(condition) {
        const { startDate, endDate, companyId, page } = condition;
        return `${API_ADMIN_CUSTOMER}/comment/list?start_date=${startDate}&end_date=${endDate}&company_id=${companyId}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    advert: {
      list(condition) {
        return `${API_ADMIN_CUSTOMER}/advert/list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      add() {
        return `${API_ADMIN_CUSTOMER}/advert/create`;
      },
      edit() {
        return `${API_ADMIN_CUSTOMER}/advert/edit`;
      },
      offline() {
        return `${API_ADMIN_CUSTOMER}/advert/offline`;
      },
    },

    activity: {
      list(condition) {
        return `${API_ADMIN_CUSTOMER}/activity/list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      add() {
        return `${API_ADMIN_CUSTOMER}/activity/create`;
      },
      edit() {
        return `${API_ADMIN_CUSTOMER}/activity/edit`;
      },
      offline() {
        return `${API_ADMIN_CUSTOMER}/activity/offline`;
      },
    },

    headlines: {
      list(condition) {
        return `${API_ADMIN_CUSTOMER}/news/list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      add() {
        return `${API_ADMIN_CUSTOMER}/news/create`;
      },
      edit() {
        return `${API_ADMIN_CUSTOMER}/news/edit`;
      },
      offline() {
        return `${API_ADMIN_CUSTOMER}/news/offline`;
      },
    },

    news: {
      list(condition) {
        const { page = 1, status = '-2', key = '' } = condition;
        return `${API_ADMIN_CUSTOMER}/news/news-list?key=${key}&status=${status}&skip=${((page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      add() {
        return `${API_ADMIN_CUSTOMER}/news/create-news`;
      },
      edit() {
        return `${API_ADMIN_CUSTOMER}/news/edit-news`;
      },
      updateStatus() {
        return `${API_ADMIN_CUSTOMER}/news/news-status-update`;
      },

      detail(id) {
        return `${API_ADMIN_CUSTOMER}/news/news-detail?news_id=${id}`;
      },
    },

    question: {
      list(condition) {
        return `${API_ADMIN_ARTIFICER}/question/question-list?company_id=${condition.companyId}&type=${condition.type}&status=${condition.status}&skip=${((condition.page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      listOfUnbalance(condition) {
        return `${API_ADMIN_ARTIFICER}/question/unbalance-question-list?skip=${((condition.page -
          1) ||
          0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      listOfMine(condition) {
        return `${API_ADMIN_ARTIFICER}/question/my-question-list?skip=${((condition.page - 1) ||
          0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      listOfCompany(condition) {
        return `${API_ADMIN_ARTIFICER}/question/company-question-list?skip=${((condition.page -
          1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      detail(id) {
        return `${API_ADMIN_ARTIFICER}/question/question-detail?question_id=${id}`;
      },

      adoptAllAnswer() {
        return `${API_ADMIN_ARTIFICER}/question/adopt-all-dialog`;
      },

      shield() {
        return `${API_ADMIN_ARTIFICER}/question/delete-question`;
      },

      shieldDialog() {
        return `${API_ADMIN_ARTIFICER}/question/delete-question-dialog`;
      },

      dialogList(id) {
        return `${API_ADMIN_ARTIFICER}/question/question-dialog-list?question_id=${id}`;
      },
      dialogItemList(id, dialogId) {
        return `${API_ADMIN_ARTIFICER}/question/question-dialog-item-list?question_id=${id}&dialog_id=${dialogId}`;
      },
    },

    artificer: {
      list(condition) {
        return `${API_ADMIN_ARTIFICER}/artificer/list?skip=${((condition.page - 1) || 0) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&brand=${condition.brand}&status=${condition.status}`;
      },
      settlement() {
        return `${API_ADMIN_ARTIFICER}/artificer/withdraw`;
      },
      detail(id) {
        return `${API_ADMIN_ARTIFICER}/artificer/detail?artificer_id=${id}`;
      },
      auditLogList(id) {
        return `${API_ADMIN_ARTIFICER}/artificer/audit-log-list?artificer_id=${id}`;
      },
      withDrawList(id, page) {
        return `${API_ADMIN_ARTIFICER}/artificer/withdraw-list?artificer_id=${id}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      auditExamine(id) {
        return `${API_ADMIN_ARTIFICER}/artificer/audit?artificer_id=${id}`;
      },
      editAlipayAccount(id) {
        return `${API_ADMIN_ARTIFICER}/artificer/edit-alipay-account?artificer_id=${id}`;
      },
      editArtificerName(id) {
        return `${API_ADMIN_ARTIFICER}/artificer/edit-name?artificer_id=${id}`;
      },
      getArtificerChargeList(condition) {
        return `${API_ADMIN_ARTIFICER}/artificer/charge-list?artificer_id=${condition.customerId}&skip=${((condition.page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
    },

    branch: {
      // 广告位
      advert: {
        list(condition) {
          return `${API_ADMIN_YOUCHE}/banner/list?skip=${((condition.page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_YOUCHE}/banner/create`;
        },
        edit() {
          return `${API_ADMIN_YOUCHE}/banner/edit`;
        },
        offline() {
          return `${API_ADMIN_YOUCHE}/banner/offline`;
        },
      },

      // 知识库
      article: {
        list(condition) {
          return `${API_ADMIN_YOUCHE}/article/list?skip=${((condition.page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_YOUCHE}/article/create`;
        },
        edit() {
          return `${API_ADMIN_YOUCHE}/article/edit`;
        },
        offline() {
          return `${API_ADMIN_YOUCHE}/article/offline`;
        },
      },

      material: {
        list(condition) {
          const { page = 1, key = '', status = '-2', type = '' } = condition;
          return `${API_ADMIN_SYSTEM}/branch-material/list?key=${key}&status=${status}&type=${type}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        logList(condition) {
          const { page = 1, branchId = '', type = '0', userId = '', materialId = '' } = condition;
          return `${API_ADMIN_SYSTEM}/branch-material/use-log-list?user_id=${userId}&branch_id=${branchId}&type=${type}&material_id=${materialId}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        create() {
          return `${API_ADMIN_SYSTEM}/branch-material/create`;
        },

        edit() {
          return `${API_ADMIN_SYSTEM}/branch-material/edit`;
        },

        detail(id) {
          return `${API_ADMIN_SYSTEM}/branch-material/detail?material_id=${id}`;
        },

        updateStatus() {
          return `${API_ADMIN_SYSTEM}/branch-material/status-update`;
        },

        shareLogList(condition) {
          const { page = 1, branchId = '', userId = '', status = '-20' } = condition;
          return `${API_ADMIN_SYSTEM}/branch-material/share-log-list?user_id=${userId}&status=${status}&branch_id=${branchId}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        updateShareLogStatus() {
          return `${API_ADMIN_SYSTEM}/branch-material/update-share-log-status`;
        },
      },

      shuidaoCourse: {
        typeList() {
          return `${API_ADMIN_BRANCH}/course/type-list`;
        },
        list(condition) {
          const { page = 1, type = '', status = '-2', key = '' } = condition;
          return `${API_ADMIN_BRANCH}/course/list?key=${key}&type=${type}&status=${status}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_BRANCH}/course/create`;
        },
        edit() {
          return `${API_ADMIN_BRANCH}/course/edit`;
        },
        updateStatus() {
          return `${API_ADMIN_BRANCH}/course/update-status`;
        },
        logList(condition) {
          const { page = 1, type = '', courseId = '', userId = '', branchId = '' } = condition;
          return `${API_ADMIN_BRANCH}/course/log-list?type=${type}&branch_user_id=${userId}&branch_id=${branchId}&course_id=${courseId}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },
    },

    daotian: {
      tutorial: {
        list(condition) {
          const { page, type } = condition;
          return `${API_ADMIN_DAOTIAN}/repair-tutorial/list?type=${type}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_DAOTIAN}/repair-tutorial/create`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/repair-tutorial/edit`;
        },
        offline() {
          return `${API_ADMIN_DAOTIAN}/repair-tutorial/offline`;
        },
      },

      shuidaoCourse: {
        list(condition) {
          const { page, type, status } = condition;
          return `${API_ADMIN_DAOTIAN}/repair-course/list?type=${type}&status=${status}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_DAOTIAN}/repair-course/create`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/repair-course/edit`;
        },

        updateStatus() {
          return `${API_ADMIN_DAOTIAN}/repair-course/update-status`;
        },

        notPublicCompanyList(condition) {
          const { page = 1, key = '', courseId = '', currentPayLevel = '0' } = condition;
          return `${API_ADMIN_DAOTIAN}/repair-course/not-public-company-list?key=${key}&current_pay_level=${currentPayLevel}&course_id=${courseId}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        addPublicCompany() {
          return `${API_ADMIN_DAOTIAN}/repair-course/add-public-company`;
        },

        publicCompanyList(condition) {
          const { page = 1, courseId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/repair-course/public-company-list?course_id=${courseId}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        removePublicCompany() {
          return `${API_ADMIN_DAOTIAN}/repair-course/remove-public-company`;
        },
      },

      miPcs: {
        list(condition) {
          const { cid, page, status, key, categoryId1, categoryId2, categoryId3, isCoupon, orderByPrice = '0', orderByCommissionPercent = '0', orderByCommissionAmount = '0' } = condition;
          return `${API_ADMIN_SYSTEM}/mi-cps/product-list?cid=${cid}&key=${key}&status=${status}&order_by_price=${orderByPrice}&order_by_commission_percent=${orderByCommissionPercent}&order_by_commission_amount=${orderByCommissionAmount}&category_id1=${categoryId1}&category_id2=${categoryId2}&category_id3=${categoryId3}&is_coupon=${isCoupon}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        categoryList() {
          return `${API_ADMIN_SYSTEM}/mi-cps/category-list`;
        },

        sourceProductList(condition) {
          const { cid, page, limit } = condition;
          return `${API_ADMIN_SYSTEM}/mi-cps/source-product-list?cid=${cid}&skip=${((page - 1) || 0) *
          (limit || apiAdmin.config.limit)}&limit=${(limit || apiAdmin.config.limit)}`;
        },

        addProduct() {
          return `${API_ADMIN_SYSTEM}/mi-cps/add-product`;
        },

        updateAmount() {
          return `${API_ADMIN_SYSTEM}/mi-cps/update-commission-amount`;
        },

        updateStatus() {
          return `${API_ADMIN_SYSTEM}/mi-cps/update-status`;
        },

        category1List() {
          return `${API_ADMIN_SYSTEM}/mi-cps/category1-list`;
        },

        category2List(id) {
          return `${API_ADMIN_SYSTEM}/mi-cps/category2-list?category_id1=${id}`;
        },

        category3List(id) {
          return `${API_ADMIN_SYSTEM}/mi-cps/category3-list?category_id2=${id}`;
        },

        orderList(condition) {
          const { status, page, userId, companyId } = condition;
          return `${API_ADMIN_SYSTEM}/mi-cps/order-list?company_id=${companyId}&user_id=${userId}&status=${status}&skip=${((page - 1) || 0) *
          (apiAdmin.config.limit)}&limit=${apiAdmin.config.limit}`;
        },

        orderDetail(orderId) {
          return `${API_ADMIN_SYSTEM}/mi-cps/order-detail?order_id=${orderId}`;
        },
      },

      news: {
        sources(condition) {
          const { page = 1, key = '' } = condition;
          return `${API_ADMIN_SYSTEM}/news/source-list?key=${key}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        list(condition) {
          const { page = 1, key = '' } = condition;
          return `${API_ADMIN_SYSTEM}/news/list?key=${key}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        batchAdd() {
          return `${API_ADMIN_SYSTEM}/news/batch-create`;
        },
        offline() {
          return `${API_ADMIN_SYSTEM}/news/offline`;
        },
      },

      material: {
        images: {
          list(condition) {
            const { page = 1, key = '', type = '', status = '-10' } = condition;
            return `${API_ADMIN_SYSTEM}/material/image-list?type=${type}&key=${key}&status=${status}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
          add() {
            return `${API_ADMIN_SYSTEM}/material/create-image`;
          },
          updateStatus() {
            return `${API_ADMIN_SYSTEM}/material/image-status-update`;
          },
          genCompanyImg() {
            return `${API_ADMIN_SYSTEM}/material/create-company-image`;
          },
          companyImages(condition) {
            const { page = 1, imageId = '', companyId = '', type = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/company-image-list?company_id=${companyId}&type=${type}&image_id=${imageId}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          deleteImage() {
            return `${API_ADMIN_SYSTEM}/material/delete-company-image`;
          },

          notCreateImageCompanyList(condition) {
            const { page = 1, imageId = '', key = '', currentPayLevel = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/not-create-image-company-list?image_id=${imageId}&current_pay_level=${currentPayLevel}&key=${key}&skip=${(page -
              1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          push() {
            return `${API_ADMIN_SYSTEM}/material/push-company-image`;
          },
        },

        video: {
          list(condition) {
            const { page = 1, key = '', type = '', status = '-10' } = condition;
            return `${API_ADMIN_SYSTEM}/material/video-list?type=${type}&key=${key}&status=${status}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
          logList(condition) {
            const { page = 1, companyId = '', videoId = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/company-video-view-list?company_id=${companyId}&video_id=${videoId}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
          add() {
            return `${API_ADMIN_SYSTEM}/material/create-video`;
          },

          editVideo() {
            return `${API_ADMIN_SYSTEM}/material/edit-video`;
          },

          edit() {
            return `${API_ADMIN_SYSTEM}/material/edit-video-remark`;
          },
          updateStatus() {
            return `${API_ADMIN_SYSTEM}/material/video-status-update`;
          },
          genCompanyVideo() {
            return `${API_ADMIN_SYSTEM}/material/create-company-video`;
          },
          companyVideos(condition) {
            const { page = 1, videoId = '', companyId = '', type = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/company-video-list?company_id=${companyId}&type=${type}&video_id=${videoId}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          deleteVideo() {
            return `${API_ADMIN_SYSTEM}/material/delete-company-video`;
          },

          notCreateVideoCompanyList(condition) {
            const { page = 1, videoId = '', key = '', currentPayLevel = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/not-create-video-company-list?video_id=${videoId}&current_pay_level=${currentPayLevel}&key=${key}&skip=${(page -
              1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          push() {
            return `${API_ADMIN_SYSTEM}/material/push-company-video`;
          },
        },

        news: {
          list(condition) {
            const { page = 1, key = '', status = '-10' } = condition;
            return `${API_ADMIN_SYSTEM}/material/news-list?key=${key}&status=${status}&skip=${((page -
              1) || 0) *
            apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
          add() {
            return `${API_ADMIN_SYSTEM}/material/create-news`;
          },
          edit() {
            return `${API_ADMIN_SYSTEM}/material/edit-news`;
          },
          updateStatus() {
            return `${API_ADMIN_SYSTEM}/material/news-status-update`;
          },

          detail(id) {
            return `${API_ADMIN_SYSTEM}/material/news-detail?news_id=${id}`;
          },
          genCompanyNews() {
            return `${API_ADMIN_SYSTEM}/material/create-company-news`;
          },
          companyUpdateStatus() {
            return `${API_ADMIN_SYSTEM}/material/company-news-status-update`;
          },
          editCompanyNews() {
            return `${API_ADMIN_SYSTEM}/material/edit-company-news`;
          },
          companyNews(condition) {
            const { page = 1, newsId = '', companyId = '', type = '', status = '-10' } = condition;
            return `${API_ADMIN_SYSTEM}/material/company-news-list?company_id=${companyId}&type=${type}&status=${status}&news_id=${newsId}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
          companyNewsDetail(id) {
            return `${API_ADMIN_SYSTEM}/material/company-news-detail?company_news_id=${id}`;
          },

          notCreateNewsCompanyList(condition) {
            const { page = 1, newsId = '', key = '', currentPayLevel = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/not-create-news-company-list?news_id=${newsId}&current_pay_level=${currentPayLevel}&key=${key}&skip=${(page -
              1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          push() {
            return `${API_ADMIN_SYSTEM}/material/push-company-news`;
          },

          viewList(condition) {
            const { page = 1, newsId = '', companyId = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/company-news-view-list?news_id=${newsId}&company_id=${companyId}&skip=${(page -
              1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
        },

        script: {
          typeList() {
            return `${API_ADMIN_SYSTEM}/material/script-type`;
          },

          subTypeList(condition) {
            const { page = 1, status = '-10', type = '-10', limit } = condition;
            return `${API_ADMIN_SYSTEM}/material/script-sub-type?type=${type}&status=${status}&skip=${((page -
              1) || 0) *
            apiAdmin.config.limit}&limit=${limit || apiAdmin.config.limit}`;
          },

          updateSubTypeStatus() {
            return `${API_ADMIN_SYSTEM}/material/script-sub-type-status-update`;
          },

          addSubType() {
            return `${API_ADMIN_SYSTEM}/material/create-script-sub-type`;
          },

          editSubType() {
            return `${API_ADMIN_SYSTEM}/material/edit-script-sub-type`;
          },

          list(condition) {
            const { page = 1, key = '', status = '-10', type = '-10', subType = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/script-list?key=${key}&type=${type}&sub_type=${subType}&status=${status}&skip=${((page -
              1) || 0) *
            apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          add() {
            return `${API_ADMIN_SYSTEM}/material/create-script`;
          },
          edit() {
            return `${API_ADMIN_SYSTEM}/material/edit-script`;
          },

          updateStatus() {
            return `${API_ADMIN_SYSTEM}/material/script-status-update`;
          },

          detail(id) {
            return `${API_ADMIN_SYSTEM}/material/script-detail?script_id=${id}`;
          },

          push() {
            return `${API_ADMIN_SYSTEM}/material/push-company-script`;
          },

          companyScriptList(condition) {
            const { page = 1, scriptId = '', companyId = '', type = '', status = '-10' } = condition;
            return `${API_ADMIN_SYSTEM}/material/company-script-list?company_id=${companyId}&type=${type}&status=${status}&script_id=${scriptId}&skip=${((page -
              1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          companyScriptDetail(id) {
            return `${API_ADMIN_SYSTEM}/material/company-script-detail?company_script_id=${id}`;
          },

          companyScriptEliteUpdate() {
            return `${API_ADMIN_SYSTEM}/material/company-script-elite-update`;
          },

          companyScriptUseUpdate() {
            return `${API_ADMIN_SYSTEM}/material/company-script-use-update`;
          },

          notCreateScriptCompanyList(condition) {
            const { page = 1, scriptId = '', key = '', currentPayLevel = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/not-create-script-company-list?script_id=${scriptId}&current_pay_level=${currentPayLevel}&key=${key}&skip=${(page -
              1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },

          viewList(condition) {
            const { page = 1, scriptId = '', companyId = '' } = condition;
            return `${API_ADMIN_SYSTEM}/material/script-company-view-list?script_id=${scriptId}&company_id=${companyId}&skip=${(page -
              1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
          },
        },
      },

      log: {
        list(condition) {
          const { page = 1, key = '', companyId = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/user/use-log-list?phone=${key}&company_id=${companyId}&skip=${((page -
            1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      advert: {
        list(condition) {
          return `${API_ADMIN_DAOTIAN}/advert/list?skip=${((condition.page - 1) || 0) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_DAOTIAN}/advert/create`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/advert/edit`;
        },
        offline() {
          return `${API_ADMIN_DAOTIAN}/advert/offline`;
        },
      },

      push: {
        list(condition) {
          const { page, key = '', fromType = '0', status = '' } = condition;
          return `${API_ADMIN_DAOTIAN}/push/push-plan-list?key=${key}&from_type=${fromType}&status=${status}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_DAOTIAN}/push/create-push-plan`;
        },
        edit() {
          return `${API_ADMIN_DAOTIAN}/push/edit-push-plan`;
        },
        delete() {
          return `${API_ADMIN_DAOTIAN}/push/delete-push-plan`;
        },
      },

      superWallet: {
        list(condition) {
          const { page, key = '', type = '', status = '-2' } = condition;
          return `${API_ADMIN_SYSTEM}/super-wallet/income-list?keyword=${key}&type=${type}&status=${status}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        typeList() {
          return `${API_ADMIN_SYSTEM}/super-wallet/type-list`;
        },

        add() {
          return `${API_ADMIN_SYSTEM}/super-wallet/create-income`;
        },

        editRemark() {
          return `${API_ADMIN_SYSTEM}/super-wallet/edit-income-remark`;
        },

        editAmount() {
          return `${API_ADMIN_SYSTEM}/super-wallet/edit-income-amount`;
        },

        deleteIncome() {
          return `${API_ADMIN_SYSTEM}/super-wallet/delete-income`;
        },
      },
    },
  },

  // 5.数据管理
  dataCenter: {
    auto: {
      list(condition) {
        const { page, startDate, endDate, key } = condition;
        return `${API_ADMIN_SYSTEM}/auto/list?create_start_date=${startDate}&create_end_date=${endDate}&key=${key}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      detail(id) {
        return `${API_ADMIN_SYSTEM}/auto/detail?auto_id=${id}`;
      },

      insurance: {
        list(condition) {
          const { page, autoId } = condition;
          return `${API_ADMIN_SYSTEM}/auto/insurance-log-list?auto_id=${autoId}&skip=${((page -
            1) ||
            0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        detail(autoId, insuranceLogId) {
          return `${API_ADMIN_SYSTEM}/auto/insurance-log-detail?auto_id=${autoId}&insurance_log_id=${insuranceLogId}`;
        },
        companyList() {
          return `${API_ADMIN_SYSTEM}/auto/get-insurance-company-list`;
        },
        config() {
          return `${API_ADMIN_SYSTEM}/auto/get-insurance-config`;
        },
      },

      maintain: {
        list(condition) {
          const { page, autoId, startDate, endDate } = condition;
          return `${API_ADMIN_SYSTEM}/auto/maintain-intention-list?auto_id=${autoId}&start_date=${startDate}&end_date=${endDate}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        listByAutoId(condition) {
          const { page, autoId, startDate, endDate } = condition;
          return `${API_ADMIN_SYSTEM}/auto/maintain-intention-list-by-auto-id?auto_id=${autoId}&start_date=${startDate}&end_date=${endDate}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      weizhang: {
        list(condition) {
          const { page, autoId, startDate, endDate } = condition;
          return `${API_ADMIN_SYSTEM}/auto/weizhang-list?auto_id=${autoId}&start_date=${startDate}&end_date=${endDate}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        detail(autoId) {
          return `${API_ADMIN_SYSTEM}/auto/search-weizhang-result?auto_id=${autoId}`;
        },
      },

      inspection: {
        list(condition) {
          const { page, autoId } = condition;
          return `${API_ADMIN_SYSTEM}/auto/annual-inspection-log-list?auto_id=${autoId}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        detail(autoId, inspectionLogId) {
          return `${API_ADMIN_SYSTEM}/auto/annual-inspection-log-detail?inspection_log_id=${inspectionLogId}&auto_id=${autoId}`;
        },
      },
    },

    // 车主
    autoOwner: {
      list(condition) {
        const { page, isShowAuto, key } = condition;
        return `${API_ADMIN_SYSTEM}/customer/list?is_show_auto=${isShowAuto}&key=${key}&skip=${((page -
          1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      detail(id) {
        return `${API_ADMIN_SYSTEM}/customer/detail?customer_id=${id}`;
      },
      getAutos(ids) {
        return `${API_ADMIN_SYSTEM}/customer/customer-count?auto_brand_ids=${ids}`;
      },
    },

    partsSale: {
      push: {
        list(condition) {
          const { page = 1, key = '', status = '', fromType = '0', branchId } = condition;
          return `${API_ADMIN_BRANCH}/push/push-plan-list?key=${key}&status=${status}&from_type=${fromType}&branch_id=${branchId}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        add() {
          return `${API_ADMIN_BRANCH}/push/create-push-plan`;
        },
      },

      partBrand: {
        list(condition) {
          const { page, key = '' } = condition;
          return `${API_ADMIN_BRANCH}/part/part-brand-list?keyword=${key}&skip=${((page - 1) || 0) *
          apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : ''}`;
        },
        listAllOnline() {
          return `${API_ADMIN_BRANCH}/part/part-brand-list?status=1&skip=0&limit=-1`;
        },
        detail(id) {
          return `${API_ADMIN_BRANCH}/part/part-brand-detail?brand_id=${id}`;
        },
        add() {
          return `${API_ADMIN_BRANCH}/part/add-part-brand`;
        },
        edit() {
          return `${API_ADMIN_BRANCH}/part/edit-part-brand`;
        },
        // brand_id, status: -1：删除 0：正常
        updateStatus() {
          return `${API_ADMIN_BRANCH}/part/update-part-brand-status`;
        },
      },

      event: {
        list(condition) {
          const { status = '', page, branchId = '', limit } = condition;
          return `${API_ADMIN_BRANCH}/event/list?status=${status}&branch_id=${branchId}&skip=${((page -
            1) || 0) * (limit || apiAdmin.config.limit)}&limit=${(limit || apiAdmin.config.limit)}`;
        },

        create() {
          return `${API_ADMIN_BRANCH}/event/create`;
        },

        edit() {
          return `${API_ADMIN_BRANCH}/event/edit`;
        },

        detail(eventId) {
          return `${API_ADMIN_BRANCH}/event/detail?event_id=${eventId}`;
        },

        attendList(condition) {
          const { eventId, page, limit } = condition;
          return `${API_ADMIN_BRANCH}/event/attend-list?event_id=${eventId}&skip=${((page -
            1) || 0) * (limit || apiAdmin.config.limit)}&limit=${(limit || apiAdmin.config.limit)}`;
        },

        getQRCode(id) {
          return `${API_ADMIN_BRANCH}/event/get-qrcode-pic-data?event_id=${id}`;
        },

        export(eventId) {
          return `${API_ADMIN_BRANCH}/event/export-attend-list?event_id=${eventId}&rtick=${new Date().getTime()}`;
        },

        clueList(condition) {
          const { eventId, page = 1 } = condition;
          return `${API_ADMIN_BRANCH}/event/clue-list?event_id=${eventId}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        exportClue(id) {
          return `${API_ADMIN_BRANCH}/event/export-clue-list?event_id=${id}&rtick=${new Date().getTime()}`;
        },
      },

      brandIntention: {
        list(condition) {
          const { page = 1, branchId = '', brandId = '', partType = '', level = '', process = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-product/company-brand-intention-list?branch_id=${branchId}&brand_id=${brandId}&part_type=${partType}&level=${level}&process=${process}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        export(condition) {
          const { branchId = '', brandId = '', partType = '', level = '', process = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-product/export-company-brand-intention?branch_id=${branchId}&brand_id=${brandId}&part_type=${partType}&level=${level}&process=${process}&rtick=${new Date().getTime()}`;
        },
      },

      part: {
        list(condition) {
          const { page, key, partType, brand, scope, status, brandId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part/part-list?keyword=${key}&part_type=${partType}&brand=${brand}&brand_id=${brandId}&scope=${scope}&status=${status}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${page ? apiAdmin.config.limit : ''}`;
        },
        detail(id) {
          return `${API_ADMIN_BRANCH}/part/part-detail?part_id=${id}`;
        },
        add() {
          return `${API_ADMIN_BRANCH}/part/add-part`;
        },
        edit() {
          return `${API_ADMIN_BRANCH}/part/edit-part`;
        },
        updateStatus() {
          return `${API_ADMIN_BRANCH}/part/update-part-status`;
        },
        types() {
          return `${API_ADMIN_BRANCH}/part/all-part-types`;
        },
      },

      goods: {
        list(condition) {
          const { page, key, branchId = '', status = '-2' } = condition;
          return `${API_ADMIN_BRANCH}/part-product/list?keyword=${key}&status=${status}&branch_id=${branchId}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        detail(id) {
          return `${API_ADMIN_BRANCH}/part-product/detail?product_id=${id}`;
        },

        itemList(condition) {
          const { productId = '', status = '', key = '', page = 1, limit, branchId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-product/item-list?product_id=${productId}&branch_id=${branchId}&keyword=${key}&status=${status}&skip=${(page -
            1) * (limit || apiAdmin.config.limit)}&limit=${limit || apiAdmin.config.limit}`;
        },

        itemDetail(id, companyId = '') {
          return `${API_ADMIN_BRANCH}/part-product/item-detail?product_item_id=${id}&company_id=${companyId}`;
        },
      },

      activity: {
        list(condition) {
          const { page, key, branchId = '', status = '-2' } = condition;
          return `${API_ADMIN_BRANCH}/part-activity/list?keyword=${key}&branch_id=${branchId}&status=${status}&skip=${(page -
            1) *
          apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        getAll() {
          return `${API_ADMIN_BRANCH}/part-activity/list?keyword=&skip=0&limit=-1`;
        },
        detail(id) {
          return `${API_ADMIN_BRANCH}/part-activity/detail?activity_id=${id}`;
        },
        companyAttendRecord(condition) {
          const { page = 1, id = '', companyId = '', branchId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-activity/attend-list?activity_id=${id}&branch_id=${branchId}&company_id=${companyId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      brandActivity: {
        list(condition) {
          const { page, key, branchId = '', status = '-2' } = condition;
          return `${API_ADMIN_BRANCH}/part/brand-activity-list?keyword=${key}&status=${status}&branch_id=${branchId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        getAll() {
          return `${API_ADMIN_BRANCH}/part/brand-activity-list?keyword=&skip=0&limit=-1`;
        },
        detail(id) {
          return `${API_ADMIN_BRANCH}/part/brand-activity-detail?activity_id=${id}`;
        },
        attendLogs(condition) {
          const { page = 1, activityId = '', companyId = '', status = '-2' } = condition;
          return `${API_ADMIN_BRANCH}/part/brand-activity-log-list?activity_id=${activityId}&status=${status}&company_id=${companyId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      order: {
        list(condition) {
          const { page, status, payChannel = '', branchId = '', companyId = '', payStatus, createTimeStart = '', createTimeEnd = '', authTimeStart = '', authTimeEnd = '', orderId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/list?&branch_id=${branchId}&company_id=${companyId}&ctime_start=${createTimeStart}&ctime_end=${createTimeEnd}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&order_id=${orderId}&status=${status}&pay_status=${payStatus}&pay_channel=${payChannel}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        detail(id) {
          return `${API_ADMIN_BRANCH}/part-order/detail?order_id=${id}`;
        },

        itemList(id) {
          return `${API_ADMIN_BRANCH}/part-order/item-list?order_id=${id}`;
        },

        productIncomeList(id) {
          return `${API_ADMIN_BRANCH}/part-order/product-income-item-list-by-order?order_id=${id}`;
        },

        outputList(id) {
          return `${API_ADMIN_BRANCH}/part-order/output-list?order_id=${id}`;
        },

        returnList(condition) {
          const { orderId = '', type, status = '-2', page, branchId = '', companyId = '', userId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/return-list?order_id=${orderId}&type=${type}&supplier_id=${branchId}&company_id=${companyId}&supplier_user_id=${userId}&status=${status}&skip=${(page ? (page -
            1) * apiAdmin.config.limit : 0)}&limit=${page ? apiAdmin.config.limit : -1}`;
        },

        returnItemList(condition) {
          const { returnId } = condition;
          return `${API_ADMIN_BRANCH}/part-order/return-item-list?return_id=${returnId}`;
        },

        cutList(condition) {
          const { orderId = '', status = '-2', page, branchId = '', companyId = '', userId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/cut-list?order_id=${orderId}&supplier_id=${branchId}&company_id=${companyId}&supplier_user_id=${userId}&status=${status}&skip=${(page ? (page -
            1) * apiAdmin.config.limit : 0)}&limit=${page ? apiAdmin.config.limit : -1}`;
        },

        cutItemList(condition) {
          const { cutId } = condition;
          return `${API_ADMIN_BRANCH}/part-order/cut-item-list?cut_id=${cutId}`;
        },

        orderRefund() {
          return `${API_ADMIN_BRANCH}/part-order/order-online-pay-refund`;
        },
      },
    },

    finance: {
      transfer: {
        list(condition) {
          const { page = 1, ptimeStart = '', ptimeEnd = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/pay-summary-list-by-branch?ptime_start=${ptimeStart}&ptime_end=${ptimeEnd}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        transferToBranch() {
          return `${API_ADMIN_BRANCH}/part-order/transfer-to-branch`;
        },
        payments(condition, getAll = false) {
          const {
            page = 1,
            branchId = '',
            ptimeStart = '',
            ptimeEnd = '',
            payType = '-10',
          } = condition;
          return `${API_ADMIN_BRANCH}/part-order/pay-list?branch_id=${branchId}&pay_type=${payType}&ptime_start=${ptimeStart}&ptime_end=${ptimeEnd}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${getAll ? '-1' : apiAdmin.config.limit}`;
        },
        exportPayments(condition) {
          const {
            ptimeStart = '',
            ptimeEnd = '',
            payType = '-10',
          } = condition;
          return `${API_ADMIN_BRANCH}/part-order/export-product-income-list?ptime_start=${ptimeStart}&pay_type=${payType}&ptime_end=${ptimeEnd}&rtick=${new Date().getTime()}`;
        },
        logs(condition) {
          const { page, branchId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/transfer-list?branch_id=${branchId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      onAccount: {
        list(condition) {
          const { page = 1, ctimeStart = '', ctimeEnd = '', companyId = '', supplierId = '', supplierUserId = '', authTimeStart = '', authTimeEnd = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/on-account-order-list?ctime_start=${ctimeStart}&ctime_end=${ctimeEnd}&company_id=${companyId}&supplier_id=${supplierId}&supplier_user_id=${supplierUserId}&auth_time_start=${authTimeStart}&auth_time_end=${authTimeEnd}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        companyList(condition) {
          const { page = 1, companyId = '', supplierId = '', supplierUserId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/on-account-company-list?company_id=${companyId}&supplier_id=${supplierId}&supplier_user_id=${supplierUserId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      overdueFine: {
        list(condition) {
          const { page, branchId = '', ctimeStart = '', ctimeEnd = '', branchUserId = '' } = condition;
          return `${API_ADMIN_BRANCH}/part-order/overdue-fine-list?branch_id=${branchId}&branch_user_id=${branchUserId}&ctime_start=${ctimeStart}&ctime_end=${ctimeEnd}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },

      marketWallet: {
        chargeList(condition) {
          const { page = 1, branchId = '', startDate = '', endDate = '' } = condition;
          return `${API_ADMIN_BRANCH}/market-wallet/charge-list?branch_id=${branchId}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        charge() {
          return `${API_ADMIN_BRANCH}/market-wallet/charge`;
        },

        outgoingList(condition) {
          const { page, branchId = '', startDate = '', endDate = '', type = '' } = condition;
          return `${API_ADMIN_BRANCH}/market-wallet/outgoing-list?branch_id=${branchId}&type=${type}&start_date=${startDate}&end_date=${endDate}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },

        consume() {
          return `${API_ADMIN_BRANCH}/market-wallet/consume`;
        },
      },
    },

    sms: {
      createTemplate() {
        return `${API_ADMIN_SYSTEM}/sms/create-template`;
      },
      editTemplate() {
        return `${API_ADMIN_SYSTEM}/sms/edit-template`;
      },
      detail(id) {
        return `${API_ADMIN_SYSTEM}/sms/template-detail?template_id=${id}`;
      },
      branchDetail(id) {
        return `${API_ADMIN_SYSTEM}/sms/branch-template-detail?template_id=${id}`;
      },
      dealerDetail(id) {
        return `${API_ADMIN_SYSTEM}/sms/part-dealer-template-detail?template_id=${id}`;
      },

      companyTemplateList(condition) {
        const { page = 1, startDate, endDate, type, status, companyId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/company-template-list?create_start_date=${startDate}&create_end_date=${endDate}&company_id=${companyId}&type=${type}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      sendPlanList(condition) {
        const { page = 1, templateId = '', status = '-10', companyId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/send-plan-list?template_id=${templateId}&company_id=${companyId}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      branchTemplateList(condition) {
        const { page = 1, startDate, endDate, type, status, branchId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/branch-template-list?create_start_date=${startDate}&create_end_date=${endDate}&branch_id=${branchId}&type=${type}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      dealerTemplateList(condition) {
        const { page = 1, startDate, endDate, type, status, dealerId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/part-dealer-template-list?create_start_date=${startDate}&create_end_date=${endDate}&branch_id=${dealerId}&type=${type}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      getSendTemplates(page, type) {
        return `${API_ADMIN_SYSTEM}/sms/company-template-list?type=${type}&create_start_date=&create_end_date=&status=1&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      companyConsumeList(condition) {
        const { key = '', startDate, endDate, status, subType, page = 1, companyId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/company-consume-list?phone=${key}&start_date=${startDate}&end_date=${endDate}&status=${status}&company_id=${companyId}&sub_type=${subType}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      companyConsumeTypeList() {
        return `${API_ADMIN_SYSTEM}/sms/company-consume-type-list`;
      },
      branchConsumeList(condition) {
        const { key, startDate, endDate, status, subType, page = 1, branchId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/branch-consume-list?phone=${key}&start_date=${startDate}&end_date=${endDate}&status=${status}&branch_id=${branchId}&sub_type=${subType}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      branchConsumeTypeList() {
        return `${API_ADMIN_SYSTEM}/sms/branch-consume-type-list`;
      },

      dealerConsumeList(condition) {
        const { key, startDate, endDate, status, subType, page = 1, dealerId = '' } = condition;
        return `${API_ADMIN_SYSTEM}/sms/part-dealer-consume-list?phone=${key}&start_date=${startDate}&end_date=${endDate}&status=${status}&dealer_id=${dealerId}&sub_type=${subType}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      dealerConsumeTypeList() {
        return `${API_ADMIN_SYSTEM}/sms/part-dealer-consume-type-list`;
      },

      consumeVoiceList(condition) {
        const { page = 1, startDate, endDate, status } = condition;
        return `${API_ADMIN_SYSTEM}/sms/voice-consume-list?start_date=${startDate}&end_date=${endDate}&status=${status}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      send() {
        return `${API_ADMIN_SYSTEM}/sms/send-one-sms`;
      },
      sendBatch() {
        return `${API_ADMIN_SYSTEM}/sms/send-batch-sms`;
      },

      charge: {
        repairShopList(condition) {
          const { page = 1, startDate, endDate, companyId = '' } = condition;
          return `${API_ADMIN_SYSTEM}/sms/company-charge-list?start_date=${startDate}&end_date=${endDate}&company_id=${companyId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        branchList(condition) {
          const { page = 1, startDate, endDate, branchId = '' } = condition;
          return `${API_ADMIN_SYSTEM}/sms/branch-charge-list?start_date=${startDate}&end_date=${endDate}&branch_id=${branchId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        dealerList(condition) {
          const { page = 1, startDate, endDate, dealerId = '' } = condition;
          return `${API_ADMIN_SYSTEM}/sms/part-dealer-charge-list?start_date=${startDate}&end_date=${endDate}&dealer_id=${dealerId}&skip=${(page -
            1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
      },
    },

    // 提醒任务
    remind: {
      maintain: {
        list(condition) {
          const { page, isSms, between } = condition;
          return `${API_ADMIN_SYSTEM}/task/maintain-list?is_sms=${isSms}&between=${between}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        send() {
          return `${API_ADMIN_SYSTEM}/task/remind-maintain`;
        },
      },

      insurance: {
        list(condition) {
          const { page, isSms, between } = condition;
          return `${API_ADMIN_SYSTEM}/task/insurance-list?is_sms=${isSms}&between=${between}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        send() {
          return `${API_ADMIN_SYSTEM}/task/remind-insurance`;
        },
      },

      yearlyCheck: {
        list(condition) {
          const { page, isSms, between } = condition;
          return `${API_ADMIN_SYSTEM}/task/inspection-list?is_sms=${isSms}&between=${between}&skip=${((page -
            1) || 0) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
        },
        send() {
          return `${API_ADMIN_SYSTEM}/task/remind-inspection`;
        },
      },
    },
  },

  // 6.设置
  settings: {
    // 系统功能管理
    authority: {
      list(id) {
        return `${API_ADMIN_SYSTEM}/auth/item-list?parent_id=${id}`;
      },
      add() {
        return `${API_ADMIN_SYSTEM}/auth/create-item`;
      },
      edit() {
        return `${API_ADMIN_SYSTEM}/auth/edit-item`;
      },
      delete() {
        return `${API_ADMIN_SYSTEM}/auth/delete-item`;
      },

      dealer: {
        list(id) {
          return `${API_ADMIN_SYSTEM}/dealer-auth/item-list?parent_id=${id}`;
        },
        add() {
          return `${API_ADMIN_SYSTEM}/dealer-auth/create-item`;
        },
        edit() {
          return `${API_ADMIN_SYSTEM}/dealer-auth/edit-item`;
        },
        delete() {
          return `${API_ADMIN_SYSTEM}/dealer-auth/delete-item`;
        },

        getByRole(roleId) {
          return `${API_ADMIN_SYSTEM}/dealer-auth/role-item-list?role=${roleId}`;
        },

        updateByRole() {
          return `${API_ADMIN_SYSTEM}/dealer-auth/update-role-item`;
        },
      },

      branch: {
        list(id) {
          return `${API_ADMIN_SYSTEM}/branch-auth/item-list?parent_id=${id}`;
        },
        add() {
          return `${API_ADMIN_SYSTEM}/branch-auth/create-item`;
        },
        edit() {
          return `${API_ADMIN_SYSTEM}/branch-auth/edit-item`;
        },
        delete() {
          return `${API_ADMIN_SYSTEM}/branch-auth/delete-item`;
        },

        getByRole(roleId) {
          return `${API_ADMIN_SYSTEM}/branch-auth/role-item-list?role=${roleId}`;
        },

        updateByRole() {
          return `${API_ADMIN_SYSTEM}/branch-auth/update-role-item`;
        },

        getAllRoles() {
          return `${API_ADMIN_BRANCH}/branch/all-department-roles`;
        },
      },
    },

    // 系统版本功能配置
    permission: {
      list(parentId) {
        return `${API_ADMIN_SYSTEM}/auth/item-list?parent_id=${parentId}`;
      },
      add() {
        return `${API_ADMIN_SYSTEM}/auth/create-item`;
      },
      edit() {
        return `${API_ADMIN_SYSTEM}/auth/edit-item`;
      },
      delete() {
        return `${API_ADMIN_SYSTEM}/auth/delete-item`;
      },
      updateByRole() {
        return `${API_ADMIN_SYSTEM}/auth/update-role-item`;
      },
      getByRole(roleId) {
        return `${API_ADMIN_SYSTEM}/auth/role-item-list?role=${roleId}`;
      },

      updateBySystem() {
        return `${API_ADMIN_SYSTEM}/auth/update-system-item`;
      },
      getBySystem(systemType) {
        return `${API_ADMIN_SYSTEM}/auth/system-item-list?system_type=${systemType}`;
      },
    },

    // 账号管理
    account: {
      list(condition) {
        const { page = 1, key = '', userType = '', status = '-2' } = condition;
        return `${API_ADMIN_SYSTEM}/super-user/list?keyword=${key}&status=${status}&user_type=${userType}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      add() {
        return `${API_ADMIN_SYSTEM}/super-user/create`;
      },
      edit() {
        return `${API_ADMIN_SYSTEM}/super-user/edit`;
      },
      detail(id) {
        return `${API_ADMIN_SYSTEM}/super-user/detail?_id=${id}`;
      },
      modifyStatus() {
        return `${API_ADMIN_SYSTEM}/super-user/update-login-status`;
      },
      userList(key = '', companyId = '') {
        return `${API_ADMIN_SYSTEM}/user/list?keyword=${key}&company_id=${companyId}&skip=&limit=-1`;
      },
    },

    // 团队管理
    team: {
      list(condition) {
        const { page = 1, key = '', status = '0' } = condition;
        return `${API_ADMIN_BRANCH}/branch/list?keyword=${key}&status=${status}&skip=${(page - 1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },
      getAll() {
        return `${API_ADMIN_BRANCH}/branch/list?skip=0&limit=-1&status=0`;
      },
      add() {
        return `${API_ADMIN_BRANCH}/branch/create`;
      },
      edit() {
        return `${API_ADMIN_BRANCH}/branch/edit`;
      },
      detail(id) {
        return `${API_ADMIN_BRANCH}/branch/detail?_id=${id}`;
      },

      updateStatus() {
        return `${API_ADMIN_BRANCH}/branch/update-status`;
      },

      userList(condition) {
        const { key = '', branchId = '', page = 1, status = '-10' } = condition;
        return `${API_ADMIN_BRANCH}/branch/user-list?keyword=${key}&status=${status}&branch_id=${branchId}&skip=${(page -
          1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      fire() {
        return `${API_ADMIN_BRANCH}/branch/fire`;
      },

      getAllUsers(condition) {
        const { key = '', branchId = '', status = '-10' } = condition;
        return `${API_ADMIN_BRANCH}/branch/user-list?keyword=${key}&branch_id=${branchId}&skip=0&limit=-1&status=${status}`;
      },

      userTrackList(condition) {
        const { date = '', branchId = '', userId = '' } = condition;
        return `${API_ADMIN_BRANCH}/branch/user-track-list?user_id=${userId}&branch_id=${branchId}&date=${date}&type=0`;
      },

      userYingYanTrackList(condition) {
        const { branchId = '', userId = '', date = '' } = condition;
        return `${API_ADMIN_BRANCH}/branch/user-yingyan-track-list?user_id=${userId}&date=${date}&branch_id=${branchId}`;
      },

      companyList(condition) {
        const { branchId = '', cityId = '', key = '', userId = '', countryId = '', status = '-10', currentPayLevel = '0', page = 1 } = condition;
        return `${API_ADMIN_BRANCH}/branch/maintain-company-list?branch_id=${branchId}&city_id=${cityId}&key=${key}&branch_user_id=${userId}&country_id=${countryId}&status=${status}&current_pay_level=${currentPayLevel}&skip=${(page -
          1) * apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
      },

      allCompanyList(branchId = '', userId = '') {
        return `${API_ADMIN_BRANCH}/branch/maintain-company-list?branch_id=${branchId}&city_id=&key=&branch_user_id=${userId}&country_id&status=-10&current_pay_level=&skip=$0&limit=-1`;
      },

      resetPartDevice() {
        return `${API_ADMIN_BRANCH}/branch/reset-part-device`;
      },
    },

    // 车型
    autoModels: {
      list(condition) {
        return `${API_ADMIN_SYSTEM}/auto/auto-type-list?skip=${(condition.page - 1) *
        apiAdmin.config.limit}&limit=${apiAdmin.config.limit}&auto_brand_id=${condition.autoBrandId}&auto_series_id=${condition.autoSeriesId}&auto_type_id=${condition.autoTypeId}&guide_price=${condition.guidePrice}&is_private=${condition.isCustomeAuto}`;
      },
      create() {
        return `${API_ADMIN_SYSTEM}/auto/create-auto-type`;
      },
      edit() {
        return `${API_ADMIN_SYSTEM}/auto/edit-auto-type`;
      },
      autoTypeDetail(autoTypeId) {
        return `${API_ADMIN_SYSTEM}/auto/auto-type-detail?auto_type_id=${autoTypeId}`;
      },
    },

    superUser: {
      departmentRoles() {
        return `${API_ADMIN_SYSTEM}/super-user/department-roles`
      },
    },
  },

  // 公司, 产品运营中，问题和评论中使用
  company: {
    list(page) {
      return `${API_ADMIN_DAOTIAN}/company/maintain-company-list?skip=${((Number(page) - 1) || 0) *
      apiAdmin.config.limit}&limit=${apiAdmin.config.limit}`;
    },
    getAll() {
      return `${API_ADMIN_DAOTIAN}/company/maintain-company-list?skip=0&limit=-1`;
    },
    keyList(key) {
      return `${API_ADMIN_DAOTIAN}/company/maintain-company-list?skip=0&limit=20&key=${key}`;
    },
    add() {
      return `${API_ADMIN_DAOTIAN}/company/create`;
    },
    edit() {
      return `${API_ADMIN_DAOTIAN}/company/edit`;
    },
  },

  // 数据统计
  statistic: {
    summary: {
      total() {
        return `${API_ADMIN_STATISTIC}/summary/today-total`;
      },
      todayGrowth() {
        return `${API_ADMIN_STATISTIC}/summary/today-new`;
      },
    },

    repairShop: {
      dailySummary(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/company/summary-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyBusiness(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/company/biz-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailySystemUse(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/company/use-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyCustomer(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/company/customer-days?start_date=${startDate}&end_date=${endDate}`;
      },
    },
    team: {
      dailySummary(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/branch/summary-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyBusiness(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/branch/biz-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyVisit(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/branch/visit-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyUse(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/branch/use-days?start_date=${startDate}&end_date=${endDate}`;
      },
    },

    partTeam: {
      dailySummary(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/part-dealer/summary-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyBusiness(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/part-dealer/biz-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyVisit(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/part-dealer/visit-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyUse(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/part-dealer/use-days?start_date=${startDate}&end_date=${endDate}`;
      },
    },

    business: {
      dailyWeapp(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/biz/weapp-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyOfficialAccount(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/biz/wechat-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailyQa(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/biz/question-days?start_date=${startDate}&end_date=${endDate}`;
      },
      dailySms(startDate, endDate) {
        return `${API_ADMIN_STATISTIC}/biz/sms-days?start_date=${startDate}&end_date=${endDate}`;
      },
    },
  },
};

export default apiAdmin;
