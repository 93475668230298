import React from 'react';
import { Redirect } from 'react-router-dom';

import LazyRoute from 'lazy-route';
import AuthUtil from '../../utils/AuthUtil';

const routers = [
  {
    exact: true,
    path: '/',
    breadcrumbName: '首页/',
    render: () => (AuthUtil.isLogin()
      ? <Redirect to="/home" />
      : <Redirect to="/login" />),
  }, {
    exact: true,
    breadcrumbName: '首页',
    path: '/home',
    render: props => (
      <LazyRoute {...props} component={import('./Home')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '人事/员工管理',
    path: '/personnel',
    render: props => (
      <LazyRoute {...props} component={import('./personnel/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '智能BI系统',
    path: '/bi-system',
    render: () => (
      <Redirect to="/dashboard/bi-system/Index" />
    ),
  }, {
    exact: true,
    breadcrumbName: '门店/水稻会员店',
    path: '/shuidao-member-store',
    render: props => (
      <LazyRoute {...props} component={import('./store/shuidao-member-store/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '门店/汽修管理',
    path: '/store-shop',
    render: props => (
      <LazyRoute {...props} component={import('./store/repair-shop/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '门店/汽修管理/详情',
    path: '/store-shop/:id',
    render: props => (
      <LazyRoute {...props} component={import('./store/repair-shop/Detail')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '门店/门店地图',
    path: '/store-shop-map',
    render: props => (
      <LazyRoute {...props} component={import('./store/repair-shop/Map')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '门店/线索管理',
    path: '/store-clue',
    render: props => (
      <LazyRoute {...props} component={import('./store/clue/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/配件管理',
    path: '/parts-sale/parts',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/part/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/商品类别',
    path: '/parts-sale/goods',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/goods/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/商品管理',
    path: '/parts-sale/goods-item',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/goods-item/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/门店设置',
    path: '/parts-sale/brand-config',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/brand-config/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '水稻采购/商品管理',
    path: '/shuidao-purchase/goods',
    render: props => (
      <LazyRoute {...props} component={import('./shuidao-purchase/goods-item/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '水稻采购/订单管理',
    path: '/shuidao-purchase/order',
    render: props => (
      <LazyRoute {...props} component={import('./shuidao-purchase/order/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '水稻采购/订单管理/新增',
    path: '/shuidao-purchase/order/new',
    render: props => (
      <LazyRoute {...props} component={import('./shuidao-purchase/order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '水稻采购/订单管理/编辑',
    path: '/shuidao-purchase/order/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./shuidao-purchase/order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '水稻采购/订单管理/详情',
    path: '/shuidao-purchase/order/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./shuidao-purchase/order/DetailLink')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/仓库管理',
    path: '/inventory/warehouse',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/warehouse/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/仓库盘点',
    path: '/inventory/stocktaking',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/stocktaking/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/仓库盘点/创建',
    path: '/inventory/stocktaking/create',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/stocktaking/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/仓库盘点/编辑',
    path: '/inventory/stocktaking/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/stocktaking/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/仓库盘点/详情',
    path: '/inventory/stocktaking/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/stocktaking/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/损溢单',
    path: '/inventory/income-statement',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/income-statement/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/损溢单/创建',
    path: '/inventory/income-statement/create',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/income-statement/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/损溢单/编辑',
    path: '/inventory/income-statement/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/income-statement/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/损溢单/详情',
    path: '/inventory/income-statement/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/income-statement/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/采购管理',
    path: '/inventory/purchase',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/purchase/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/调拨管理',
    path: '/inventory/warehouse-move',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/warehouse-move/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/入库管理',
    path: '/inventory/warehouse-input',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/warehouse-input/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '库存管理/出库管理',
    path: '/inventory/warehouse-output',
    render: props => (
      <LazyRoute {...props} component={import('./inventory/warehouse-output/List')} />
    ),
  },

  // 车辆
  {
    exact: true,
    path: '/vehicle/list',
    breadcrumbName: '车队管理/列表',
    render: props => (
      <LazyRoute {...props} component={import('./vehicle/List')} />
    ),
  }, {
    exact: true,
    path: '/vehicle/detail/:id',
    breadcrumbName: '车队管理/详情',
    render: props => (
      <LazyRoute {...props} component={import('./vehicle/detail/Index')} />
    ),
  },

  {
    exact: true,
    breadcrumbName: '营销/优惠活动',
    path: '/parts-sale/activity',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/activity/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/会议管理',
    path: '/parts-sale/conference',
    render: props => (
      <LazyRoute {...props} component={import('./conference/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/活动报名',
    path: '/parts-sale/enroll',
    render: props => (
      <LazyRoute {...props} component={import('./enroll/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '活动BI系统',
    path: '/activity-bi/:id',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/activity-bi/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/活动管理/参与记录',
    path: '/parts-sale/activity-logs',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/activity-logs/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/活动管理/参与记录',
    path: '/parts-sale/activity-logs/:id',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/activity-logs/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/订单管理',
    path: '/parts-sale/order',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/order/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/订单管理/新增',
    path: '/parts-sale/order/new',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/订单管理/编辑',
    path: '/parts-sale/order/edit/:id',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/order/Edit')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '配件销售/订单管理/详情',
    path: '/parts-sale/order/detail/:id',
    render: props => (
      <LazyRoute {...props} component={import('./parts-sale/order/DetailLink')} />
    ),
  },

  // 统计
  {
    exact: true,
    breadcrumbName: '数据统计/每日报表',
    path: '/dashboard/daily-report',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/daily-report/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '数据统计/数据分析',
    path: '/dashboard/analysis',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/analysis/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '业务管理/智能BI系统',
    path: '/dashboard/bi-system',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/bi-system/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '数据统计/业务概况',
    path: '/dashboard/business',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/business/Index')} />
    ),
  },

  {
    exact: true,
    breadcrumbName: '拜访管理/数据收集',
    path: '/dashboard/journal',
    render: props => (
      <LazyRoute {...props} component={import('./journal/template/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '拜访管理/拜访记录',
    path: '/dashboard/visit',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/visit/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '拜访管理/拜访计划',
    path: '/dashboard/visit/plan',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/visit/plan/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '拜访管理/品牌意向',
    path: '/dashboard/brand-intention',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/brand-intention/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '拜访管理/拜访轨迹',
    path: '/dashboard/visit/track',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/visit/Map')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '拜访管理/拜访轨迹-鹰眼',
    path: '/dashboard/visit/track-yingyan',
    render: props => (
      <LazyRoute {...props} component={import('./dashboard/visit/NewTrack')} />
    ),
  },

  // 营销
  {
    exact: true,
    breadcrumbName: '营销/品牌活动',
    path: '/repair/activity-brand-config',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/activity-brand/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/一物一码',
    path: '/anti-fake-code',
    render: props => (
      <LazyRoute {...props} component={import('./anti-fake-code/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/推送记录',
    path: '/repair/push',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/push/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/门店积分',
    path: '/repair/voucher',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/voucher/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/门店礼品券',
    path: '/repair/coupon',
    render: props => (
      <LazyRoute {...props} component={import('./marketing/coupon/List')} />
    ),
  },
  // 财务
  {
    exact: true,
    breadcrumbName: '财务/收款管理',
    path: '/finance/pay',
    render: props => (
      <LazyRoute {...props} component={import('./finance/pay/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '财务/到账记录',
    path: '/finance/transfer',
    render: props => (
      <LazyRoute {...props} component={import('./finance/transfer/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '财务/收支管理',
    path: '/finance/expense',
    render: props => (
      <LazyRoute {...props} component={import('./finance/expense/List')} />
    ),
  }, {
    breadcrumbName: '财务/收支管理',
    path: '/finance/expense/:incomeShow/:expenseShow',
    render: props => (
      <LazyRoute {...props} component={import('./finance/expense/List')} />
    ),
  }, {
    breadcrumbName: '财务/应收账款',
    path: '/finance/on-account',
    render: props => (
      <LazyRoute {...props} component={import('./finance/on-account/List')} />
    ),
  }, {
    breadcrumbName: '财务/应收账款/创建收款单',
    path: '/on-account/create/:id/:orderId',
    render: props => (
      <LazyRoute {...props} component={import('./finance/on-account/CreatePay')} />
    ),
  }, {
    breadcrumbName: '财务/应收账款/创建收款单',
    path: '/on-account/create/:id',
    render: props => (
      <LazyRoute {...props} component={import('./finance/on-account/CreatePay')} />
    ),
  }, {
    breadcrumbName: '财务/应收账款/编辑收款单',
    path: '/on-account/edit/:incomeId',
    render: props => (
      <LazyRoute {...props} component={import('./finance/on-account/CreatePay')} />
    ),
  }, {
    breadcrumbName: '财务/应收账款/收款单详情',
    path: '/on-account/detail/:incomeId',
    render: props => (
      <LazyRoute {...props} component={import('./finance/on-account/Detail')} />
    ),
  }, {
    breadcrumbName: '财务/应收账款/收款单审核',
    path: '/on-account/auth/:incomeId',
    render: props => (
      <LazyRoute {...props} component={import('./finance/on-account/CreatePay')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '财务/活动资金',
    path: '/finance/wallet',
    render: props => (
      <LazyRoute {...props} component={import('./finance/wallet/List')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '财务/系统资金',
    path: '/finance/system-wallet',
    render: props => (
      <LazyRoute {...props} component={import('./finance/sys-wallet/List')} />
    ),
  }, /* {    exact: true,
    breadcrumbName: '财务/直播资金',
    path: '/finance/live-wallet',
    render: props => (
      <LazyRoute {...props} component={import('./finance/live-wallet/List')} />
    ),
  }, */

  // 设置
  {
    exact: true,
    breadcrumbName: '设置/公司信息',
    path: '/setting/company',
    render: props => (
      <LazyRoute {...props} component={import('./setting/company/Intro')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '设置/收款码',
    path: '/setting/pay-qrcode',
    render: props => (
      <LazyRoute {...props} component={import('./setting/pay-qrcode/Index')} />
    ),
  }, {
    exact: true,
    breadcrumbName: '营销/短信管理',
    path: '/setting/sms',
    render: props => (
      <LazyRoute {...props} component={import('./setting/sms/List')} />
    ),
  },

  // 10. error pages
  {
    breadcrumbName: '403/',
    path: '/permission-403',
    render: props => <LazyRoute {...props} component={import('../403')} />,
  }, {
    breadcrumbName: '404/',
    path: '*',
    render: props => <LazyRoute {...props} component={import('../404')} />,
  },
];

export default routers;
