import React from 'react';
import { message, Modal, Spin } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import apiCommon from '../../configs/api-common';

import server from '../../utils/server';

// todo 重构获取图片的代码
export default class ImageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      isError: false,
      fileKey: '',
      imageUrl: '', // 缩略图地址
      originUrl: '', // 图片原图地址
    };
  }

  /**
   * 用在popover中时，使用该函数
   */
  componentDidMount() {
    const { fileKey, fileType, thumbType } = this.props;
    const { imageUrl } = this.state;

    if (fileKey && fileKey.indexOf('http') > -1) {
      this.setState({ imageUrl: fileKey, fileKey });
      return false;
    }

    if (fileKey && (!imageUrl || fileKey !== this.state.fileKey)) {
      const fileUrl = fileType === 'private'
        ? apiCommon.file.getPrivatePicUrl(fileKey, thumbType, '')
        : apiCommon.file.getPublicPicUrl(fileKey, thumbType, '');

      server.get(fileUrl, (data) => {
        this.setState({ imageUrl: data.res.url, fileKey });
      }, (error) => {
        message.error(`图片加载失败[${error}]`);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { fileKey, fileType, thumbType } = this.props;

    if (fileKey && (fileKey !== prevProps.fileKey)) {
      const fileUrl = fileType === 'private'
        ? apiCommon.file.getPrivatePicUrl(fileKey, thumbType, '')
        : apiCommon.file.getPublicPicUrl(fileKey, thumbType, '');

      server.get(fileUrl, (data) => {
        this.setState({ imageUrl: data.res.url });
      }, (error) => {
        message.error(`获取图片url失败[${error}]`);
      });
    }
  }

  hideModal() {
    this.setState({ visible: false });
  }

  handleImagePreview() {
    const { fileKey, fileType, downloadName } = this.props;
    const { originUrl } = this.state;

    if (this.state.fileKey === fileKey && originUrl) {
      this.setState({ visible: true });
      return;
    }

    if (fileKey && fileKey.indexOf('http') > -1) {
      this.setState({ originUrl: fileKey, fileKey });
      return false;
    }

    const fileUrl = fileType === 'private'
      ? apiCommon.file.getPrivatePicUrl(fileKey, 0, downloadName || '')
      : apiCommon.file.getPublicPicUrl(fileKey, 0, downloadName || '');

    this.setState({ isLoading: true });
    server.get(fileUrl, (data) => {
      this.setState({ visible: true, isLoading: false, originUrl: data.res.url, fileKey });
    }, (error) => {
      this.setState({ isLoading: false });
      message.error(`图片加载失败[${error}]`);
    });
  }

  handleError() {
    this.setState({ isError: true });
  }

  render() {
    const { style, downloadName, isDownload } = this.props;
    const { visible, isLoading, imageUrl, originUrl, isError } = this.state;

    return (
      <span>
        {!imageUrl
          ? <PictureOutlined style={{ fontSize: '25px' }} />
          : (
            <img
              src={imageUrl}
              style={{ width: 100, height: 100, ...style, cursor: 'pointer' }}
              alt="图片"
              onError={() => this.setState({ imageUrl: '' })}
              onClick={() => this.handleImagePreview()}
            />
          )
        }

        <Modal visible={visible} width={720} footer={null} onCancel={() => this.hideModal()}>
          <Spin spinning={isLoading}>
            <figure>
              {isDownload && (
                <figcaption>
                  <div className="flex-right">
                    <a
                      href={originUrl}
                      className="mr32"
                      disabled={isError}
                      download={downloadName}
                    >
                      下载
                    </a>
                  </div>
                </figcaption>
              )}

              <img
                className="image-preview"
                onError={e => this.handleError(e)}
                src={originUrl} alt="大图"
              />
            </figure>
          </Spin>
        </Modal>
      </span>
    );
  }
}
