import React from 'react';
import { Axis, Chart, Geom, Label } from 'bizcharts';

import apiDealer from '../../../../configs/api-dealer';
import server from '../../../../utils/server';

import './style.css';

const cols = {
  sales: {
    tickInterval: 10,
  },
};

const axLine = {
  stroke: '#bef2ff',
  lineDash: [0, 0, 0],
  lineWidth: 1,
};

const axgrid = {
  type: 'polygon',
  align: 'center',
  hightLightZero: true,
  lineStyle: {
    lineWidth: 1,
    lineDash: [1, 1],
    stroke: '#bef2ff',
  },
};

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id || '',
      summary: {},
      companyList: [],
      eventList: [],
      envetTotal: 0,
      activityQRCode: '',
      productList: [],
      detail: {},
      conferenceDetail: {},
      conferenceQRCode: '',

      activityDetail: {},
      eventDetail: {},
    };
  }

  componentDidMount() {
    const { id } = this.state;
    if (id) {
      this.getConferenceDetail(id);
      this.getConferenceQRCode(id);
    }

    window.onresize = () => {
      this.setState({ id: this.state.id });
    };
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getInterval() {
    const searchParams = new URLSearchParams(location.href.split('?')[1]);
    const time = Number(searchParams.get('time')) > 0
      ? (Number(searchParams.get('time')) * 1000)
      : (30 * 1000);
    this.interval = setInterval(() => {
      const { conferenceDetail } = this.state;
      if (conferenceDetail) {
        if (conferenceDetail.relate_product_activity_ids) {
          this.getStatisticSummary(conferenceDetail.relate_product_activity_ids, false);
        }
        if (conferenceDetail.relate_event_ids) {
          this.getEventActivity(conferenceDetail.relate_event_ids, false);
        }
      }
    }, time);
  }

  getConferenceDetail(conferenceId) {
    server.get(apiDealer.conference.detail(conferenceId), (data) => {
      const { detail } = data.res;
      this.setState({ conferenceDetail: detail });
      if (detail && detail._id) {
        if (detail.relate_product_activity_ids) {
          this.getStatisticSummary(detail.relate_product_activity_ids);
          this.getActivityDetail(detail.relate_product_activity_ids);
        }
        if (detail.relate_event_ids) {
          this.getEventActivity(detail.relate_event_ids);
          this.getEventDetail(detail.relate_event_ids);
        }
        this.getInterval();
      }
    });
  }

  getStatisticSummary(id, isFirst = true) {
    this.setState({ isCss3: false });
    server.get(apiDealer.partsSale.activity.getStatisticSummary(id), (data) => {
      const { res } = data;
      const { summary } = this.state;
      const productList = [];
      const amount = summary.total_amount;
      const count = summary.company_count;

      this.rollNum('totalAmount', isFirst ? 0 : amount, res.summary.total_amount);
      this.rollNum('companyCount', isFirst ? 0 : count, res.summary.company_count);

      res.product_item_list.forEach((item) => {
        productList.push({
          _id: item._id,
          value: item.item_name,
          count: Number(item.order_item_count),
          unit: item.order_item_unit,
        });
      });

      this.setState({
        summary: res.summary,
        companyList: res.order_company_list,
        productList,
        isCss3: true,
      });
    });
  }

  getActivityDetail(id) {
    server.get(apiDealer.partsSale.activity.detail(id), (data) => {
      const { detail } = data.res;
      this.setState({ activityDetail: detail });
    });
  }

  getEventActivity(id, isFirst = true) {
    server.get(apiDealer.enroll.attendList({ page: 1, limit: -1, eventId: id }), (data) => {
      const { list, total } = data.res;
      const { envetTotal } = this.state;
      this.rollNum('listedCompany', isFirst ? 0 : envetTotal, total);
      this.setState({ eventList: list, envetTotal: total });
    });
  }

  getEventDetail(id) {
    server.get(apiDealer.enroll.detail(id), (data) => {
      const { detail } = data.res;
      this.setState({ eventDetail: detail });
    });
  }

  getConferenceQRCode(id) {
    server.get(apiDealer.conference.getQRCode(id), (data) => {
      this.setState({ conferenceQRCode: data.res.pic_data });
    });
  }

  // 数字变化特效
  rollNum(elId, startVal, endVal, decimalNum) {
    const n = decimalNum || 0;
    const countUp = new CountUp(elId, startVal, endVal, n, 2.5, {
      useEasing: true,
      useGrouping: true,
      separator: ',',
      decimal: '.',
    });
    if (!countUp.error) {
      countUp.start();
    } else {
      console.error(countUp.error);
    }
  }

  getHeight() {
    const wrapper = this.wrapperRef;
    if (wrapper && wrapper.clientHeight) {
      return screen.width > 1900 ? wrapper.clientHeight - 130 : wrapper.clientHeight - 26;
    }
    return 400;
  }

  render() {
    const {
      eventList, activityDetail, productList, companyList, conferenceDetail,
      conferenceQRCode, eventDetail, isCss3,
    } = this.state;

    const axLabel = {
      textStyle: {
        fontSize: screen.width > 1900 ? '26' : '12',
        fill: '#bef2ff',
        textBaseline: 'top',
        autoRotate: true,
      },
    };

    const axYLabel = {
      textStyle: {
        rotate: 45,
        fontSize: screen.width > 1900 ? '26' : '12',
        fill: '#bef2ff',
        textBaseline: 'top',
        autoRotate: true,
      },
    };
    const geomStyle = {
      textAlign: 'center',
      fill: '#bef2ff',
      fontSize: screen.width > 1900 ? '26' : '12',
    };

    return (
      <div className="layout">
        <header id="header">
          <h3 className="bi-header-title">{conferenceDetail.title}</h3>
          <h3 className="bi-header-title-1" style={{ color: '#bef2ff' }}>智能数据大屏</h3>
        </header>

        <div id="container">
          <div id="flexCon">
            <div className="flex-row">
              <div className="flex-cell flex-cell-r">
                <div className="chart-wrapper">
                  <img
                    src={require('../../../../images/bi_img/shuidao-bi-3.png')}
                    className="shuidao-bi-l"
                  />
                  <div className="chart-loader flex-center">
                    <div className="flex-center bi-mt80">
                      <img
                        src={conferenceQRCode}
                        style={{
                          width: screen.width > 1900 ? '390px' : '190px',
                          background: 'rgb(190,242,255)',
                        }}
                      />
                      <p className="qrcode-title">微信扫描二维码即可参与</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-cell flex-cell-r">
                <div className="chart-wrapper">
                  <div className="chart-div">
                    <h1 className="header-title">{eventDetail.title}</h1>
                    <h3 className="chart-bi-title bi-mt20 flex-cell-center">
                      实时报名门店数&nbsp;&nbsp;&nbsp;
                      <p className="header-title"><span id="listedCompany">0</span>家</p>
                    </h3>

                    <div className="module-wrapper flex-cell">
                      <h3 className="chart-bi-title">门店名称</h3>
                      <div className="company_cell">
                        <div className={eventList.length > 9 ? 'rowup' : ''}>
                          {eventList.map(item => (
                            <div className="flex-row list-item" key={item._id}>
                              <p className="cell-tb-1 chart-bi-title table-td"
                                 style={{ marginTop: 0 }}>
                                {item.company_name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex-cell flex-cell-c"
                style={{ padding: '10px 0px' }}
              >
                <div className="chart-wrapper">
                  <div className="chart-div">
                    <h1 className="header-title">{activityDetail.title}</h1>
                    <div className="flex-row chart-wrapper">
                      <div className="flex-cell flex-cell-c" style={{ padding: 0 }}>
                        <div
                          className="chart-wrapper" ref={ref => this.wrapperRef = ref}
                          style={{ maxWidth: screen.width > 1900 ? 1000 : 430 }}
                        >
                          <h3 className="chart-bi-title bi-mt20 flex-cell-center">
                            实时销售额&nbsp;&nbsp;&nbsp;
                            <p className="header-title">¥<span id="totalAmount">0</span></p>
                          </h3>

                          <div className="module-wrapper flex-cell mr15" style={{ border: '0px' }}>
                            <Chart
                              height={this.getHeight()}
                              data={productList} scale={cols}
                              forceFit
                              padding={[screen.width > 1900 ? 40 : 30, 'auto', 230, 'auto']}
                            >
                              <Axis
                                name="value"
                                label={axYLabel}
                                line={axLine}
                                grid={axgrid}
                              />
                              <Axis
                                name="count"
                                label={axLabel}
                                line={axLine}
                                grid={axgrid}
                              />
                              <Geom
                                type="interval"
                                position="value*count"
                                color="#006ff7"
                              >
                                <Label
                                  content={[
                                    ['value', 'count', 'unit'], (value, count, unit) => (
                                      `${count}${unit}`
                                    )]}
                                  offset={20} // 设置坐标轴文本 label 距离坐标轴线的距离
                                  textStyle={geomStyle}
                                />
                              </Geom>
                            </Chart>
                          </div>
                        </div>
                      </div>

                      <div className="flex-cell flex-cell-l" style={{ padding: 0 }}>
                        <div className="chart-wrapper">
                          <h3 className="chart-bi-title bi-mt20 flex-cell-center">
                            订货门店数量&nbsp;&nbsp;&nbsp;
                            <p className="header-title"><span id="companyCount">0</span>家</p>
                          </h3>

                          <div className="module-wrapper flex-cell">
                            <div className="flex-row-between">
                              <p className="cell-tb-1 chart-bi-title pl15">
                                门店名称
                              </p>
                              <p className="flex-cell-r chart-bi-title"
                                 style={{ textAlign: 'right' }}>
                                订货额
                              </p>
                              <p className="cell-tb-3 chart-bi-title mr5">箱数</p>
                            </div>
                            <div className="company_cell">
                              <div className={isCss3 && companyList.length >= 9 ? 'rowup' : ''}>
                                {companyList.map((item, index) => (
                                  <div className="flex-row-between flex-row list-item"
                                       key={index.toString()}>
                                    <p className="cell-tb-1 chart-bi-title table-td"
                                       style={{ marginTop: 0 }}>
                                      {item.company_name && item.company_name.length > 11
                                        ? item.company_name.substr(0, 11)
                                        : item.company_name}
                                    </p>
                                    <p className="flex-cell-r chart-bi-title table-td"
                                       style={{ textAlign: 'right', marginTop: 0 }}>
                                      ¥{item.total_amount}
                                    </p>
                                    <p className="cell-tb-3 chart-bi-title table-td"
                                       style={{ marginTop: 0 }}>
                                      {item.total_count}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-cell flex-cell-r">
                <div className="chart-wrapper">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={require('../../../../images/bi_img/shuidao-bi-1.png')}
                      className="shuidao-bi-r"
                    />

                    {/*<img*/}
                    {/*  src={require('../../../../images/bi_img/shuidao-bi-2.png')}*/}
                    {/*  className="shuidao-bi-r"*/}
                    {/*/>*/}

                    <img
                      src={require('../../../../images/bi_img/shuidao-bi-logo.png')}
                      className="shuidao-bi-logo-r"
                    />
                  </div>
                  <div className="chart-loader flex-center">
                    <div className="flex-center bi-mt80">
                      <img
                        src={conferenceQRCode}
                        style={{
                          width: screen.width > 1900 ? '390px' : '190px',
                          background: 'rgb(190,242,255)',
                        }}
                      />
                      <p className="qrcode-title">微信扫描二维码即可参与</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
