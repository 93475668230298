import React from 'react';
import { Badge, Popover } from 'antd';
import { FullscreenOutlined, CompassOutlined, PlusSquareOutlined, SolutionOutlined, EnvironmentOutlined, EditOutlined, CheckSquareOutlined, EuroOutlined } from '@ant-design/icons';
import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts';

import apiDealer from '../../../../configs/api-dealer';
import server from '../../../../utils/server';
import DateUtil from '../../../../utils/DateUtil';
import AuthUtil from '../../../../utils/AuthUtil';

import ImageView from '../../../../components/widget/ImageView';

import PieChart from '../../common/PieChart';

import YingyanTrack from '../visit/NewTrack';

import '../../store/repair-shop/style.less';
import './style.less';

const actionType = {
  101: CompassOutlined,
  102: PlusSquareOutlined,
  103: SolutionOutlined,
  104: EnvironmentOutlined,
  105: EditOutlined,
  106: CheckSquareOutlined,
};

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // startDate: DateUtil.day(DateUtil.getCurrentMonthStart()),
      startDate: DateUtil.day(DateUtil.getLatestMonthStart()),
      endDate: DateUtil.day(DateUtil.getCurrentDate()),
      dealerUserId: '',
      summary: {
        orderAmount: 0,
        orderCount: 0,
        orderPayCount: 0,
        newStoreCount: 0,
        installStoreCount: 0,
        systemUseCount: 0,
        visitCount: 0,
      },
      companyDays: [],
      orderDays: [],
      productSells: [],
      userSells: [],

      // 地图
      markers: [],
      linePoints: [],
      pointMarkers: [],
      center: {
        longitude: 0,
        latitude: 0,
      },

      // 订单
      orderList: [],
    };

    this.renderMarkerInfo = extData => (
      <div>
        <ImageView fileKey={extData.icon_pic || ''} style={{ maxWidth: '100%' }} />
        <p>地址：{extData.address}</p>
      </div>
    );
    this.renderMarkerPoint = (extData) => {
      const content = (
        <div>
          <p>业务员：{extData.user_name}</p>
          <p>拜访内容：{`${extData.action_type_name} ${extData.remark}`}</p>
          <p>拜访时间：{extData.ctime}</p>
          <p>拜访地址：{extData.address}</p>
        </div>
      );

      const ActionTypeIcon = actionType[extData.action_type];

      return (
        <div style={{ marginTop: 18 }}>
          <Popover content={content} title={extData.name} trigger="hover">
            <div>
              <Badge count={extData.index} overflowCount={999}>
                {actionType[extData.action_type] ? (
                  <ActionTypeIcon style={{ fontSize: 20, color: '#52c41a' }} />
                ) : (
                  <EuroOutlined
                    style={{ fontSize: 20, color: '#52c41a' }}
                  />
                )}
              </Badge>
            </div>
          </Popover>
        </div>
      );
    };

    this.mapEvents = {
      created: (ins) => {
        this.setState({ mapCreated: ins });
      },
    };
  }

  componentDidMount() {
    const path = this.props.location.pathname;
    this.getSummaryData(this.state);
    // this.getAllRepairShops();
    this.getdealerInfo();
    this.getOrder();
    if (path === '/bi-system') {
      this.getSetTimeOut();
    }
  }

  componentWillUnmount() {
    const path = this.props.location.pathname;
    if (path === '/bi-system') {
      clearInterval(this.tick);
    }
  }

  getSetTimeOut() {
    this.tick = setInterval(() => {
      this.setState({
        startDate: DateUtil.day(DateUtil.getLatestMonthStart()),
        endDate: DateUtil.day(DateUtil.getCurrentDate()),
      }, () => {
        this.getSummaryData(this.state);
        this.getdealerInfo();
        this.getOrder();
      });
    }, 60000);
  }

  getdealerInfo() {
    server.get(apiDealer.setting.company.detail(), (data) => {
      const dealerInfo = data.res.detail;
      const coordidate = dealerInfo.poi.split(',');
      this.setState({ center: { longitude: coordidate[0], latitude: coordidate[1] } });
    });
  }

  getSummaryData(condition) {
    this.getSummaryCompanyDays(condition);
    this.getSummaryOrderDays(condition);
    this.getUserSell(condition);
    this.getProductSell(condition);
  }

  getSummaryCompanyDays(condition) {
    server.get(apiDealer.statistics.summary.companyDays(condition), (data) => {
      const { list } = data.res;
      const { summary } = this.state;

      const summaryCompanyArr = [];

      let newStoreCount = 0;
      let installStoreCount = 0;
      let systemUseCount = 0;
      let visitCount = 0;

      // antv 暂不支持给字段设置对应的中文描述，为了可以读性，直接写成中文
      list.forEach((item) => {
        summaryCompanyArr.push({
          date: item.date,
          系统安装: Number(item.content.active_count),
          新增门店: Number(item.content.company_new_count),
          use_count: Number(item.content.use_count),
          visit_count: Number(item.content.visit_count),
        });

        newStoreCount += Number(item.content.company_new_count);
        installStoreCount += Number(item.content.active_count);
        systemUseCount += Number(item.content.use_count);
        visitCount += Number(item.content.visit_count);
      });

      summary.newStoreCount = newStoreCount;
      summary.installStoreCount = installStoreCount;
      summary.systemUseCount = systemUseCount;
      summary.visitCount = visitCount;

      this.setState({ companyDays: summaryCompanyArr, summary });
    });

    // 今日数据
    const dataDay = { ...condition };
    dataDay.startDate = DateUtil.day(DateUtil.getCurrentDate());
    dataDay.endDate = DateUtil.day(DateUtil.getCurrentDate());
    server.get(apiDealer.statistics.summary.companyDays(dataDay), (data) => {
      const { list } = data.res;
      const { summary } = this.state;

      let visitCount = 0;

      // antv 暂不支持给字段设置对应的中文描述，为了可以读性，直接写成中文
      list.forEach((item) => {
        visitCount += Number(item.content.visit_count);
      });

      summary.day_visitCount = visitCount;

      this.setState({ summary });
    });

    // 本周
    const dataWeek = { ...condition };
    dataWeek.startDate = DateUtil.day(DateUtil.getCurrentWeekStart());
    dataWeek.endDate = DateUtil.day(DateUtil.getCurrentDate());
    server.get(apiDealer.statistics.summary.companyDays(dataWeek), (data) => {
      const { list } = data.res;
      const { summary } = this.state;

      let visitCount = 0;

      // antv 暂不支持给字段设置对应的中文描述，为了可以读性，直接写成中文
      list.forEach((item) => {
        visitCount += Number(item.content.visit_count);
      });

      summary.week_visitCount = visitCount;

      this.setState({ summary });
    });
  }

  getSummaryOrderDays(condition) {
    server.get(apiDealer.statistics.summary.orderDays(condition), (data) => {
      const { list } = data.res;
      const { summary } = this.state;

      const summaryOrderArr = [];

      let orderAmount = 0;
      let orderCount = 0;
      let orderPayCount = 0;
      let authAmount = 0;

      list.forEach((item) => {
        summaryOrderArr.push({
          date: item.date,
          company_count: Number(item.content.company_count),
          order_new_count: Number(item.content.order_new_count),
          pay_amount: Number(item.content.pay_amount),
          auth_amount: Number(item.content.auth_amount),
        });
        orderAmount += Number(item.content.pay_amount);
        orderCount += Number(item.content.order_new_count);
        orderPayCount += Number(item.content.company_count);
        authAmount += isNaN(item.content.auth_amount) ? 0 : Number(item.content.auth_amount);
      });
      summary.orderAmount = Number(orderAmount).toFixed(2);
      summary.orderCount = orderCount;
      summary.orderPayCount = orderPayCount;
      summary.authAmount = Number(authAmount).toFixed(2);

      this.setState({ orderDays: summaryOrderArr, list, summary });
    });

    // 今日数据
    const dataDay = { ...condition };
    dataDay.startDate = DateUtil.day(DateUtil.getCurrentDate());
    dataDay.endDate = DateUtil.day(DateUtil.getCurrentDate());
    server.get(apiDealer.statistics.summary.orderDays(dataDay), (data) => {
      const { list } = data.res;
      const { summary } = this.state;

      let orderAmount = 0;
      let authAmount = 0;
      let orderPayCount = 0;

      list.forEach((item) => {
        orderAmount += Number(item.content.pay_amount);
        authAmount += Number(item.content.auth_amount);
        orderPayCount += Number(item.content.company_count);
      });
      summary.day_orderAmount = Number(orderAmount).toFixed(2);
      summary.day_authAmount = Number(authAmount).toFixed(2);
      summary.day_orderPayCount = orderPayCount;

      this.setState({ summary });
    });

    // 本周
    const dataWeek = { ...condition };
    dataWeek.startDate = DateUtil.day(DateUtil.getCurrentWeekStart());
    dataWeek.endDate = DateUtil.day(DateUtil.getCurrentDate());
    server.get(apiDealer.statistics.summary.orderDays(dataWeek), (data) => {
      const { list } = data.res;
      const { summary } = this.state;

      let orderAmount = 0;
      let authAmount = 0;
      let orderPayCount = 0;

      list.forEach((item) => {
        orderAmount += Number(item.content.pay_amount);
        authAmount += Number(item.content.auth_amount);
        orderPayCount += Number(item.content.company_count);
      });
      summary.week_orderAmount = Number(orderAmount).toFixed(2);
      summary.week_authAmount = Number(authAmount).toFixed(2);
      summary.week_orderPayCount = orderPayCount;

      this.setState({ summary });
    });
  }

  getUserSell(condition) {
    server.get(apiDealer.statistics.summary.userSellList(condition), (data) => {
      const { list } = data.res;
      const userSells = [];
      list.forEach((item, index) => {
        if (index < 5) {
          userSells.push(item);
        }
      });

      while (userSells.length < 5) {
        for (let i = 0; i < 5 - userSells.length; i++) {
          userSells.push({
            supplier_user_id: i,
            supplier_user_name: '',
            company_count: '',
            order_count: '',
            pay_amount: '',
          });
        }
      }

      this.setState({ userSells });
    });
  }

  getProductSell(condition) {
    server.get(apiDealer.statistics.summary.productSellList(condition), (data) => {
      const { list } = data.res;
      const productSells = [];
      list.forEach((item, index) => {
        if (index < 5) {
          productSells.push(item);
        }
      });

      while (productSells.length < 5) {
        for (let i = 0; i < 5 - productSells.length; i++) {
          productSells.push({
            product_id: i,
            product_name: '',
            company_count: '',
            order_count: '',
          });
        }
      }
      this.setState({ productSells });
    });
  }

  getOrder() {
    server.get(apiDealer.partsSale.order.list({
      status: '-10',
      payStatus: '-10',
      payChannel: '-10',
      limit: 16,
    }), (data) => {
      const { list } = data.res;

      while (list.length < 16) {
        for (let i = 0; i < 16 - list.length; i++) {
          list.push({
            supplier_user_id: i,
            company_name: '',
            supplier_user_name: '',
            total_fee: '',
          });
        }
      }
      this.setState({ orderList: list });
    });
  }

  render() {
    const { orderDays, companyDays, userSells, productSells, summary, orderList } = this.state;

    const datas = [];
    const saleDays = [];
    orderDays.forEach((item) => {
      datas.push({ date: item.date, city: '交易客户数', count: isNaN(item.company_count) ? 0 : item.company_count });
      saleDays.push({ date: item.date, city: '回款金额', count: isNaN(item.pay_amount) ? 0 : item.pay_amount });
      saleDays.push({ date: item.date, city: '销售额', count: isNaN(item.auth_amount) ? 0 : item.auth_amount });
    });

    companyDays.forEach((item) => {
      datas.push({ date: item.date, city: '拜访客户数', count: isNaN(item.visit_count) ? 0 : item.visit_count });
    });

    const path = this.props.location.pathname;
    const isFullScreen = path === '/bi-system';
    const userInfo = AuthUtil.getLoginUserInfo();

    const screenWidth = screen.width;
    const screenHeight = screen.height;

    // 调节背景图在不同分辨率下的缩放，导致顶部背景显示下移的问题
    let headerHeight = 125;
    if (screenWidth > 1680) {
      headerHeight = 180;
    } else if (screenWidth > 1920) {
      headerHeight = 200;
    } else if (screenWidth > 3860) {
      headerHeight = 390;
    }

    const isLargeScreen = screenWidth >= 1680;
    const isSuperScreen = screenWidth >= 3840;

    /**
     * 内容1等分的宽度
     * 默认 左中右为1:2:1 =>4
     * isLargeScreen 左中右为1:3:1 => 5
     * 10*2 + 12*2 + 6*4
     */
    const screenOneColumnWidth = (screenWidth - (29 + 24 + 24)) / (isLargeScreen ? 5 : 4);
    const lineChartWidth = (screenOneColumnWidth * (isLargeScreen ? 3 : 2)) / 2;
    // 中间内容高度 10*2 + 12
    const centerContentHeight = screenHeight - headerHeight - (20 + 12);
    let lineChartHeight = centerContentHeight / 3; // 2:1
    if (isLargeScreen) {
      lineChartHeight = centerContentHeight / 4; // 3:1
    } else if (isSuperScreen) {
      lineChartHeight = centerContentHeight / 6; // 5:1
    }

    const pieChartWidth = (screenWidth - 68) / (isLargeScreen ? 5 : 4);
    let pieChartHeight = ((screenHeight - headerHeight - 120 - 12) / 3);

    // todo 小屏幕计算有差值
    if (screenWidth > 1680 && screenWidth < 1920) {
      pieChartHeight -= 10;
    } else if (screenWidth <= 1280) {
      pieChartHeight -= 30;
    }

    const dataSource = [
      {
        week: '本月',
        visit_count: summary.visitCount,
        pay_count: summary.orderPayCount,
        order_amount: summary.orderAmount,
        auth_amount: summary.authAmount,
      }, {
        week: '本周',
        visit_count: summary.week_visitCount,
        pay_count: summary.week_orderPayCount,
        order_amount: summary.week_orderAmount,
        auth_amount: summary.week_authAmount,
      }, {
        week: '今日',
        visit_count: summary.day_visitCount,
        pay_count: summary.day_orderPayCount,
        order_amount: summary.day_orderAmount,
        auth_amount: summary.day_authAmount,
      },
    ];

    return (
      <div
        className={`bi-content ${isFullScreen ? '' : 'inner-page'}`}
        style={{ padding: !isFullScreen ? '0' : '10px' }}
      >
        {path !== '/bi-system' ? (
          <div className="flex-right mb5">
            <a href="../bi-system" target="_blank">
              <FullscreenOutlined style={{ fontSize: '18px' }} />
            </a>
          </div>
        ) : (
          <div className="bi-header">
            <p className="header-title">{userInfo.dealerName}</p>
            <p className="header-sub-title">水稻智能BI</p>
          </div>
        )}

        <div className="page-content">
          <div className="page-left">
            <div className="module-wrapper mb10">
              <div className="flex-table">
                <div className="col">
                  <div className="cell col-header" />
                  {dataSource.map((item, index) => (
                    <div className="cell" key={`week${index.toString()}`}>{item.week}</div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">拜访数</div>
                  {dataSource.map((item, index) => (
                    <div className="cell" key={`visit${index.toString()}`}>
                      {isNaN(item.visit_count) ? 0 : item.visit_count}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">客户数</div>
                  {dataSource.map((item, index) => (
                    <div className="cell" key={`pay${index.toString()}`}>
                      {isNaN(item.pay_count) ? 0 : item.pay_count}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell text-right col-header">回款</div>
                  {dataSource.map((item, index) => (
                    <div className="cell text-right" key={`order${index.toString()}`}>
                      {isNaN(item.order_amount) ? 0 : item.order_amount}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell text-right col-header">交易</div>
                  {dataSource.map((item, index) => (
                    <div className="cell text-right" key={`auth${index.toString()}`}>
                      {isNaN(item.auth_amount) ? 0 : item.auth_amount}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="module-wrapper five">
              <div className="table-title">实时订单</div>
              <div className="flex-table five">
                <div className="col three">
                  <div className="cell col-header">门店</div>
                  {orderList.map((item, index) => (
                    <div className="cell" key={`order_company${index.toString()}`}>
                      {item.company_name}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">业务员</div>
                  {orderList.map((item, index) => (
                    <div className="cell" key={`order_user${index.toString()}`}>
                      {item.supplier_user_name}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell text-right col-header">金额</div>
                  {orderList.map((item, index) => (
                    <div className="cell text-right" key={`fee${index.toString()}`}>
                      {item.total_fee}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={`page-center ${isLargeScreen ? 'large' : ''}`}>
            <div
              className={`flex-map ${isLargeScreen ? 'large' : ''} ${isSuperScreen ? 'super' : ''}`}
            >
              <YingyanTrack isInnerPage />
            </div>
            <div className="flex-chart">
              <div className="line-chart">
                <div className="chart-wrapper" style={{ marginRight: 10 }}>
                  <Chart
                    theme="dark"
                    data={saleDays}
                    width={lineChartWidth}
                    height={lineChartHeight}
                    forceFit
                    background={{ opacity: 0.7, fillOpacity: 0.7 }}
                    plotBackground={{ fillOpacity: 0.3 }}
                    padding={[20, 20, 60, 50]}
                  >
                    <Axis
                      name="date"
                      label={{
                        formatter(text) {
                          return text.substr(5, text.length);
                        },
                      }}
                    />
                    <Axis name="count" />
                    <Legend offsetY={5} />
                    <Tooltip crosshairs={{ type: 'y' }} />
                    <Geom
                      type="line"
                      position="date*count"
                      size={2}
                      color="city"
                      shape="smooth"
                    />
                    <Geom
                      type="point"
                      position="date*count"
                      size={4}
                      shape="circle"
                      color="city"
                      style={{
                        stroke: '#fff',
                        lineWidth: 1,
                      }}
                    />
                  </Chart>
                </div>
              </div>
              <div className="line-chart">
                <div className="chart-wrapper">
                  <Chart
                    theme="dark"
                    data={datas}
                    width={lineChartWidth}
                    height={lineChartHeight}
                    forceFit
                    background={{ opacity: 0.7, fillOpacity: 0.7 }}
                    plotBackground={{ fillOpacity: 0.3 }}
                    padding={[20, 20, 60, 50]}
                  >
                    <Axis
                      name="date"
                      label={{
                        formatter(text) {
                          return text.substr(5, text.length);
                        },
                      }}
                    />
                    <Axis name="count" />
                    <Legend offsetY={5} />
                    <Tooltip crosshairs={{ type: 'y' }} />
                    <Geom
                      type="line"
                      position="date*count"
                      size={2}
                      color="city"
                      shape="smooth"
                    />
                    <Geom
                      type="point"
                      position="date*count"
                      size={4}
                      shape="circle"
                      color="city"
                      style={{
                        stroke: '#fff',
                        lineWidth: 1,
                      }}
                    />
                  </Chart>
                </div>
              </div>
            </div>
          </div>

          <div className="page-right">
            <div className="module-wrapper mb10">
              <div className="table-title">员工Top5</div>
              <div className="flex-table">
                <div className="col">
                  <div className="cell col-header">姓名</div>
                  {userSells.map((item, index) => (
                    <div className="cell" key={`name${index.toString()}`}>
                      {item.supplier_user_name}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">门店</div>
                  {userSells.map((item, index) => (
                    <div className="cell" key={`company${index.toString()}`}>
                      {item.company_count}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">订单</div>
                  {userSells.map((item, index) => (
                    <div className="cell" key={`order_count${index.toString()}`}>
                      {item.order_count}
                    </div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header text-right">销售</div>
                  {userSells.map((item, index) => (
                    <div className="cell text-right" key={`sell${index.toString()}`}>
                      {item.pay_amount}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="module-wrapper two">
              <div className="table-title">关注产品Top5</div>
              <div className="flex-table">
                <div className="col three">
                  <div className="cell col-header">名称</div>
                  {productSells.map((item, index) => (
                    <div className="cell" key={`name_${index.toString()}`}>{item.product_name}</div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">门店</div>
                  {productSells.map((item, index) => (
                    <div className="cell" key={`count_${index.toString()}`}>{item.company_count}</div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header">订单</div>
                  {productSells.map((item, index) => (
                    <div className="cell" key={`order_count_${index.toString()}`}>{item.order_count}</div>
                  ))}
                </div>
                <div className="col">
                  <div className="cell col-header text-right">销售</div>
                  {productSells.map((item, index) => (
                    <div className="cell text-right" key={`pay_amount_${index.toString()}`}>{item.pay_amount}</div>
                  ))}
                </div>
              </div>

              <div className="chart-wrapper mt20">
                <PieChart
                  theme="dark"
                  width={pieChartWidth}
                  height={pieChartHeight || 150}
                  padding={[10, 10, 70, 10]}
                  legendOffsetY={30}
                  field="pay_amount"
                  dimension="product_name"
                  data={productSells}
                  background={{ opacity: 0.7, fillOpacity: 0.7 }}
                  plotBackground={{ fillOpacity: 0.7 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
