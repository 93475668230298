import React from 'react';
import { Select } from 'antd';

import apiDealer from '../../../configs/api-dealer';
import server from '../../../utils/server';

const Option = Select.Option;

export default class SelectUser extends React.Component {
  static defaultProps = {
    value: '',
    selectStyle: { width: 200 },
    isAll: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    server.get(apiDealer.user.listAll(), (data) => {
      this.setState({ users: data.res.list });
    });
  }

  render() {
    const { selectStyle, value, isAll } = this.props;
    const { users } = this.state;

    return (
      <Select
        style={selectStyle}
        placeholder="请选择业务员"
        defaultValue={`${value}`}
        onChange={this.props.onChange}
      >
        {isAll && (<Option value="">全部</Option>)}
        {users.map(item => <Option key={item._id}>{item.name}</Option>)}
      </Select>
    );
  }
}
