import React from 'react';
import { DatePicker, message, Spin, Switch } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

import apiDealer from '../../../../configs/api-dealer';
import apiCommon from '../../../../configs/api-common';
import server from '../../../../utils/server';
import DateUtil from '../../../../utils/DateUtil';
import mapCommon from '../../../../utils/mapCommon';
import mapControl from '../../../../utils/mapControl';

import ImageView from '../../../../components/widget/ImageView';
import SelectUser from '../../common/SelectUser';

import '../../store/repair-shop/style.less';

const styleJson = [
  {
    featureType: 'land',
    elementType: 'geometry',
    stylers: {
      color: '#242f3eff',
    },
  }, {
    featureType: 'manmade',
    elementType: 'geometry',
    stylers: {
      color: '#242f3eff',
    },
  }, {
    featureType: 'water',
    elementType: 'geometry',
    stylers: {
      color: '#17263cff',
    },
  }, {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: {
      color: '#9e7d60ff',
    },
  }, {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#554631ff',
    },
  }, {
    featureType: 'districtlabel',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d69563ff',
    },
  }, {
    featureType: 'districtlabel',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#17263cff',
      weight: 3,
    },
  }, {
    featureType: 'poilabel',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d69563ff',
    },
  }, {
    featureType: 'poilabel',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#17263cff',
      weight: 3,
    },
  }, {
    featureType: 'subway',
    elementType: 'geometry',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'railway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'poilabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'subwaylabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'subwaylabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'tertiarywaysign',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'tertiarywaysign',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'provincialwaysign',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'provincialwaysign',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'nationalwaysign',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'nationalwaysign',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'highwaysign',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'highwaysign',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'green',
    elementType: 'geometry',
    stylers: {
      color: '#263b3eff',
    },
  }, {
    featureType: 'nationalwaysign',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d0021bff',
    },
  }, {
    featureType: 'nationalwaysign',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#ffffffff',
    },
  }, {
    featureType: 'city',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'city',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'city',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d69563ff',
    },
  }, {
    featureType: 'city',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#17263cff',
    },
  }, {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d69563ff',
    },
  }, {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#242f3eff',
    },
  }, {
    featureType: 'local',
    elementType: 'geometry.fill',
    stylers: {
      color: '#38414eff',
    },
  }, {
    featureType: 'local',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#ffffff00',
    },
  }, {
    featureType: 'fourlevelway',
    elementType: 'geometry.fill',
    stylers: {
      color: '#38414eff',
    },
  }, {
    featureType: 'fourlevelway',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#ffffff00',
    },
  }, {
    featureType: 'tertiaryway',
    elementType: 'geometry.fill',
    stylers: {
      color: '#38414eff',
    },
  }, {
    featureType: 'tertiaryway',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#ffffff00',
    },
  }, {
    featureType: 'tertiaryway',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'fourlevelway',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry.fill',
    stylers: {
      color: '#9e7d60ff',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#554631ff',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'geometry.fill',
    stylers: {
      color: '#9e7d60ff',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#554631ff',
    },
  }, {
    featureType: 'tertiaryway',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'fourlevelway',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry.fill',
    stylers: {
      color: '#9e7d60ff',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#554631ff',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry.fill',
    stylers: {
      color: '#9e7d60ff',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#554631ff',
    },
  }, {
    featureType: 'arterial',
    elementType: 'geometry.fill',
    stylers: {
      color: '#9e7d60ff',
    },
  }, {
    featureType: 'arterial',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#554631fa',
    },
  }, {
    featureType: 'medicallabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'medicallabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'entertainmentlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'entertainmentlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'estatelabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'estatelabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'businesstowerlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'businesstowerlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'companylabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'companylabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'governmentlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'governmentlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'restaurantlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'restaurantlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'hotellabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'hotellabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'shoppinglabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'shoppinglabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'lifeservicelabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'lifeservicelabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'carservicelabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'carservicelabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'financelabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'financelabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'otherlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'otherlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'airportlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'airportlabel',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d69563ff',
    },
  }, {
    featureType: 'airportlabel',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#17263cff',
    },
  }, {
    featureType: 'airportlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'highway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'highway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'highway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'highway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'highway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'highway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'nationalway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'nationalway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'nationalway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'nationalway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'nationalway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'nationalway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'highway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'provincialway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '8,9',
      level: '8',
    },
  }, {
    featureType: 'provincialway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '8,9',
      level: '9',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '8,9',
      level: '8',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '8,9',
      level: '9',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '8,9',
      level: '8',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '8,9',
      level: '9',
    },
  }, {
    featureType: 'cityhighway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'cityhighway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'cityhighway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'cityhighway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'cityhighway',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '6',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '7',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '8',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '9',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '6,10',
      level: '10',
    },
  }, {
    featureType: 'arterial',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '9,10',
      level: '9',
    },
  }, {
    featureType: 'arterial',
    stylers: {
      curZoomRegionId: '0',
      curZoomRegion: '9,10',
      level: '10',
    },
  }, {
    featureType: 'arterial',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '9,10',
      level: '9',
    },
  }, {
    featureType: 'arterial',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '9,10',
      level: '10',
    },
  }, {
    featureType: 'arterial',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '9,10',
      level: '9',
    },
  }, {
    featureType: 'arterial',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
      curZoomRegionId: '0',
      curZoomRegion: '9,10',
      level: '10',
    },
  }, {
    featureType: 'building',
    elementType: 'geometry.fill',
    stylers: {
      color: '#2a3341ff',
    },
  }, {
    featureType: 'building',
    elementType: 'geometry.stroke',
    stylers: {
      color: '#1a212eff',
    },
  }, {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'arterial',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#759879ff',
    },
  }, {
    featureType: 'provincialway',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'cityhighway',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'arterial',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#1a2e1cff',
    },
  }, {
    featureType: 'local',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'manmade',
    elementType: 'labels.text.fill',
    stylers: {
      color: '#d69563ff',
    },
  }, {
    featureType: 'manmade',
    elementType: 'labels.text.stroke',
    stylers: {
      color: '#17263cff',
    },
  }, {
    featureType: 'subwaystation',
    elementType: 'geometry',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'transportationlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'transportationlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'estate',
    elementType: 'geometry',
    stylers: {
      color: '#2a3341ff',
    },
  }, {
    featureType: 'scenicspotslabel',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'scenicspotslabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'off',
    },
  }, {
    featureType: 'educationlabel',
    elementType: 'labels',
    stylers: {
      visibility: 'on',
    },
  }, {
    featureType: 'educationlabel',
    elementType: 'labels.icon',
    stylers: {
      visibility: 'on',
    },
  }];

/**
 * 拜访轨迹，百度鹰眼
 */
export default class Track extends React.Component {
  static defaultProps = {
    isInnerPage: false, // 是否嵌套在别的页面
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isViewMap: false,
      date: DateUtil.day(new Date()),
      userId: '',
      detail: {},
      selectUser: {},
      list: [],
      repairShops: [],

      infoBoxObject: {},
      center: { lng: 116.414954, lat: 40.046087 },

      // 轨迹列表
      trackList: [],
      // 当前选中轨迹
      currentTrack: {},
      // 空白entity列表，占位用
      blankEntityList: [],
      // 当前选择的entity名
      currentEntityName: '',
      // 当前选中的entity显示的内容
      currentEntityPrint: '',
      // 是否是第一次点击绘制
      first: true,
      // 当前选中的track的全天完整数据
      currentTrackData: [],
    };

    this.initCompany = this.initCompany.bind(this);
  }

  componentWillMount() {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = 'https://api.map.baidu.com/api?v=3.0&ak=ypu21obbyGc7E1wx6H8S5l4GuOo78oaz&callback=initialized';
    document.body.appendChild(scriptTag);
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (window.mapAPIScriptStatus === 'success') {
        this.initCompany();
        window.mapAPIScriptStatus = '';
        clearInterval(interval);
      }
    }, 500);
  }

  initCompany() {
    const reactBMap = require('react-bmap');
    this.setState({ isLoading: false, reactBMap, isViewMap: true }, () => {
      this.initMap();
    });
    this.getDealerInfo();
    const { isInnerPage } = this.props;
    if (isInnerPage) {
      this.getAllDealers();
      this.tick = setInterval(() => {
        const { selectUser, users } = this.state;
        const date = DateUtil.day(new Date());
        this.setState({ date });
        const map = this.BMap.map;
        // 清除图层
        users.forEach(() => {
          if (typeof (window.canvasLayer) !== 'undefined' || typeof (window.canvasLayerBack) !== 'undefined' ||
            typeof (window.CanvasLayerPointer) !== 'undefined') {
            map.removeOverlay(window.CanvasLayerPointer);
            map.removeOverlay(window.canvasLayer);
            map.removeOverlay(window.canvasLayerBack);
          }
        });
        map.clearOverlays();
        if (_isEmpty(selectUser)) {
          this.getDealerUsers(date);
        } else {
          this.getUserTrack(date, selectUser);
        }
      }, 3 * 60 * 1000);
    }
  }

  componentWillUnmount() {
    const { isInnerPage } = this.props;

    if (isInnerPage) {
      clearInterval(this.tick);
    }

    if (this.BMap) {
      const map = this.BMap.map;
      map.clearOverlays();
    }
  }

  initMap() {
    window.map = this.BMap.map;
    mapControl.initControl();
    mapControl.initOverlay();
  }

  getDealerUsers(date) {
    server.get(apiDealer.user.listAll(), (data) => {
      const { list } = data.res;
      this.setState({ users: list, selectUser: {} });
      if (list && list.length > 0) {
        list.forEach((user) => {
          this.getUserTrack(date, user, false);
        });
      }
    });
  }

  getDealerInfo() {
    server.get(apiDealer.setting.company.detail(), (data) => {
      const dealerInfo = data.res.detail;
      const coordidate = dealerInfo.poi.split(',');
      this.setState({ center: { lng: coordidate[0], lat: coordidate[1] } });
      this.getDealerUsers(this.state.date);
    });
  }

  getAllDealers() {
    server.get(apiDealer.store.repairShop.getAll(), (data) => {
      const { list } = data.res;
      const repairShops = [];
      list.forEach((item) => {
        const lonlas = item.poi_bd09 ? item.poi_bd09.split(',') : [];
        repairShops.push({
          text: item.name,
          lng: lonlas[0],
          lat: lonlas[1],
          icon_pic: item.icon_pic,
          _id: item.company_id,
          address: item.address,
        });
      });
      this.setState({ repairShops, isLoading: false }, () => {
        this.getMassivePoints();
      });
    });
  }

  // todo 添加加载动画
  getUserTrack(date, user, isSingle = true) {
    server.get(apiDealer.user.userTrackYingyan(date, user._id), (data) => {
      const detail = data.res.list;
      if (detail.points && detail.points.length > 0) {
        const tempArray = new Array(detail.points.length);
        tempArray.fill(1);

        this.setState({ blankEntityList: tempArray, detail, trackList: detail.points });
      }

      if (detail.points && detail.points.length > 0) {
        this.drawTrack(detail.points, '', '', isSingle, user);
      }
    });
  }

  getUserInfo(userId) {
    const map = this.BMap.map;
    const { users } = this.state;
    // 清除图层
    users.forEach(() => {
      if (typeof (window.canvasLayer) !== 'undefined' || typeof (window.canvasLayerBack) !== 'undefined' ||
        typeof (window.CanvasLayerPointer) !== 'undefined') {
        map.removeOverlay(window.CanvasLayerPointer);
        map.removeOverlay(window.canvasLayer);
        map.removeOverlay(window.canvasLayerBack);
      }
    });
    map.clearOverlays();

    server.get(apiDealer.user.detail(userId), (data) => {
      const { detail } = data.res;
      this.setState({ userId, selectUser: detail });
      this.getUserTrack(this.state.date, detail);
    });
  }

  handleSwitch(checked) {
    if (checked) {
      this.setState({ isLoading: true });
      this.getAllDealers();
    } else {
      this.setState({
        repairShops: [],
      });
      window.pointCollection.clear();
    }
  }

  handleDateChange(date, dateStr) {
    this.setState({ date: dateStr });
    const { selectUser } = this.state;
    const map = this.BMap.map;
    const { users } = this.state;
    // 清除图层
    users.forEach(() => {
      if (typeof (window.canvasLayer) !== 'undefined' || typeof (window.canvasLayerBack) !== 'undefined' ||
        typeof (window.CanvasLayerPointer) !== 'undefined') {
        map.removeOverlay(window.CanvasLayerPointer);
        map.removeOverlay(window.canvasLayer);
        map.removeOverlay(window.canvasLayerBack);
      }
    });
    map.clearOverlays();
    if (!_isEmpty(selectUser)) {
      this.getUserTrack(dateStr, selectUser);
    } else {
      this.getDealerUsers(dateStr);
    }
  }

  handleUserChange(userId) {
    if (userId) {
      this.getUserInfo(userId);
    } else {
      this.getDealerUsers(this.state.date);
    }
  }

  async getAddress(point, user) {
    // todo 根据经纬度获取地址
    const infoBoxObject = {
      point,
      print: point.print,
      infor: [
        ['定位:', point.lnglat],
        ['地址:', '地址未解析'],
        ['速度:', point.printSpeed],
        ['时间:', point.printTime],
        ['高度:', `${point.height}米`],
        ['精度:', `${point.radius}米`],
      ],
      // lnglat: point.lnglat,
      // address: address,
      // printTime: point.printTime,
      // printSpeed: point.printSpeed,
      // height: point.height,
      // radius: point.radius
    };

    const info = await new Promise((resolve, reject) => {
      server.get(apiCommon.system.getAddressByLocation(point.lnglat), (data) => {
        resolve(data.res);
      }, (err) => {
        reject(err);
      });
    });
    const sContent = '<div>' +
      `<h3>${user.name}</h3>` +
      `<div style="display: flex;"><h4>位置：   </h4><div style="width: 350px">${info.province_info.name + info.city_info.name + info.country_info.name + info.address}</div></div>` +
      `<div style="display: flex;"><h4>时间：   </h4><span>${point.printTime}</span></div>` +
      `<div style="display: flex;"><h4>速度：   </h4><span>${point.printSpeed}</span></div>` +
      `<div style="display: flex;"><h4>精度：   </h4><span>${point.radius}米</span></div>` +
      '</div>';
    const infoWindow = new BMap.InfoWindow(sContent);
    map.openInfoWindow(infoWindow, point);
    // this.setState({ infoBoxObject });
  }

  drawTrack(data, starttime, endtime, isSingle, user) {
    const { currentTrackData, first } = this.state;
    const that = this;
    const totalPoints = [];
    const map = this.BMap.map;

    if (!data) {
      data = currentTrackData;
    }

    if (data && data.length === 0) {
      if (isSingle) {
        message.warning('没有轨迹数据');
      }
      return;
    }
    if (!starttime && data && data.length > 0) {
      starttime = data[0].loc_time;
    }

    if (!endtime) {
      endtime = data[data.length - 1].loc_time;
    }

    data.forEach((item, i) => {
      if (item.loc_time >= starttime && item.loc_time <= endtime) {
        const tempPoint = new BMap.Point(data[i].longitude, data[i].latitude);
        tempPoint.speed = item.speed ? data[i].speed : 0;
        tempPoint.loc_time = item.loc_time;
        tempPoint.height = item.height;
        tempPoint.radius = item.radius;
        tempPoint.print = that.state.currentEntityPrint;
        tempPoint.supplement = item._supplement;
        tempPoint.printTime = mapCommon.getLocalTime(item.loc_time);
        tempPoint.printSpeed = mapCommon.getSpeed(item.speed);
        tempPoint.lnglat = `${item.longitude.toFixed(2)},${item.latitude.toFixed(2)}`;
        totalPoints.push(tempPoint);
      }
    });
    if (first) {
      // 使用 setTimeout 解决 连续map.setViewport bmap getscript.js 报错children问题
      const timer = setTimeout(() => {
        clearTimeout(timer)
        map.setViewport(totalPoints, { margins: [80, 0, 0, 200] });
      }, 500)
    }

    const updatePointer = function () {
      const ctx = this.canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      if (totalPoints.length !== 0) {
        let pixelPart = 0;
        const pixelPartUnit = 40;
        for (let i = 0, len = totalPoints.length; i < len - 1; i += 1) {
          const pixel = map.pointToPixel(totalPoints[i]);
          const nextPixel = map.pointToPixel(totalPoints[i + 1]);
          pixelPart +=
            Math.pow(Math.pow(nextPixel.x - pixel.x, 2) + Math.pow(nextPixel.y - pixel.y, 2), 0.5);
          if (pixelPart <= pixelPartUnit) {
            continue;
          }
          pixelPart = 0;
          ctx.beginPath();

          if (totalPoints[i + 1].loc_time - totalPoints[i].loc_time <= 5 * 60) {
            // 箭头一共需要5个点：起点、终点、中心点、箭头端点1、箭头端点2
            let midPixel = {};
            try {
              midPixel = new BMap.Pixel(
                (pixel.x + nextPixel.x) / 2,
                (pixel.y + nextPixel.y) / 2,
              );
            } catch (e) {

            }

            // 起点终点距离
            const distance = Math.pow((Math.pow(nextPixel.x - pixel.x, 2) +
              Math.pow(nextPixel.y - pixel.y, 2)), 0.5);
            // 箭头长度
            const pointerLong = 4;
            const aPixel = {};
            const bPixel = {};
            if (nextPixel.x - pixel.x === 0) {
              if (nextPixel.y - pixel.y > 0) {
                aPixel.x = midPixel.x - pointerLong * Math.pow(0.5, 0.5);
                aPixel.y = midPixel.y - pointerLong * Math.pow(0.5, 0.5);
                bPixel.x = midPixel.x + pointerLong * Math.pow(0.5, 0.5);
                bPixel.y = midPixel.y - pointerLong * Math.pow(0.5, 0.5);
              } else if (nextPixel.y - pixel.y < 0) {
                aPixel.x = midPixel.x - pointerLong * Math.pow(0.5, 0.5);
                aPixel.y = midPixel.y + pointerLong * Math.pow(0.5, 0.5);
                bPixel.x = midPixel.x + pointerLong * Math.pow(0.5, 0.5);
                bPixel.y = midPixel.y + pointerLong * Math.pow(0.5, 0.5);
              } else {
                continue;
              }
            } else {
              const k0 = ((-Math.pow(2, 0.5) * distance * pointerLong + 2 *
                (nextPixel.y - pixel.y) *
                midPixel.y) / (2 * (nextPixel.x - pixel.x))) + midPixel.x;
              const k1 = -((nextPixel.y - pixel.y) / (nextPixel.x - pixel.x));
              const a = Math.pow(k1, 2) + 1;
              const b = 2 * k1 * (k0 - midPixel.x) - 2 * midPixel.y;
              const c = Math.pow(k0 - midPixel.x, 2) + Math.pow(midPixel.y, 2) -
                Math.pow(pointerLong, 2);

              aPixel.y = (-b + Math.pow(b * b - 4 * a * c, 0.5)) / (2 * a);
              bPixel.y = (-b - Math.pow(b * b - 4 * a * c, 0.5)) / (2 * a);
              aPixel.x = k1 * aPixel.y + k0;
              bPixel.x = k1 * bPixel.y + k0;
            }
            ctx.moveTo(aPixel.x, aPixel.y);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#eee';
            ctx.lineTo(midPixel.x, midPixel.y);
            ctx.lineTo(bPixel.x, bPixel.y);
            ctx.lineCap = 'round';
          }
          if (totalPoints[i].loc_time >= starttime && totalPoints[i + 1].loc_time <= endtime) {
            ctx.stroke();
          }
        }
      }
    };

    const updateBack = function () {
      const ctx = this.canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      if (totalPoints.length !== 0) {
        for (let i = 0, len = totalPoints.length; i < len - 1; i++) {
          const pixel = map.pointToPixel(totalPoints[i]);
          const nextPixel = map.pointToPixel(totalPoints[i + 1]);
          ctx.beginPath();

          ctx.moveTo(pixel.x, pixel.y);
          if (totalPoints[i + 1].loc_time - totalPoints[i].loc_time <= 5 * 60) {
            // 绘制轨迹的时候绘制两次line，一次是底色，一次是带速度颜色的。目的是实现边框效果
            const supplement = totalPoints[i].supplement;
            if (supplement && (supplement === 1 || supplement === 2)) {
              ctx.lineWidth = 2;
            } else {
              ctx.lineWidth = 10;
              ctx.strokeStyle = '#8b8b89';
            }
            ctx.lineTo(nextPixel.x, nextPixel.y);
            ctx.lineCap = 'round';
          }
          if (totalPoints[i].loc_time >= starttime && totalPoints[i + 1].loc_time <= endtime) {
            ctx.stroke();
          }
        }
      }
    };
    const update = function () {
      const ctx = this.canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      if (totalPoints.length !== 0) {
        let lines = 1;
        for (let i = 0, len = totalPoints.length; i < len - 1; i++) {
          const pixel = map.pointToPixel(totalPoints[i]);
          const nextPixel = map.pointToPixel(totalPoints[i + 1]);
          ctx.beginPath();
          ctx.moveTo(pixel.x, pixel.y);
          if (totalPoints[i + 1].loc_time - totalPoints[i].loc_time <= 5 * 60) {
            const grd = ctx.createLinearGradient(pixel.x, pixel.y, nextPixel.x, nextPixel.y);
            // 绘制带速度颜色的轨迹
            ctx.lineCap = 'round';
            const supplement = totalPoints[i].supplement;
            if (supplement && (supplement === 1 || supplement === 2)) {
              ctx.setLineDash([5, 12]);
              ctx.lineWidth = 8;
              ctx.strokeStyle = '#8b8b89';
            } else {
              ctx.lineWidth = 8;
              ctx.setLineDash([0, 0]);
              ctx.strokeStyle = grd;
            }
            const speed = totalPoints[i].speed;
            const speedNext = totalPoints[i + 1].speed;
            grd.addColorStop(0, that.getColorBySpeed(speed));
            grd.addColorStop(1, that.getColorBySpeed(speedNext));
            ctx.lineTo(nextPixel.x, nextPixel.y);
          } else {
            lines += 1;
            const lineNum = lines;
            if (totalPoints[i + 1].loc_time >= starttime && totalPoints[i + 1].loc_time <=
              endtime) {
              const partImgStart = new Image();
              partImgStart.src = Number(user.department) === 6
                ? require('../../../../images/yingyan/startpoint_lg_red.png')
                : require('../../../../images/yingyan/startpoint_lg.png');
              const next = nextPixel;
              partImgStart.onload = () => {
                const width = [4, 8];
                ctx.drawImage(partImgStart, next.x - 10, next.y - 38);
                ctx.font = 'lighter 12px arial';
                ctx.fillStyle = 'white';
                ctx.fillText(user.name, next.x - width[lineNum >= 10 ? 1 : 0], next.y - 15);
              };
            }
            if (totalPoints[i].loc_time >= starttime && totalPoints[i].loc_time <= endtime) {
              // 轨迹终点
              const current = pixel;
              const partImgEnd = new Image();
              partImgEnd.src = Number(user.department) === 6
                ? require('../../../../images/yingyan/startpoint_lg_red.png')
                : require('../../../../images/yingyan/startpoint_lg.png');
              partImgEnd.onload = () => {
                const width = [4, 8];
                ctx.drawImage(partImgEnd, current.x - 10, current.y - 38);
                ctx.font = 'lighter 12px arial';
                ctx.fillStyle = 'white';
                ctx.fillText(user.name, current.x - width[lineNum >= 10 ? 1 : 0], current.y - 15);
              };
            }
          }
          if (totalPoints[i].loc_time >= starttime && totalPoints[i + 1].loc_time <= endtime) {
            ctx.stroke();
          }
        }
      }

      if (totalPoints[0].loc_time >= starttime) {
        const lines = 1;
        const imgStart = new Image();
        imgStart.src = Number(user.department) === 6
          ? require('../../../../images/yingyan/startpoint_lg_red.png')
          : require('../../../../images/yingyan/startpoint_lg.png');
        imgStart.onload = () => {
          const width = [4, 8];
          ctx.drawImage(imgStart, map.pointToPixel(totalPoints[0]).x -
            10, map.pointToPixel(totalPoints[0]).y - 38);
          ctx.font = 'lighter 12px arial';
          ctx.fillStyle = 'white';
          ctx.fillText(user.name, map.pointToPixel(totalPoints[0]).x -
            width[lines >= 10 ? 1 : 0], map.pointToPixel(totalPoints[0]).y - 16);
        };
      }
      if (totalPoints[totalPoints.length - 1].loc_time <= endtime) {
        const lines = 1;
        const imgEnd = new Image();
        imgEnd.src = Number(user.department) === 6
          ? require('../../../../images/yingyan/startpoint_lg_red.png')
          : require('../../../../images/yingyan/startpoint_lg.png');
        imgEnd.onload = () => {
          const width = [4, 8];
          ctx.drawImage(imgEnd, map.pointToPixel(totalPoints[totalPoints.length - 1]).x -
            10, map.pointToPixel(totalPoints[totalPoints.length - 1]).y - 38);
          ctx.font = 'lighter 12px arial';
          ctx.fillStyle = Number(user.department) === 6 ? '#000000' : 'red';
          ctx.fillText(user.name, map.pointToPixel(totalPoints[totalPoints.length - 1]).x -
            width[lines >= 10 ? 1 : 0], map.pointToPixel(totalPoints[totalPoints.length - 1]).y -
            15);
        };
      }
    };

    if (totalPoints.length > 0) {
      if (isSingle) {
        if (typeof (window.canvasLayer) !== 'undefined' || typeof (window.canvasLayerBack) !== 'undefined' ||
          typeof (window.CanvasLayerPointer) !== 'undefined') {
          map.removeOverlay(window.CanvasLayerPointer);
          map.removeOverlay(window.canvasLayer);
          map.removeOverlay(window.canvasLayerBack);
        }
      }

      window.canvasLayerBack = new CanvasLayer({
        map,
        update: updateBack,
      });
      window.canvasLayer = new CanvasLayer({
        map,
        update,
      });
      window.CanvasLayerPointer = new CanvasLayer({
        map,
        update: updatePointer,
      });
    }

    if (typeof (window.pointCollection) !== 'undefined') {
      map.removeOverlay(window.pointCollection);
    }
    const options = {
      size: BMAP_POINT_SIZE_HUGE,
      shape: BMAP_POINT_SHAPE_CIRCLE,
      color: 'rgba(0, 0, 0, 0)',
    };
    window.pointCollection = new BMap.PointCollection(totalPoints, options); // 初始化PointCollection
    window.pointCollection.addEventListener('mouseover', (e) => {
      mapControl.addTrackPointOverlay(e.point, 'trackpointOverlay');
    });
    window.pointCollection.addEventListener('mouseout', (e) => {
      mapControl.removeTrackPointOverlay('trackpointOverlay');
    });
    window.pointCollection.addEventListener('click', (e) => {
      mapControl.removeTrackInfoBox();
      mapControl.removeTrackPointOverlay('trackpointonOverlay');
      mapControl.addTrackPointOverlay(e.point, 'trackpointonOverlay');
      this.getAddress(e.point, user);
    });
    map.addOverlay(window.pointCollection); // 添加Overlay
  }

  getColorBySpeed(speed) {
    let red = 0;
    let green = 0;
    let blue = 0;
    speed = speed > 100 ? 100 : speed;
    switch (Math.floor(speed / 25)) {
      case 0:
        red = 187;
        green = 0;
        blue = 0;
        break;
      case 1:
        speed -= 25;
        red = 187 + Math.ceil((241 - 187) / 25 * speed);
        green = 0 + Math.ceil((48 - 0) / 25 * speed);
        blue = 0 + Math.ceil((48 - 0) / 25 * speed);
        break;
      case 2:
        speed -= 50;
        red = 241 + Math.ceil((255 - 241) / 25 * speed);
        green = 48 + Math.ceil((200 - 48) / 25 * speed);
        blue = 48 + Math.ceil((0 - 48) / 25 * speed);
        break;
      case 3:
        speed -= 75;
        red = 255 + Math.ceil((22 - 255) / 25 * speed);
        green = 200 + Math.ceil((191 - 200) / 25 * speed);
        blue = 0 + Math.ceil((43 - 0) / 25 * speed);
        break;
      case 4:
        red = 22;
        green = 191;
        blue = 43;
        break;
    }

    red = red.toString(16).length === 1 ? `0${red.toString(16)}` : red.toString(16);
    green = green.toString(16).length === 1 ? `0${green.toString(16)}` : green.toString(16);
    blue = blue.toString(16).length === 1 ? `0${blue.toString(16)}` : blue.toString(16);
    return `#${red}${green}${blue}`;
  }

  handleMarker(e, item) {
    const opts = {
      width: 200,
      height: 100,
      title: item.text,
      enableMessage: true,
      message: item.address,
    };
    const point = new BMap.Point(item.lng, item.lat);
    const infoWindow = new BMap.InfoWindow(item.address, opts);
    e.target.map.openInfoWindow(infoWindow, point);
  }

  createInfoWindow = (extData) => {
    const info = document.createElement('div');
    info.className = 'custom-info content-window-card';
    const top = document.createElement('div');
    const titleD = document.createElement('div');
    const a = document.createElement('a');
    top.className = 'info-top';
    a.href = `/store-shop/${extData._id}`;
    a.target = '_blank';
    a.innerText = extData.text;
    titleD.appendChild(a);
    top.appendChild(titleD);
    const content = document.createElement('div');
    content.className = 'info-middle';
    ReactDOM.render(<div style={{ width: 310, padding: '5px', background: '#fff' }}>
      <div>
        <ImageView fileKey={extData.icon_pic || ''} style={{ maxWidth: '100%' }} />
        <p>地址：{extData.address}</p>
      </div>
    </div>, content);
    info.appendChild(top);
    info.appendChild(content);
    return info;
  };

  // 海量点
  getMassivePoints() {
    const { repairShops } = this.state;
    let lastInfoBox = null;
    const siteData = [];
    const points = []; // 添加海量点数据

    if (document.createElement('canvas').getContext) {
      // repairShops为接口返回数据，此处用百度地图生成随机数 { lng: item.lng, lat: item.lat }
      repairShops.forEach((item, index) => {
        siteData.push({ center: new BMap.Point(item.lng, item.lat), count: index });
      });
      siteData.forEach(item => {
        points.push(item.center);
      });
      const options = {
        size: BMAP_POINT_SIZE_BIG,
        shape: BMAP_POINT_SHAPE_WATERDROP,
        color: '#ff3333',
      };
      const pointCollection = new BMap.PointCollection(points, options); // 初始化PointCollection

      pointCollection.addEventListener('click', (e) => {
        for (let i = 0; i < repairShops.length; i++) {
          // eslint-disable-next-line max-len
          if (Number(repairShops[i].lng) === e.point.lng && Number(repairShops[i].lat) === e.point.lat) {
            const tmpPt = new BMap.Point(e.point.lng, e.point.lat);
            const infoBox = new BMapLib.InfoBox(window.map, this.createInfoWindow(repairShops[i]), {
              boxStyle: {
                width: '320px',
                Height: '340px',
                marginBottom: '10px',
                backgroundColor: 'white',
              },
              offset: new BMap.Size(0, 0),
              closeIconMargin: '12px 8px 4px 4px',
              enableAutoPan: true,
              align: INFOBOX_AT_TOP,
            });
            lastInfoBox = infoBox;
            infoBox.open(tmpPt);
            if (lastInfoBox) {
              const timer = setTimeout(() => {
                lastInfoBox.close();
                clearTimeout(timer);
              }, 2000);
            }
            break;
          }
        }
      });
      window.pointCollection = pointCollection;
      window.map.addOverlay(pointCollection);
    } else {
      message.warn('请在chrome浏览器查看， 当前浏览器不支持海量点');
    }
  }

  renderMap() {
    const { isInnerPage } = this.props;
    const { center, reactBMap } = this.state;
    const { Map, NavigationControl, ScaleControl } = reactBMap;

    return (
      <Map
        center={center}
        ref={ref => this.BMap = ref}
        mapStyleV2={isInnerPage ? { styleJson } : { styleJson: [] }}
        zoom={10}
        minZoom={3}
        maxZoom={19}
        className={
          classNames({
            'map-style': !isInnerPage,
          })
        }
        style={{ width: '100%', height: '100%' }}
      >
        {!isInnerPage && (
          <NavigationControl />
        )}
        {!isInnerPage && (
          <ScaleControl />
        )}
      </Map>
    );
  }

  render() {
    const { isInnerPage } = this.props;
    const { date, userId, isLoading, isViewMap } = this.state;

    return (
      <div className={`${isInnerPage ? '' : 'pb40'}`} style={{ height: '100%', width: '100%' }}>
        {!isInnerPage && (
          <div className="head-action-bar">
            <span className="label">日期</span>
            <DatePicker
              placeholder="请选择日期"
              value={DateUtil.getMomentDate(date)}
              onChange={(value, valueStr) => this.handleDateChange(value, valueStr)}
            />

            <span className="label ml20">业务员</span>
            <SelectUser value={userId} onChange={value => this.handleUserChange(value)} />
            <span className="label ml20">显示门店</span>
            <Switch checkedChildren="是" unCheckedChildren="否" onChange={this.handleSwitch.bind(this)} />
          </div>
        )}

        {isInnerPage ? (
          isViewMap && this.renderMap()
        ) : (
          <Spin spinning={isLoading} tip="加载中...">
            <div style={{ width: '100%', minHeight: 650 }}>
              {isViewMap && this.renderMap()}
            </div>
          </Spin>
        )}
      </div>
    );
  }
}
