import React from 'react';
import PropTypes from 'prop-types';
import { Axis, Chart, Coord, Geom, Label, Legend, Tooltip } from 'bizcharts';
import DataSet from '@antv/data-set';

function PieChart(props) {
  const {
    title,
    theme,
    field,
    dimension,
    data,
    width,
    height,
    background,
    plotBackground,
    padding,
    legendOffsetY,
  } = props;

  const { DataView } = DataSet;

  const tempData = [];
  data.forEach((dataItem) => {
    tempData.push({
      item: dataItem[dimension],
      count: Number(dataItem[field]),
    });
  });

  const dv = new DataView();
  dv.source(tempData).transform({
    type: 'percent',
    field: 'count',
    dimension: 'item',
    as: 'percent',
  });
  const cols = {
    percent: {
      formatter: (val) => {
        val = `${Number(val * 100).toFixed(2)}%`;
        return val;
      },
    },
  };
  return (
    <div>
      <Chart
        theme={theme}
        width={width}
        height={height || 400}
        data={dv}
        scale={cols}
        padding={padding || [80, 100, 80, 80]}
        forceFit
        background={background}
        plotBackground={plotBackground}
      >
        {title && <p className={`chart-title ${theme}`}>{title}</p>}

        <Coord type="theta" radius={0.75} />
        <Axis name="percent" />
        <Legend position="bottom-center" offsetY={legendOffsetY || 46} />
        <Tooltip
          showTitle={false}
          itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
        />
        <Geom
          type="intervalStack"
          position="percent"
          color="item"
          tooltip={[
            'item*percent',
            (item, percent) => {
              percent = `${Number(percent * 100).toFixed(2)}%`;
              return {
                name: item,
                value: percent,
              };
            },
          ]}
          style={{
            lineWidth: 1,
            stroke: '#fff',
          }}
        >
          <Label
            content="percent"
            formatter={(val, item) => `${item.point.item}(${item.point.count}): ${val}`}
          />
        </Geom>
      </Chart>
    </div>
  );
}

PieChart.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  field: PropTypes.string.isRequired,
  dimension: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  background: PropTypes.object,
  plotBackground: PropTypes.object,
};

PieChart.defaultProps = {
  title: '',
  theme: 'default',
  field: 'count',
  dimension: 'item',
  data: [],
  background: {},
  plotBackground: {},
};

export default PieChart;
