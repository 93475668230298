import { SettingOutlined, AreaChartOutlined, CarOutlined, UserOutlined, ShopOutlined, BulbOutlined, AppstoreOutlined, InboxOutlined, ToolOutlined, PropertySafetyOutlined, DashboardOutlined } from '@ant-design/icons';

const menuDealer = [
  {
    key: 'business_manage',
    icon: AreaChartOutlined,
    name: '数据统计',
    subMenu: [
      {
        name: '数据分析',
        path: '/dashboard/analysis',
      }, {
        name: '业务概况',
        path: '/dashboard/business',
      }, {
        name: '每日报表',
        path: '/dashboard/daily-report',
      }, {
        name: '智能BI',
        path: '/bi-system',
        target: '_blank',
      },
    ],
  }, {
    key: 'visit_manage',
    icon: DashboardOutlined,
    name: '拜访管理',
    subMenu: [
      {
        name: '数据收集',
        path: '/dashboard/journal',
      }, {
        name: '拜访记录',
        path: '/dashboard/visit',
      }, {
        name: '拜访计划',
        path: '/dashboard/visit/plan',
      }, {
        name: '品牌意向',
        path: '/dashboard/brand-intention',
      }, {
        name: '拜访轨迹',
        path: '/dashboard/visit/track',
      }, {
        name: '拜访轨迹-鹰眼',
        path: '/dashboard/visit/track-yingyan',
      },
    ],
  }, {
    key: 'finance',
    icon: PropertySafetyOutlined,
    name: '财务',
    subMenu: [
      {
        name: '收款管理',
        path: '/finance/pay',
      }, {
        name: '到账记录',
        path: '/finance/transfer',
      }, {
        name: '收支管理',
        path: '/finance/expense',
      }, {
        name: '应收账款',
        path: '/finance/on-account',
      }, {
        name: '活动资金',
        path: '/finance/wallet',
      }, {
        name: '系统资金',
        path: '/finance/system-wallet',
      }, /* {
        name: '直播资金',
        path: '/finance/live-wallet',
      }, */
    ],
  }, {
    key: 'part_sell',
    icon: ToolOutlined,
    name: '配件销售',
    subMenu: [
      {
        name: '商品管理',
        path: '/parts-sale/goods-item',
      }, {
        name: '订单管理',
        path: '/parts-sale/order',
      }, {
        name: '商品类别',
        path: '/parts-sale/goods',
      }, {
        name: '配件管理',
        path: '/parts-sale/parts',
      }, {
        name: '品牌管理',
        path: '/parts-sale/brand-config',
      },
    ],
  }, {
    key: 'inventory',
    icon: InboxOutlined,
    name: '库存管理',
    subMenu: [
      {
        name: '仓库管理',
        path: '/inventory/warehouse',
      }, {
        name: '仓库盘点',
        path: '/inventory/stocktaking',
      }, {
        name: '损溢单',
        path: '/inventory/income-statement',
      }, {
        name: '采购管理',
        path: '/inventory/purchase',
      }, {
        name: '调拨管理',
        path: '/inventory/warehouse-move',
      }, {
        name: '入库管理',
        path: '/inventory/warehouse-input',
      }, {
        name: '出库管理',
        path: '/inventory/warehouse-output',
      },
    ],
  }, {
    key: 'shuidao_purchase',
    icon: AppstoreOutlined,
    name: '水稻采购',
    subMenu: [
      {
        name: '商品管理',
        path: '/shuidao-purchase/goods',
      }, {
        name: '订单管理',
        path: '/shuidao-purchase/order',
      },
    ],
  }, {
    key: 'marketing',
    icon: BulbOutlined,
    name: '营销',
    subMenu: [
      {
        name: '优惠活动',
        path: '/parts-sale/activity',
      }, {
        name: '活动报名',
        path: '/parts-sale/enroll',
      }, {
        name: '会议管理',
        path: '/parts-sale/conference',
      }, {
        name: '短信管理',
        path: '/setting/sms',
      }, /* , {
        name: '品牌活动',
        path: '/repair/activity-brand-config',
      } */ {
        name: '一物一码',
        path: '/anti-fake-code',
      }, {
        name: '推送记录',
        path: '/repair/push',
      }, {
        name: '门店积分',
        path: '/repair/voucher',
      }, {
        name: '门店礼品券',
        path: '/repair/coupon',
      },
    ],
  }, {
    key: 'store',
    icon: ShopOutlined,
    name: '门店',
    subMenu: [
      {
        name: '水稻会员店',
        path: '/shuidao-member-store',
      }, {
        name: '汽修管理',
        path: '/store-shop',
      }, {
        name: '门店地图',
        path: '/store-shop-map',
      }, {
        name: '线索管理',
        path: '/store-clue',
      },
    ],
  }, {
    key: 'personnel',
    icon: UserOutlined,
    name: '人事',
    subMenu: [
      {
        name: '员工管理',
        path: '/personnel',
      },
    ],
  }, {
    key: 'models',
    icon: CarOutlined,
    name: '车队',
    subMenu: [
      {
        name: '车队管理',
        path: '/vehicle/list',
      },
    ],
  }, {
    key: 'setting',
    icon: SettingOutlined,
    name: '设置',
    subMenu: [
      {
        name: '公司信息',
        path: '/setting/company',
      }, {
        name: '收款码',
        path: '/setting/pay-qrcode',
      },
    ],
  },
];

export default menuDealer;
