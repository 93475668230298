import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import _ from 'lodash';

import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/zh-cn';

import apiDealer from '../../configs/api-dealer';
import menuDealer from '../../configs/menu-dealer';

import server from '../../utils/server';
import AuthUtil from '../../utils/AuthUtil';
import DownloadGoogle from '../../components/DownloadGoogle';

import routes from './routes';
import Breadcrumb from '../Breadcrumb';
import PrivateRouteWithAuth from '../DealerRouteWithAuth';

moment.locale('zh-cn');

require('babel-polyfill');

require('../../styles/common.less');
require('../../styles/app.less');
require('../../styles/layout.css');

require('../../styles/reset.css');

const logo = require('../../images/dealer/logo.png');
const logoSmall = require('../../images/nav/daotian_logo.png'); // 设计小logo

const { Header, Footer, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
const MenuItemGroup = Menu.ItemGroup;

const packageFile = require('../../../package.json');

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavbar: sessionStorage.getItem('activeNavbar') || 'home',
      activeMenu: sessionStorage.getItem('menu') || '/home',
      collapsed: localStorage.getItem('collapsed') === 'true' || false,
      openKeys: [],
      useMenu: [],

      permissionMap: new Map(),
      companySubMenu: [],
    };

    [
      'handleLogout',
      'handleToggle',
      'handleMenuClick',
      'handleOpenMenuClick',
    ].map(method => this[method] = this[method].bind(this));
  }

  componentDidMount() {
    const that = this;
    if (AuthUtil.isLogin()) {
      this.initPage();
      this.getMenuPermissions();

      that.setState({ useMenu: menuDealer });
    }
    AuthUtil.checkLoginUserInfo(apiDealer.system.userInfo());
  }

  /**
   * 初始化页面默认状态
   * 1. 导航及对应菜单
   * 2. 默认激活的菜单
   */
  initPage() {
    const { activeNavbar } = this.state;
    const path = location.pathname;
    const parentMenuKey = this.getMenuItemParentKey(path);

    // 保存当前选中的菜单
    this.setState({
      activeNavbar,
      activeMenu: path,
      openKeys: parentMenuKey ? [parentMenuKey] : ['home'],
    });
    sessionStorage.setItem('menu', path);
  }

  handleLogout() {
    location.href = '/login';
    sessionStorage.clear();
    localStorage.clear();
  }

  /**
   * [optimize] 暂时关闭菜单收起功能
   * 原因：
   * 1. 左侧菜单全部事展开状态，收起菜单会一直悬浮
   * 2. 城市团队没有设计小图标
   */
  handleToggle() {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.state.collapsed) {
      localStorage.setItem('collapsed', false);
    } else {
      localStorage.setItem('collapsed', true);
    }
  }

  handleMenuClick(e) {
    const event = e;
    if (event.key === '/home') {
      this.setState({ openKeys: ['home'] });
    }
    this.setState({ activeMenu: event.key });
    sessionStorage.setItem('menu', event.key);
  }

  handleOpenMenuClick(keys) {
    const { openKeys } = this.state;
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    const parentMenuKey = this.getMenuItemParentKey(latestOpenKey);
    if (parentMenuKey) {
      this.setState({ openKeys: keys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  }

  getMenuItemParentKey(currentPath) {
    const menus = require('../../configs/menu-dealer').default;
    let parentKey;

    if (menus && menus.length > 0) {
      try {
        menus.forEach((menuItem) => {
          if (menuItem.subMenu.length > 0) {
            menuItem.subMenu.forEach((subMenuItem) => {
              if (subMenuItem.path === currentPath) {
                parentKey = menuItem.key;
                throw new Error('找到menuItem的Key啦');
              } else if (subMenuItem.path === 'group' && subMenuItem.items.length > 0) {
                subMenuItem.items.forEach((groupMenuItem) => {
                  if (groupMenuItem.path === currentPath) {
                    parentKey = menuItem.key;
                    throw new Error('Whoops!');
                  }
                });
              }
            });
          }
        });
      } catch (e) {
      }
    }
    return parentKey;
  }

  // 获取登录用户的所有权限
  getMenuPermissions() {
    const { permissionMap } = this.state;

    // 店总：公司的所有权限
    const user = AuthUtil.getLoginUserInfo();
    if (user.uid === user.dealerAdminUserId) {
      server.get(apiDealer.user.getCompanyPermissions(), (data) => {
        const permissions = this.getArrayPermission(data.res.list);
        localStorage.setItem('user_permission', window.btoa(window.encodeURIComponent(JSON.stringify(permissions))));

        permissions.forEach(permission => permissionMap.set(permission.path, permission));
        this.setState({ permissionMap }, () => this.getSubMenu());
      });
    } else {
      // 普通员工权限
      server.get(apiDealer.user.getCommonUserPermissions(), (data) => {
        const permissions = data.res.list;
        localStorage.setItem('user_permission', window.btoa(window.encodeURIComponent(JSON.stringify(permissions))));

        permissions.forEach(permission => permissionMap.set(permission.item_path, permission));
        this.setState({ permissionMap }, () => this.getSubMenu());
      });
    }
  }

  getArrayPermission(list) {
    const listArray = [];
    list.forEach((item) => {
      if (item.children) {
        item.children.forEach((value) => {
          listArray.push(value);
        });
        delete item.children;
      }
      listArray.push(item);
    });
    return listArray;
  }

  // 该函数是从配置文件companySubmenuConfig找到符合权限的菜单放到一个新的数组companySubMenu中
  getSubMenu() {
    const { companySubMenu } = this.state;

    menuDealer.forEach((menu) => {
      const menuItem = _.cloneDeep(menu);

      if (this.checkSubmenuPermission(menuItem)) {
        companySubMenu.push(menuItem);

        const subMenuIndex = companySubMenu.length - 1; // 当前操作的数组下标
        companySubMenu[subMenuIndex].subMenu = [];

        menu.subMenu.forEach((subMenu) => {
          const subMenuDeepCopy = _.cloneDeep(subMenu);

          // isPrivate: true，只有门店拥有者(即后台配置的门店店总)才有权限访问
          if (subMenuDeepCopy.path === 'group') {
            if (this.checkGroupPermission(subMenuDeepCopy)) {
              companySubMenu[subMenuIndex].subMenu.push(subMenuDeepCopy);

              // 构造companySubMenu数组结构
              const itemIndex = companySubMenu[subMenuIndex].subMenu.length - 1; // 新的groupItems下标
              companySubMenu[subMenuIndex].subMenu[itemIndex].items = [];

              // 将符合权限的group中的内容拷贝到subMenu中
              subMenu.items.map((group) => {
                if (this.checkPermission(group.path)) {
                  companySubMenu[subMenuIndex].subMenu[itemIndex].items.push(group);
                }
              });
            }
          } else if (this.checkPermission(subMenu.path)) {
            companySubMenu[subMenuIndex].subMenu.push(subMenu);
          }
        });
      }
    });

    this.setState({ companySubMenu });
  }

  checkSubmenuPermission(menu) {
    for (const subMenuItem of menu.subMenu) {
      // 检测该菜单栏是否是group 若是，则判断是否有group中第一项的权限，如果有则认为有该group所有权限
      if (subMenuItem.path === 'group') {
        for (const groupItem of subMenuItem.items) {
          if (this.checkPermission(groupItem.path)) {
            return true;
          }
        }
      } else {
        // 如果该菜单栏不是group，则检测是否有该菜单栏权限
        if (this.checkPermission(subMenuItem.path)) {
          return true;
        }
      }
    }
    return false;
  }

  checkGroupPermission(group) {
    for (const groupItem of group.items) {
      if (this.checkPermission(groupItem.path)) {
        return true;
      }
    }
    return false;
  }

  checkPermission(path) {
    return this.state.permissionMap.has(path.slice(1, path.length));
  }

  renderTrigger() {
    if (this.state.collapsed) {
      return (
        <MenuUnfoldOutlined
          className="menu-trigger"
          onClick={this.handleToggle}
        />
      );
    }
    return (
      <MenuFoldOutlined
        className="menu-trigger"
        onClick={this.handleToggle}
      />
    );
  }

  renderSubMenuIcon(collapsed, sub) {
    const MenuIcon = sub.icon;
    return (
      <span>
        {!collapsed && <MenuIcon />}
        <span>{sub.name}</span>
      </span>
    );
  }

  renderSider() {
    const { activeMenu, collapsed, openKeys, companySubMenu } = this.state;
    const menuItemStyle = { height: '28px', lineHeight: '28px' };

    return (
      <Menu
        theme="dark"
        mode="inline"
        // inlineCollapsed={collapsed}
        selectedKeys={[activeMenu]}
        openKeys={openKeys}
        onOpenChange={this.handleOpenMenuClick}
        onClick={this.handleMenuClick}
      >
        <MenuItem key="/home">
          <Link to={{ pathname: '/home' }}>
            {!collapsed && <HomeOutlined />}
            <span>首页</span>
          </Link>
        </MenuItem>

        {companySubMenu.length > 0 && (
          companySubMenu.map(sub => (
            <SubMenu
              key={sub.key}
              title={this.renderSubMenuIcon(collapsed, sub)}
            >
              {sub.subMenu.map((menuList) => {
                if (menuList.path === 'group') {
                  return (
                    <MenuItemGroup key={menuList.name} title={menuList.name}>
                      {menuList.items.map(groupItem => (
                        <Menu.Item key={groupItem.path} style={menuItemStyle}>
                          <Link
                            to={{ pathname: groupItem.path }}
                            target={groupItem.target || ''}
                          >
                            <span>{groupItem.name}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                    </MenuItemGroup>
                  );
                }

                return (
                  <MenuItem key={menuList.path} style={menuItemStyle}>
                    <Link to={{ pathname: menuList.path }} target={menuList.target || ''}>
                      <span>{menuList.name}</span>
                    </Link>
                  </MenuItem>
                );
              })}
            </SubMenu>
          ))
        )}
      </Menu>
    );
  }

  render() {
    const { uid, name, dealerName } = AuthUtil.getLoginUserInfo();
    const { collapsed } = this.state;

    const showMenu = classNames({
      'layout-menu': true,
      hide: !uid,
    });

    const showSetting = classNames({
      'layout-setting': !!uid,
      hide: !uid,
    });

    const logoContainer = classNames({
      'logo-youche': true,
      'logo-collapsed': collapsed,
    });

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed} width={collapsed ? 80 : 160}>
          <div className={logoContainer}>
            {collapsed
              ? <img src={logoSmall} style={{ width: 17, height: 17 }} alt="经销商" />
              : <img src={logo} style={{ width: 107, paddingLeft: 11 }} alt="经销商" />
            }
          </div>

          <div className={showMenu}>
            {this.renderSider()}
          </div>
        </Sider>

        {/* 内容区 */}
        <Layout>
          <Header
            className="header"
            style={{
              background: '#fff',
              padding: 0,
              borderBottom: '1px solid #e1e1e1',
              height: 50,
            }}
          >
            {this.renderTrigger()}

            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index.toString()}
                  path={route.path}
                  exact={route.exact}
                  component={() => <Breadcrumb breadcrumbName={route.breadcrumbName} />}
                />
              ))}
            </Switch>

            <div className={showSetting}>
              <div className="setting-container">
                <div className="company-name">
                  <div className="company-name-content">
                    {dealerName}
                  </div>
                </div>

                <div className="user-setting" style={{ color: 'black' }}>
                  <p className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
                    <UserOutlined style={{ marginLeft: 10, marginRight: 5 }} />
                    {name}
                    <a className="btn-logout" onClick={this.handleLogout}>退出</a>
                  </p>
                </div>
              </div>
            </div>
          </Header>

          <Content
            style={{
              margin: 20,
              padding: '20px',
              background: '#fff',
              minHeight: document.body.clientHeight - 156,
              border: '1px solid #e1e1e1',
              flex: 1,
            }}
          >

            <Switch>
              {routes.map((route, index) => (
                <PrivateRouteWithAuth
                  key={index.toString()}
                  path={route.path}
                  exact={route.exact}
                  component={route.render}
                />
              ))}
            </Switch>
          </Content>

          <Footer className="padding-20" style={{ textAlign: 'center' }}>
            水稻汽车 v{packageFile.versionDealer} 版权所有 ©{new Date().getFullYear()}
            <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
              京ICP备15040810号
            </a>
          </Footer>
        </Layout>

        <DownloadGoogle />
      </Layout>
    );
  }
}
