const API_COMMON = `${window.baseURL}/common`;

/**
 * 公共接口
 */
const apiCommon = {
  pagination: {
    size: 15,
  },
  system: {
    getProvinces() {
      return `${API_COMMON}/system/province-list`;
    },
    getCitiesByProvinceId(provinceId) {
      return `${API_COMMON}/system/city-list-by-province-id?province_id=${provinceId}`;
    },
    getCountriesByCityId(cityId) {
      return `${API_COMMON}/system/country-list-by-city-id?city_id=${cityId}`;
    },
    applyToUse() {
      return `${API_COMMON}/system/add-join-clue`;
    },

    getAddressByLocation(location) {
      return `${API_COMMON}/system/get-address-by-location?location=${location}`;
    },
  },

  /**
   * thumb_type 压缩值
   * 0: 原图
   * 1: 问题列表、问题详情缩略图
   * 2: 聊天列表缩略图
   * 3：压缩图片质量到50%
   * 具体值：
   * 0：不压缩
   * 1：74*74
   * 2：200*200
   * 3：容量压缩50%
   */
  file: {
    uploadURl: 'https://up.qbox.me',

    // 公共
    getPublicPicUploadToken(fileType) {
      return `${API_COMMON}/file/get-public-pic-upload-token?file_type=${fileType}`;
    },
    getPublicPicUrl(fileName = '', thumbType = 0, downloadName = 'image') {
      if (fileName.length === 0) return '';
      return `${API_COMMON}/file/get-public-pic-url?file_name=${fileName}&thumb_type=${thumbType}&download_name=${downloadName}`;
    },

    // 私有
    getPrivatePicUploadToken(fileType) {
      return `${API_COMMON}/file/get-private-pic-upload-token?file_type=${fileType}&attr=&ext=`;
    },
    getPrivatePicUrl(fileName = '', thumbType = 0, downloadName = 'image') {
      if (fileName.length === 0) return '';
      downloadName = downloadName || fileName;
      return `${API_COMMON}/file/get-private-pic-url?file_name=${fileName}&thumb_type=${thumbType}&download_name=${downloadName}`;
    },

    // 问答
    getQaPublicFileUploadToken(fileName) {
      return `${API_COMMON}/file/get-qa-public-file-upload-token?file_name=${fileName}&attr=&ext=`;
    },
    getQaPublicFileUrl(fileName, thumbType = 0) {
      return `${API_COMMON}/file/get-qa-public-file-url?file_name=${fileName}&thumb_type=${thumbType}`;
    },
  },

  auto: {
    getBrands(key = '') {
      return `${API_COMMON}/auto/auto-brand-list?rtick=&name=${key}`;
    },
    getSeriesByGuidePrice(guidePrice) {
      return `${API_COMMON}/auto/search-auto-type?price=${guidePrice}`;
    },
    getSeriesByBrand(brandId) {
      return `${API_COMMON}/auto/series-list-by-brand?auto_brand_id=${brandId}`;
    },
    getTypesBySeries(seriesId, year = '', minGuidePrice = 0, maxGuidePrice = 100000000) {
      return `${API_COMMON}/auto/type-list-by-series?auto_series_id=${seriesId}&year=${year}&min_guide_price=${minGuidePrice}&max_guide_price=${maxGuidePrice}`;
    },
    getTypeDetail(typeId) {
      return `${API_COMMON}/auto/type-info-by-id?auto_type_id=${typeId}`;
    },
    getYearsBySeries(seriesId, minGuidePrice = 0, maxGuidePrice = 100000000) {
      return `${API_COMMON}/auto/type-year-list-by-series?auto_series_id=${seriesId}&min_guide_price=${minGuidePrice}&max_guide_price=${maxGuidePrice}`;
    },
    getOutColorBySeries(seriesId) {
      return `${API_COMMON}/auto/out-color-by-series?auto_series_id=${seriesId}`;
    },
  },
};

export default apiCommon;
